import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
  state: () => ({
    token: '',
    userInfo: {},
    // token 是否过期
    isTokenTimeout: false
  }),
  getters: {
    isLogin () {
      return !!this.token
    },
    userType () {
      return this.userInfo.userType || null
    }
  },
  actions: {
    setToken (token) {
      this.token = token

      this.setTokenTimeout(false)
    },
    setUserInfo (userInfo) {
      this.userInfo = userInfo
    },
    logout () {
      this.token = ''
      this.userInfo = {}

      this.setTokenTimeout(true)
    },
    setTokenTimeout (isTokenTimeout) {
      this.isTokenTimeout = isTokenTimeout
    }
  },
  persist: true
})
