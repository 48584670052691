import { debounce } from '@/utils/index'

export const thousands = {
  mounted (el, binding) {
    const precision = binding.value || 0
    // console.debug('@ppp = ', typeof precision, precision)
    // 获取input节点
    if (el.tagName.toLocaleUpperCase() !== 'INPUT') {
      el = el.getElementsByTagName('input')[0]
    }

    function formatVal (value) {
      setTimeout(() => {
        el.value = value.toLocaleString('zh', {
          minimumFractionDigits: precision,
          maximumFractionDigits: precision,
        })
      }, 0)
    }

    // 初始化时，格式化值为千分位
    const numberValue = parseFloat(el.value.replace(/,/g, ''))
    if (!isNaN(numberValue)) {
      formatVal(numberValue)
    }

    // 聚焦时转化为数字格式（去除千分位）
    el.onfocus = () => {
      if (!el.value) return
      el.value = parseFloat(el.value.replace(/,/g, '')).toFixed(precision)
    }

    // 失去焦点时转化为千分位
    el.onblur = () => {
      const onBlurValue = parseFloat(el.value.replace(/,/g, ''))
      // console.debug('@onBlurValue = ', onBlurValue)
      if (!isNaN(onBlurValue)) {
        formatVal(onBlurValue)
      } else {
        el.value = null
      }
    }
  },
  updated (el, binding) {
    const precision = binding.value || 0

    // 获取input节点
    if (el.tagName.toLocaleUpperCase() !== 'INPUT') {
      el = el.getElementsByTagName('input')[0]
    }
    const isFocus = document.activeElement === el
    // console.debug('@iisFocus = ', isFocus)
    // console.debug('@update', el.value)

    function formatVal (value) {
      el.value = value.toLocaleString('zh', {
        minimumFractionDigits: precision,
        maximumFractionDigits: precision,
      })
    }

    // 输入时转化为千分位，并且加入防抖

    if (isFocus) return

    debounce(() => {
      const onInputValue = parseFloat(el.value.replace(/,/g, ''))
      // console.debug('@onInputValue = ', onInputValue)
      if (!isNaN(onInputValue)) {
        formatVal(onInputValue)
      }
    }, 1000)()
  }
}