import axios from 'axios'
// import i18n from '@/locales/index'
import { useUserStore } from '@/store/user'
import { ElNotification } from 'element-plus'
import router from '@/router/index'
import { MessageBox } from '@/components/msgBox/msgBox'

// const t = i18n.global.t
import { useFanyi } from '@/plugin/fanyi'

import { fanyi_from_java } from '@/fanyi/language_for_java'

const { f, locale } = useFanyi()

const service = axios.create({
  baseURL: '/sponsor/api',
  timeout: 60_000
})

service.interceptors.request.use(
  config => {
    config.headers.token = useUserStore().token
    config.headers.lang = locale.value
    config.headers.currency = localStorage.getItem('currency')
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  (resp) => {
    console.log(resp)
    const data=resp.data
    const code = data?.code
    const requestUrl=resp.config.url
    if (code === 1001) {
      useUserStore().setTokenTimeout(true)
      //如果是查询消息数量的,则不需要谈对话框
      if(requestUrl.endsWith('queryUnreadMessageCount')) {
        console.log('endsWith queryUnreadMessageCount')
        useUserStore().logout()
      }else{
        MessageBox.dialog({
          title: f('p050_t100', '提示'),
          type: 'error',
          closeOnClickModal: false,
          message: f('p050_t110', '登录过期，请重新登录'),
          confirmButtonText: f('p050_t120', '确认'),
          callback: (action) => {
            if (action === 'confirm') {
              useUserStore().logout()
              router.push({ name: 'login' })
            }
          },
        })
      }

      return Promise.reject(data)
    } else if (code === 500) {

      ElNotification({
        title: f('p050_t130', '提示'),
        message: fanyi_from_java(data.message) || f('p050_t140', '服务器内部错误'),
        type: 'error',
      })

      return data
    }

    return data
  },
  error => {
    return Promise.reject(error)
  }
)

export function get (url, params) {
  return service.get(url, { params })
}

export function post (url, data) {
  return service.post(url, data)
}

export default service