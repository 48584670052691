import { ElMessageBox } from 'element-plus'

class MsgBox {
  constructor () {
    this.isLoging = false
  }
  /**
   * 弹窗
   * @param {Object} param messageBox的参数
   */
  dialog (param) {
    if (!this.isLoging) {
      this.isLoging = true
      const _this = this

      ElMessageBox({
        title: param.title,
        type: param.type,
        message: param.message,
        confirmButtonText: param.confirmButtonText,
        closeOnClickModal: param.closeOnClickModal,
        // 等等别的参数...
        callback (action) {
          param.callback(action)

          // 点击确认关闭弹窗后 重置
          _this.isLoging = false
        }
      })
    }
  }
}

export const MessageBox = new MsgBox()