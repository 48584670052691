export const language_text = {
  'p045_t100': { 'zh-cn': '请先签署佣金协议', 'en': 'Please sign commission agreement first', 'jp': 'コミッション契約に署名してください' },

  // 'p046_t100': { 'zh-cn': '欢迎来到力赞体育商业在线社区', 'en': 'Welcome to the SponsorForce', 'jp': ''},
  'p046_t101': { 'zh-cn': '欢迎来到力赞的世界', 'en': 'Welcome to the SponsorForce', 'jp': 'SponsorForceの世界へようこそ' },
  // 'p046_t110': { 'zh-cn': '我们让赞助更简单', 'en': 'We make sponsorship simpler', 'jp': ''},
  'p046_t111': { 'zh-cn': '关于赞助，尽在一处', 'en': 'Everything About Sponsorship', 'jp': 'スポンサーシップが、より簡単に' },
  'p046_t120': { 'zh-cn': '探索全球体育资源', 'en': 'Discover Global Sports Properties', 'jp': '世界の協賛可能な案件を探す' },
  // 'p046_t130': { 'zh-cn': '涵盖运动员、队伍、赛事、职业联盟、协会等丰富资源选择', 'en': 'Easy access to a variety of sports rights holders including athletes, teams, leagues, and more.', 'jp': '選手、チーム、イベント、リーグ、協会、連盟など、豊富な案件を網羅'},
  'p046_t130': { 'zh-cn': '申请加入您心仪的品牌方项目，或接受赞助商接洽邀约', 'en': '', 'jp': '' },
  'p046_t150': { 'zh-cn': '输入资源名称、项目或地区', 'en': 'Enter name, sports or location', 'jp': 'キーワードを入力' },

  'p046_t160': { 'zh-cn': '释放更多机会', 'en': 'Unlock the many possibilities', 'jp': '' },
  'p046_t170': { 'zh-cn': '告诉我们您是谁，您的使命是什么，并展现您的价值。', 'en': 'Create your profile and upload your sponsorship package. Tell us who you are, what you are about, and share your value', 'jp': 'あなたの情報やミッションを教えてください' },


  'p046_t180': { 'zh-cn': '浏览品牌方需求，申请加入您感兴趣的品牌推广计划。', 'en': 'Check out what the brands on SponsorForce are looking for in partners and what they are offering. Apply to those of interest. (You may also be invited to the campaign by the brand.)', 'jp': '' },
  'p046_t190': { 'zh-cn': '发布资源', 'en': 'PUBLISH SPONSORSHIP PACKAGES', 'jp': 'プロパティを掲載する' },
  'p046_t200': { 'zh-cn': '轻松找到好IP', 'en': 'Easily Find High-Quality Properties', 'jp': '「協賛リクエスト」に応募してみる' },
  'p046_t210': { 'zh-cn': '力赞最全面的搜索工具来锁定满足您需求的优质资源。', 'en': 'Our most comprehensive search tools to pinpoint high-quality sports properties that meet your needs.', 'jp': '' },
  'p046_t220': { 'zh-cn': '创建您的赞助需求，鼓励资源方申请加入您的计划中，并通过力赞支付赞助费用，更便捷，透明且安全', 'en': 'Create your sponsorship campaign proposal and encourage rights holders to apply to your program, and pay sponsorship fees through SponsorForce for a more convenient, transparent, and secure process.', 'jp': '' },
  'p046_t230': { 'zh-cn': '发布需求', 'en': 'PUBLISH CAMPAIGN PROPOSAL', 'jp': '協賛リクエストを発表' },
  'p046_t240': { 'zh-cn': '留言', 'en': 'Messaging', 'jp': '' },
  'p046_t250': { 'zh-cn': '使用我们的即时聊天功能，与心仪的资源方或品牌方合作伙伴进行直接沟通，极大地提升了沟通效率。', 'en': 'Utilize our instant messaging feature to communicate directly with desired rights holders or brand partners, significantly enhancing communication efficiency.', 'jp': '' },
  'p046_t260': { 'zh-cn': '给对方留言', 'en': 'LEAVE A MESSAGE', 'jp': '相手にメッセージを送信してみる' },
  'p046_t270': { 'zh-cn': '数据服务', 'en': 'Data Services', 'jp': '' },
  'p046_t280': { 'zh-cn': '力赞与国内外顶尖的体育数据公司合作，帮助您提取所需的数据，并将其转化为改变游戏规则的见解，使您的下一个体育赞注战略举措变得毫不费力。无论您需要的是白皮书、报告还是信息图，我们都会为您提供支持', 'en': 'SponsorForce collaborates with top sports data companies both domestically and internationally, helping you extract the data you need and transforming it into game-changing insights, power your next sports sponsorship strategy.Whether you need a whitepaper, report, or infographics, we will provide support for you.', 'jp': '' },
  // 'p046_t290': { 'zh-cn': '无论您需要的是白皮书、报告还是信息图，我们都会为您提供支持','en':'','jp':''},
  'p046_t300': { 'zh-cn': '了解更多', 'en': 'Learn More', 'jp': '詳細はこちら' },
  'p046_t310': { 'zh-cn': '话题', 'en': 'Community', 'jp': 'コミュニティ' },
  'p046_t320': { 'zh-cn': '一个有用的、高质量的体育商业内容社区', 'en': 'A useful, high-quality sports business content community.', 'jp': '' },
  'p046_t330': { 'zh-cn': '暂无数据', 'en': 'Not Available', 'jp': 'データなし' },


  'p047_t100': { 'zh-cn': '基于真实、透明和独特连接的赞助新方式', 'en': 'A new way of sports sponsorship based on authenticity, transparency, and unique connections', 'jp': '' },
  // 'p047_t110': { 'zh-cn': '我们让赞助更简单', 'en': 'We make sponsorship simpler', 'jp': ''},
  'p047_t111': { 'zh-cn': '关于赞助，尽在一处', 'en': 'Everything About Sponsorship', 'jp': 'スポンサーシップが、より簡単に' },
  'p047_t120': { 'zh-cn': '关于力赞', 'en': 'About SponsorForce', 'jp': 'SponsorForceについて' },
  'p047_t130': { 'zh-cn': '力赞是一个创新的体育赞助数字化社区，专注于在全球范围建立资源方、品牌方、代理商以及专业人士之间更加紧密的连接，发掘并促进更多赞助机会与可能', 'en': 'SponsorForce is an innovative sports sponsorship digital community, focusing on establishing closer connections between rights holders, sponsors, agents, and professionals globally, discovering and promoting more sponsorship opportunities and possibilities.', 'jp': 'SponsorForceは、スポンサーシップ・ビジネスのための先進的なデジタルプラットフォームです。世界各地の権利所有者、広告主、代理店、および業界専門家を一つに結びつけ、スポンサーシップに関する情報交換を促進します。このプラットフォームは、スポンサーシップ取引のプロセスを効率化し、より簡単かつスムーズに行えるよう設計されています。SponsorForceを利用することで、パートナーシップの可能性を最大限に引き出し、ビジネスの成長を加速させることができます。' },
  'p047_t140': { 'zh-cn': '愿景', 'en': 'Vision', 'jp': 'Vision' },
  'p047_t150': { 'zh-cn': '技术驱动赞助行业的变革', 'en': 'To revolutionize sponsorship business through cutting-edge technologies.', 'jp': 'テクノロジーでスポーツビジネスのあり方を再構築し、スポーツの発展に寄与することによって、より多くの感動が作り出されること' },
  'p047_t160': { 'zh-cn': '使命', 'en': 'Missions', 'jp': 'Missions' },
  'p047_t170': { 'zh-cn': '成为赞助领域首个平台化企业', 'en': 'To become the first-ever Platformer in the realm of sponsorship.', 'jp': '透明性：繋ぐことによって透明性を創造すること' },
  'p047_t180': { 'zh-cn': '将凌乱分散的行业洞察整合到一个集中的人工智能引擎中', 'en': 'To consolidate fragmented industrial insights into a centralized AI Engine.', 'jp': 'インテリジェンス：先進的なAIとアルゴリズムで意思決定を向上させること' },
  'p047_t181': { 'zh-cn': '', 'en': '', 'jp': '効率性：最高のパフォーマンスのためのスポンサーシップ管理プロセスを改善すること' },


  'p047_t171': { 'zh-cn': '服务', 'en': 'Services', 'jp': 'Services' },
  'p047_t172': { 'zh-cn': '赞助匹配与交易支持', 'en': 'Sponsorship matching and transaction supports', 'jp': 'コミュニティ：スポーツビジネスコミュニティのためのデジタルハブの運営' },
  'p047_t173': { 'zh-cn': '人工智能驱动的赞助管理工具', 'en': 'An online sports business community', 'jp': 'マーケットプレイス：ポンサーシップのマッチングと取引のサポート' },
  'p047_t174': { 'zh-cn': '体育商业内容在线社区', 'en': 'AI-powered sponsorship management tool', 'jp': 'スマートエンジン：スポンサーシップ管理のためのAI駆動ツールの提供' },

  'p047_t190': { 'zh-cn': '为何选择力赞？', 'en': 'Why SponsorForce?', 'jp': '' },
  'p047_t200': { 'zh-cn': '全球资源：提供全球最广泛的体育资源选择', 'en': 'GLOBAL：The largest choice of sports properties worldwide', 'jp': '' },
  'p047_t210': { 'zh-cn': '力赞认证：提供全面认证，更值得信赖', 'en': 'OFFICIAL：Comprehensive certification, more trustworthy', 'jp': 'SponsorForce認証：包括的な認証を提供し、より信頼できる。' },
  'p047_t220': { 'zh-cn': '最优价格：会员享最优佣金，且无隐藏费用', 'en': 'BEST PRICE：Members enjoy the best commission rates, and no hidden fees', 'jp': 'ベストプライス: 会員は隠された手数料のないベストコミッションを享受できる' },

  'p047_t230': { 'zh-cn': '可靠安全：隐私保护与安全的支付方案', 'en': 'RELIABLE：Privacy protection and safe payment solutions', 'jp': '' },
  'p047_t240': { 'zh-cn': '智能算法：根据赞助商的需求和资源的特点进行适配', 'en': 'SMART ALGORITHM：Smart matching based on the needs of sponsors and the characteristics of sports properties.', 'jp': '' },
  'p047_t250': { 'zh-cn': '个性化服务：联系我们的赞助营销专家', 'en': 'BESPOKE SERVICES：Please contact our sponsorship experts', 'jp': '個人向けサービス：スポンサーシップマーケティングの専門家にお問い合わせください。' },

  'p048_t100': { 'zh-cn': '关于我们', 'en': 'About Us', 'jp': '会社概要' },

  'p048_t101': { 'zh-cn': '用户协议', 'en': 'User Agreement', 'jp': '利用規約' },
  'p048_t102': { 'zh-cn': '免责事项', 'en': 'Disclaimers', 'jp': '免責事項' },
  'p048_t103': { 'zh-cn': '隐私政策', 'en': 'Privacy Policy', 'jp': 'プライバシーポリシー' },
  'p048_t104': { 'zh-cn': '广告合作', 'en': 'Advertising ', 'jp': '広告掲載について' },
  // 'p048_t105': { 'zh-cn': 'FAQs', 'en': 'FAQs', 'jp': 'ヘルプ（FAQs）' },
  'p048_t106': { 'zh-cn': '联系我们', 'en': 'Contact Us', 'jp': '連絡先' },


  'p048_t110': { 'zh-cn': '常见问题', 'en': 'FAQs', 'jp': 'ヘルプ（FAQs）' },
  'p048_t120': { 'zh-cn': '商业合作', 'en': 'Partnerships', 'jp': '' },
  'p048_t130': { 'zh-cn': '联系我们', 'en': 'Contact Us', 'jp': '連絡先' },
  'p048_t140': { 'zh-cn': '用户协议', 'en': 'User Agreement', 'jp': '利用規約' },


  'p049_t100': { 'zh-cn': '最近联系', 'en': 'Recently contacted', 'jp': '最近の連絡先' },
  'p049_t110': { 'zh-cn': '全部已读', 'en': 'All read', 'jp': '全て既読' },
  'p049_t120': { 'zh-cn': '暂无联系人', 'en': 'There are currently no contacts available', 'jp': '連絡先がいません' },
  'p049_t130': { 'zh-cn': '与', 'en': 'Related to', 'jp': 'と' },
  'p049_t140': { 'zh-cn': '对话', 'en': 'Dialogue', 'jp': '会話' },
  'p049_t150': { 'zh-cn': '开始聊天', 'en': 'Start chatting', 'jp': 'チャットを始める' },
  'p049_t160': { 'zh-cn': '按回车键发送', 'en': 'Press Enter to send', 'jp': 'Enterキーを押して送信する' },
  'p049_t170': { 'zh-cn': '发送', 'en': 'Send', 'jp': '送信する' },


  'p050_t100': { 'zh-cn': '提示', 'en': 'Tips', 'jp': 'リマインダー' },
  'p050_t110': { 'zh-cn': '登录过期，请重新登录', 'en': 'Session expired,please login again', 'jp': '登録期限が切れていますので,再登録してください' },
  'p050_t120': { 'zh-cn': '确认', 'en': 'Confirm', 'jp': '確認' },
  'p050_t130': { 'zh-cn': '提示', 'en': 'Tips', 'jp': 'リマインダー' },
  'p050_t140': { 'zh-cn': '服务器内部错误', 'en': 'Internal server error', 'jp': 'サーバー内部のエラーです' },

  'p051_t100': { 'zh-cn': '请输入正确的手机号', 'en': 'Please enter the correct phone number', 'jp': '正しい番号をお願いします' },
  'p051_t110': { 'zh-cn': '请输入正确的电话号码', 'en': 'Please enter the correct phone number', 'jp': '正しい電話番号をお願いします' },
  'p051_t120': { 'zh-cn': '密码必须包含字母、数字，长度为8-32，不能包含空格', 'en': 'The password must contain letters, numbers, a length of 8-32, and cannot contain spaces', 'jp': '文字、数字、長さは8 ~ 32で、スペースは含まれません' },
  'p051_t130': { 'zh-cn': '请输入密码', 'en': 'Please enter password', 'jp': 'パスワードをお願いします' },
  'p051_t140': { 'zh-cn': '邮箱格式错误', 'en': 'Email format error', 'jp': 'メールエラーです' },
  'p051_t150': { 'zh-cn': '账号为邮箱格式', 'en': 'Email', 'jp': 'アカウントはメール形式です。' },
  'p051_t160': { 'zh-cn': '请输入内容', 'en': 'Please enter the content', 'jp': '内容の入力をお願いします' },
  'p051_t170': { 'zh-cn': '网址格式错误', 'en': 'URL format error', 'jp': 'アドレスが間違っています' },



  //'p052_t090': { 'zh-cn': '后退', 'en': 'Back', 'jp': '戻る' },
  'p052_t091': { 'zh-cn': '赞助需求', 'en': 'Request', 'jp': '協賛リクエスト' },
  'p052_t092': { 'zh-cn': '赞助商信息', 'en': 'Sponsor Info', 'jp': 'スポンサー情報' },
  'p052_t100': { 'zh-cn': '我要沟通', 'en': 'Communicate', 'jp': 'コミュニケーションを取る' },
  'p052_t110': { 'zh-cn': '收藏', 'en': 'Bookmark', 'jp': 'お気に入り追加' },
  'p052_t120': { 'zh-cn': '取消收藏', 'en': 'Cancel Collection', 'jp': 'お気に入り取消し' },
  'p052_t130': { 'zh-cn': '需求类型', 'en': 'Request Type', 'jp': '種類' },
  'p052_t140': { 'zh-cn': '名称', 'en': 'Title', 'jp': '表示タイトル' },
  'p052_t150': { 'zh-cn': '品牌类别', 'en': 'Brand Category', 'jp': '業種' },
  'p052_t160': { 'zh-cn': '项目预算', 'en': 'Project Budget', 'jp': 'プロジェクト予算' },
  'p052_t170': { 'zh-cn': '元/年', 'en': 'Yuan/year', 'jp': '元/年' },
  'p052_t180': { 'zh-cn': '偏好的体育项目', 'en': 'Favorite Sports Projects', 'jp': '希望種目' },
  'p052_t190': { 'zh-cn': '申请截止日期', 'en': 'Application Deadline', 'jp': '応募締切' },
  'p052_t200': { 'zh-cn': '国家', 'en': 'Country', 'jp': '国名' },
  'p052_t201': { 'zh-cn': '赞助周期', 'en': 'Sponsor Period', 'jp': '希望の協賛期間' },


  'p052_t210': { 'zh-cn': '地区', 'en': 'Region', 'jp': '地域' },
  'p052_t220': { 'zh-cn': '规格', 'en': 'Specification', 'jp': '仕様' },
  'p052_t230': { 'zh-cn': '需求描述', 'en': 'Description of Request', 'jp': '詳細記述' },
  'p052_t240': { 'zh-cn': '微博粉丝数', 'en': 'Number of Weibo followers', 'jp': '微博フォロワー数' },
  'p052_t250': { 'zh-cn': '抖音粉丝数', 'en': 'Number of Tiktok fans', 'jp': '抖音フォロワー数' },
  'p052_t260': { 'zh-cn': '小红书粉丝数', 'en': 'Number of followers on Xiaohongshu', 'jp': '小红书フォロワー' },
  'p052_t270': { 'zh-cn': 'Facebook粉丝数', 'en': 'Facebook fan count', 'jp': 'Facebookフォロワー数' },
  'p052_t280': { 'zh-cn': 'Instgram粉丝数', 'en': 'Instagram fan count', 'jp': 'Instgramフォロワー数' },
  'p052_t290': { 'zh-cn': 'Twitter粉丝数', 'en': 'Number of Twitter followers', 'jp': 'X (旧ツイッター）フォロワー数' },
  'p052_t291': { 'zh-cn': '微信粉丝数', 'en': 'Number of Wechat followers', 'jp': 'Wechatフォロワー数' },
  'p052_t300': { 'zh-cn': 'Tiktok粉丝数', 'en': 'Tiktok fan count', 'jp': 'Tiktokフォロワー数' },
  'p052_t310': { 'zh-cn': '返回', 'en': 'Return', 'jp': '戻る' },
  'p052_t320': { 'zh-cn': '沟通内容', 'en': 'Communication', 'jp': '先方とコミュニケーションを取りますか？' },
  'p052_t321': { 'zh-cn': '请输入您的信息（最多 150 个字符）', 'en': 'Please enter content (150 max)', 'jp': 'メッセージを入力してください（150文字まで）' },
  'p052_t322': { 'zh-cn': '不可选择未发布的资源', 'en': 'Cannot select unpublished property', 'jp': '未公開のリソースは選択できません' },

  'p052_t330': { 'zh-cn': '请确认是否通过以下联系方式联系你？', 'en': 'Do you want to initiate communication?', 'jp': '' },
  'p052_t340': { 'zh-cn': '手机号', 'en': 'Mobile', 'jp': '携帯番号' },
  'p052_t350': { 'zh-cn': '请输入登录账号', 'en': 'Please enter the login account', 'jp': 'ログインアカウントを入力してください' },
  'p052_t360': { 'zh-cn': '邮箱', 'en': 'Email', 'jp': 'メールアドレス' },
  'p052_t370': { 'zh-cn': '请输入邮箱', 'en': 'Please enter email', 'jp': 'メールアドレスを入力してください' },
  'p052_t380': { 'zh-cn': '资源ID', 'en': 'Property ID', 'jp': '案件ID' },
  'p052_t390': { 'zh-cn': '资源名称', 'en': 'Property Name', 'jp': '案件名' },
  'p052_t400': { 'zh-cn': '资源类型', 'en': 'Property Type', 'jp': '案件タイプ' },
  'p052_t410': { 'zh-cn': '资源状态', 'en': 'Property Status', 'jp': '案件ステータス' },
  'p052_t420': { 'zh-cn': '待审核', 'en': 'Pending Review', 'jp': 'レビュー保留中' },
  'p052_t430': { 'zh-cn': '审核通过', 'en': 'Approved', 'jp': 'レビューパス' },
  'p052_t440': { 'zh-cn': '审核拒绝', 'en': 'Review Reject', 'jp': 'レビュー拒否' },
  'p052_t450': { 'zh-cn': '废弃', 'en': 'Abandoned', 'jp': '廃棄' },
  'p052_t460': { 'zh-cn': '开始时间', 'en': 'Begin Time', 'jp': '開始時間' },
  'p052_t470': { 'zh-cn': '结束时间', 'en': 'End Time', 'jp': '終了時間' },
  'p052_t480': { 'zh-cn': '创建时间', 'en': 'Creation Time', 'jp': '作成日時' },
  'p052_t490': { 'zh-cn': '取消', 'en': 'Cancel', 'jp': 'キャンセル' },
  'p052_t500': { 'zh-cn': '确认', 'en': 'Confirm', 'jp': '確認' },
  'p052_t501': { 'zh-cn': '特别提醒', 'en': 'Notification', 'jp': 'ご確認事項' },
  'p052_t502': { 'zh-cn': '我已阅读并同意', 'en': 'I have read and agreed', 'jp': '同意しました' },
  'p052_t510': { 'zh-cn': '选中的资源', 'en': 'Selected resources', 'jp': '選ばれた資源です' },
  'p052_t520': { 'zh-cn': '合集', 'en': 'Collection', 'jp': '' },
  'p052_t530': { 'zh-cn': '请输入邮箱', 'en': 'Please enter email', 'jp': 'メールアドレスを入力してください' },
  'p052_t540': { 'zh-cn': '请输入手机', 'en': 'Please enter mobile number', 'jp': '携帯番号を入力してください' },
  'p052_t550': { 'zh-cn': '资源列表分页', 'en': 'Property List Paging', 'jp': '' },
  //'p052_t560': { 'zh-cn': '页码改变', 'en': 'Page number change', 'jp': ''},
  //'p052_t570': { 'zh-cn': '查询我的资源列表', 'en': 'Query my property list', 'jp': ''},
  //'p052_t580': { 'zh-cn': '资源是否允许选中', 'en': 'Allow selection of resources', 'jp': ''},
  //'p052_t590': { 'zh-cn': '只有审核通过的资源才能支持可以选中', 'en': 'Only approved resources can support selection', 'jp': ''},
  //'p052_t600': { 'zh-cn': '我要沟通点击', 'en': 'I am interested in clicking', 'jp': ''},
  //'p052_t610': { 'zh-cn': '首先查询资源', 'en': 'First, search for resources', 'jp': ''},
  'p052_t620': { 'zh-cn': '提交成功', 'en': 'Submitted Successfully', 'jp': '投稿完了' },
  'p052_t630': { 'zh-cn': '收藏成功', 'en': 'Collection Successfully', 'jp': 'お気に入り追加に成功しました' },
  'p052_t640': { 'zh-cn': '取消收藏成功', 'en': 'Cancel Collection Successfully', 'jp': 'お気に入り取消しに成功しました' },
  'p052_t650': { 'zh-cn': '只有资源方才可对话', 'en': 'Only resources can talk', 'jp': 'リソースだけが会話できる' },
  'p052_t660': { 'zh-cn': '只有赞助商才可对话', 'en': 'Only sponsors can chat', 'jp': 'スポンサーのみがチャットできます' },
  'p052_t641': { 'zh-cn': '签约成功', 'en': 'Confirmed Successfully', 'jp': '契約に成功しました' },


  //'p052_t650': { 'zh-cn': '个人部分', 'en': 'Personal section', 'jp': ''},
  //'p052_t660': { 'zh-cn': '提交', 'en': 'Submit', 'jp': ''},
  //'p052_t670': { 'zh-cn': '生命周期', 'en': 'life cycle', 'jp': ''},

  'p052_t700': { 'zh-cn': '请先去用户中心完善公司信息（公司名称和营业执照）', 'en': 'Please go to the user center first to complete the company information (company name and business license)', 'jp': 'まずユーザーセンターに行って会社情報(会社名と営業許可書)を整備してもらいます。' },
  'p052_t710': { 'zh-cn': '马上去完善公司信息？', 'en': 'Go to complete company information?', 'jp': 'さっそく会社情報を完成させに行きますか?' },
  'p100_t100': { 'zh-cn': '用户信息', 'en': 'User Info', 'jp': 'ユーザー情報' },
  'p100_t110': { 'zh-cn': '用户类型', 'en': 'User Type', 'jp': 'ユーザータイプ' },
  'p100_t120': { 'zh-cn': '资源方', 'en': 'Rights Holder', 'jp': '権利元' },
  'p100_t130': { 'zh-cn': '赞助商', 'en': 'Sponsor', 'jp': 'スポンサー' },
  'p100_t140': { 'zh-cn': '其他用户', 'en': 'Other User', 'jp': 'その他のユーザー' },
  'p100_t150': { 'zh-cn': '角色', 'en': 'Role', 'jp': 'ユーザー属性' },
  'p100_t160': { 'zh-cn': '请选择角色', 'en': 'Please choose your role', 'jp': 'ユーザー属性を選択してください' },
  'p100_t170': { 'zh-cn': '资源拥有者', 'en': 'Rights Owner', 'jp': '権利元' },
  'p100_t180': { 'zh-cn': '资源拥有者', 'en': 'Rights Owner', 'jp': '権利元' },
  'p100_t190': { 'zh-cn': '资源代理', 'en': 'Sports Agent', 'jp': '権利元代理' },
  'p100_t200': { 'zh-cn': '资源代理', 'en': 'Sports Agent', 'jp': '権利元代理' },
  'p100_t210': { 'zh-cn': '其他', 'en': 'Others', 'jp': 'その他' },
  'p100_t220': { 'zh-cn': '其他', 'en': 'Others', 'jp': 'その他' },
  'p100_t230': { 'zh-cn': '角色', 'en': 'Role', 'jp': 'ユーザー属性' },
  'p100_t240': { 'zh-cn': '请选择角色', 'en': 'Please choose your role', 'jp': 'ユーザー属性を選択してください' },
  'p100_t250': { 'zh-cn': '品牌主', 'en': 'Advertiser', 'jp': '広告主' },
  'p100_t260': { 'zh-cn': '品牌主', 'en': 'Advertiser', 'jp': '広告主' },
  'p100_t270': { 'zh-cn': '品牌营销代理', 'en': 'Agency', 'jp': '広告代理' },
  'p100_t280': { 'zh-cn': '品牌营销代理', 'en': 'Agency', 'jp': '広告代理' },
  'p100_t290': { 'zh-cn': '其他', 'en': 'Others', 'jp': 'その他' },
  'p100_t300': { 'zh-cn': '其他', 'en': 'Others', 'jp': 'その他' },
  'p100_t310': { 'zh-cn': '头像', 'en': 'Profile Image', 'jp': 'プロフィール画像' },
  'p100_t311': { 'zh-cn': '选择图片', 'en': 'Select Image', 'jp': '画像を選択してください' },
  'p100_t330': { 'zh-cn': '账号', 'en': 'Account', 'jp': 'アカウント' },
  'p100_t331': { 'zh-cn': '用户名', 'en': 'Username', 'jp': 'ユーザー名' },
  'p100_t340': { 'zh-cn': '联系邮箱', 'en': 'Contact Email Address', 'jp': 'メールアドレス' },
  'p100_t350': { 'zh-cn': '请输入邮箱', 'en': 'Please enter email', 'jp': 'メールアドレスを入力してください' },
  'p100_t351': { 'zh-cn': '偏好语言', 'en': 'Preferred Language', 'jp': '好みの言語' },
  'p100_t352': { 'zh-cn': '请输入偏好语言', 'en': 'Please enter preferred language', 'jp': '好みの言語を入力してください' },




  'p100_t380': { 'zh-cn': '联系手机', 'en': 'Contact Mobile Number', 'jp': '携帯番号' },
  'p100_t390': { 'zh-cn': '国家代码', 'en': 'Country Code', 'jp': '国番号' },
  'p100_t400': { 'zh-cn': '请输入手机', 'en': 'Please enter mobile number', 'jp': '携帯番号を入力してください' },
  'p100_t410': { 'zh-cn': '姓', 'en': 'Last Name', 'jp': '姓' },
  'p100_t420': { 'zh-cn': '请输入姓', 'en': 'Please enter last name', 'jp': '姓を入力してください' },
  'p100_t430': { 'zh-cn': '名', 'en': 'First Name', 'jp': '名' },
  'p100_t440': { 'zh-cn': '请输入名', 'en': 'Please enter first name', 'jp': '名前を入力してください' },
  'p100_t450': { 'zh-cn': '生日', 'en': 'Date of Birth', 'jp': '生年月日' },
  'p100_t460': { 'zh-cn': '请选择生日', 'en': 'Please choose date of birth', 'jp': '生年月日を入力してください' },
  'p100_t470': { 'zh-cn': '公司信息', 'en': 'Company Information', 'jp': '企業情報' },
  'p100_t480': { 'zh-cn': '公司名称', 'en': 'Corporate Name', 'jp': '会社名' },
  'p100_t490': { 'zh-cn': '请输入公司名称', 'en': 'Please enter company name', 'jp': '会社名を入力してください' },
  'p100_t500': { 'zh-cn': '公司电话', 'en': 'Corporate Phone No.  ', 'jp': '電話番号' },
  'p100_t510': { 'zh-cn': '请输入公司电话', 'en': 'Enter company phone No.', 'jp': '電話番号を入力してください' },
  'p100_t520': { 'zh-cn': '行业分类', 'en': 'Category', 'jp': '製品・サービスのカテゴリー' },
  'p100_t530': { 'zh-cn': '请选择行业分类', 'en': 'Please choose industry category', 'jp': '製品・サービスのカテゴリーを選択してください' },
  'p100_t540': { 'zh-cn': '职位', 'en': 'Job Title', 'jp': 'ポジション' },
  'p100_t550': { 'zh-cn': '请输入职位', 'en': 'Please enter job title', 'jp': '役職を入力してください' },
  'p100_t560': { 'zh-cn': '公司logo', 'en': 'Company Logo', 'jp': '企業ロゴ' },
  'p100_t570': { 'zh-cn': '公司网站', 'en': 'Company Website', 'jp': '企業ウェブサイト' },
  'p100_t580': { 'zh-cn': '请输入公司网站', 'en': 'Please enter company website', 'jp': 'ウェブサイトのURLを入力してください' },
  'p100_t590': { 'zh-cn': '公司地址', 'en': 'Corporate Address', 'jp': '住所' },
  'p100_t600': { 'zh-cn': '请输入公司地址', 'en': 'Please enter company address', 'jp': '住所を入力してください' },
  'p100_t610': { 'zh-cn': '营业执照', 'en': 'Business license', 'jp': 'ビジネスライセンス' },
  'p100_t620': { 'zh-cn': '到期时间', 'en': 'Business License Expiration Date', 'jp': '期限切れ' },
  'p100_t630': { 'zh-cn': '请选择营业执照到期时间', 'en': 'Please choose business license expiration date', 'jp': '営業許可証の有効期限を選択してください' },
  'p100_t640': { 'zh-cn': '企业规模', 'en': 'Company Size', 'jp': '事業規模' },
  'p100_t650': { 'zh-cn': '请选择企业规模', 'en': 'Please choose company size', 'jp': 'エンタープライズ規模を選択してください' },
  'p100_t660': { 'zh-cn': '1-10人', 'en': 'Less than10 staffs', 'jp': '1-10人' },
  'p100_t670': { 'zh-cn': '11-100人', 'en': '10—100 staffs', 'jp': '11-100人' },
  'p100_t680': { 'zh-cn': '101-1000人', 'en': '100-1000 staffs', 'jp': '101-1000人' },
  'p100_t690': { 'zh-cn': '1001-10000人', 'en': '1000-10000 staffs', 'jp': '1,001-10,000人' },
  'p100_t700': { 'zh-cn': '10000人以上', 'en': 'Greater than 10000 staffs', 'jp': '10,000人以上' },
  'p100_t750': { 'zh-cn': '修改', 'en': 'Change', 'jp': '更新' },
  'p100_t779': { 'zh-cn': '请选择角色', 'en': 'Please select role', 'jp': '役割をお願いします' },
  'p100_t780': { 'zh-cn': '请选择用户类型', 'en': 'Please choose user type', 'jp': 'ユーザータイプを選択してください' },
  'p100_t790': { 'zh-cn': '请输入邮箱', 'en': 'Please enter email', 'jp': 'メールアドレスを入力してください' },
  'p100_t800': { 'zh-cn': '请输入昵称', 'en': 'Please enter username ', 'jp': 'ニックネームを入力してください' },
  'p100_t810': { 'zh-cn': '请输入密码', 'en': 'Please enter password', 'jp': 'パスワードを入力してください' },
  'p100_t820': { 'zh-cn': '请输入确认密码', 'en': 'Please confirm password', 'jp': 'パスワードを再入力してください' },
  'p100_t880': { 'zh-cn': '上传大小不能超过5Mb', 'en': '5MB max file size', 'jp': '5MB以上のファイルはアップロードできません' },
  'p100_t890': { 'zh-cn': '上传中...', 'en': 'Uploading...', 'jp': 'アップロード中...' },
  'p100_t891': { 'zh-cn': '加载中...', 'en': 'Loading...', 'jp': '読み込み中...' },
  'p100_t900': { 'zh-cn': '已经提交', 'en': 'Already submitted', 'jp': 'アップロード完了' },
  'p100_t910': { 'zh-cn': '请耐心等候', 'en': 'Kindly wait patiently', 'jp': 'お待ちください' },
  'p100_t970': { 'zh-cn': '用户信息修改成功!', 'en': 'User information successfuly changed', 'jp': 'ユーザー情報の変更が完了しました！' },

  'p101_t100': { 'zh-cn': '注册', 'en': 'REGISTER', 'jp': '登録' },
  'p101_t110': { 'zh-cn': '使用已有账号登录', 'en': 'Log in with an existing account', 'jp': '既存のアカウントでログイン' },
  'p101_t120': { 'zh-cn': '用户类型', 'en': 'User Type', 'jp': 'ユーザータイプ' },
  'p101_t130': { 'zh-cn': '资源方', 'en': 'Rights Holder', 'jp': '権利元' },
  'p101_t140': { 'zh-cn': '赞助商', 'en': 'Sponsor', 'jp': 'スポンサー' },
  'p101_t150': { 'zh-cn': '其他用户', 'en': 'Other User', 'jp': 'その他のユーザー' },
  'p101_t160': { 'zh-cn': '角色', 'en': 'Role', 'jp': 'ユーザー属性' },
  'p101_t170': { 'zh-cn': '请选择角色', 'en': 'Please choose your role', 'jp': 'ユーザー属性を選択してください' },
  'p101_t180': { 'zh-cn': '资源拥有者', 'en': 'Rights Owner', 'jp': '権利元' },
  'p101_t190': { 'zh-cn': '资源拥有者', 'en': 'Rights Owner', 'jp': '権利元' },
  'p101_t200': { 'zh-cn': '资源代理', 'en': 'Sports Agent', 'jp': '権利元代理' },
  'p101_t210': { 'zh-cn': '资源代理', 'en': 'Sports Agent', 'jp': '権利元代理' },
  'p101_t220': { 'zh-cn': '其他', 'en': 'Others', 'jp': 'その他' },
  'p101_t230': { 'zh-cn': '其他', 'en': 'Others', 'jp': 'その他' },
  'p101_t240': { 'zh-cn': '角色', 'en': 'Role', 'jp': 'ユーザー属性' },
  'p101_t250': { 'zh-cn': '请选择角色', 'en': 'Please choose your role', 'jp': 'ユーザー属性を選択してください' },
  'p101_t260': { 'zh-cn': '品牌主', 'en': 'Advertiser', 'jp': '広告主' },
  'p101_t270': { 'zh-cn': '品牌主', 'en': 'Advertiser', 'jp': '広告主' },
  'p101_t280': { 'zh-cn': '品牌营销代理', 'en': 'Agency', 'jp': '広告代理' },
  'p101_t290': { 'zh-cn': '品牌营销代理', 'en': 'Agency', 'jp': '広告代理' },
  'p101_t300': { 'zh-cn': '其他', 'en': 'Others', 'jp': 'その他' },
  'p101_t310': { 'zh-cn': '其他', 'en': 'Others', 'jp': 'その他' },
  'p101_t330': { 'zh-cn': '账号', 'en': 'Account', 'jp': 'アカウント' },
  'p101_t340': { 'zh-cn': '请输入账号', 'en': 'Please enter account', 'jp': 'アカウントを入力してください' },
  'p101_t350': { 'zh-cn': '账号为邮箱格式', 'en': 'Email', 'jp': 'アカウントはメール形式です' },
  'p101_t360': { 'zh-cn': '联系邮箱', 'en': 'Contact email', 'jp': 'メールアドレスに連絡します。' },
  'p101_t361': { 'zh-cn': '用户名', 'en': 'Username', 'jp': 'ユーザー名' },
  'p101_t362': { 'zh-cn': '请输入用户名', 'en': 'Your username in the web page. i.e. SponsorForce', 'jp': 'ユーザー名はサイト内での表示名。例：SponsorForce' },

  'p101_t370': { 'zh-cn': '请输入可以联系到您的邮箱', 'en': 'Please enter an email address where you can be reached', 'jp': 'ご連絡可能なメールの入力をお願いします。' },
  'p101_t380': { 'zh-cn': '密码', 'en': 'Password', 'jp': 'パスワード' },
  //'p101_t390': { 'zh-cn': '请输入密码', 'en': 'Please enter password', 'jp': 'パスワードを入力してください'},
  'p101_t391': { 'zh-cn': '密码为字母,数字,长度8-32,不含空格', 'en': 'The password contains 8 to 32 letters and digits without Spaces', 'jp': '8文字以上、半角英数字で構成され、スペースなし' },


  'p101_t400': { 'zh-cn': '确认密码', 'en': 'Confirm Password', 'jp': 'パスワード再入力' },
  'p101_t410': { 'zh-cn': '请输入确认密码', 'en': 'Please confirm password', 'jp': 'パスワード再入力' },
  'p101_t411': { 'zh-cn': '我同意', 'en': 'I agree with', 'jp': '同意' },
  'p101_t420': { 'zh-cn': '用户协议', 'en': 'User Agreement', 'jp': 'ユーザー規約' },
  'p101_t421': { 'zh-cn': '隐私政策', 'en': 'Privacy', 'jp': 'プライバシー条項' },
  'p101_t430': { 'zh-cn': '注册', 'en': 'SIGN UP', 'jp': '登録' },
  'p101_t440': { 'zh-cn': '会员协议条款', 'en': 'Terms of membership agreement', 'jp': '会員契約条項です' },
  'p101_t450': { 'zh-cn': '确认', 'en': 'Confirm', 'jp': '確認' },
  'p101_t451': { 'zh-cn': '关闭', 'en': 'Close', 'jp': '关闭' },
  'p101_t460': { 'zh-cn': '该账号已注册', 'en': 'The account has been registered', 'jp': 'このアカウントは登録済みです' },
  'p101_t470': { 'zh-cn': '该邮箱已注册', 'en': 'The email has been registered', 'jp': 'このメールアドレスは登録済みです' },
  'p101_t489': { 'zh-cn': '请选择角色', 'en': 'Please select role', 'jp': '役割をお願いします' },
  'p101_t490': { 'zh-cn': '请选择用户类型', 'en': 'Please select user type', 'jp': 'ユーザータイプを選択してください' },
  'p101_t500': { 'zh-cn': '请输入登录账号', 'en': 'Please enter user account', 'jp': 'このアカウントは登録済みです' },
  'p101_t510': { 'zh-cn': '请输入邮箱', 'en': 'Please enter email', 'jp': 'メールアドレスを入力してください' },
  'p101_t520': { 'zh-cn': '请输入密码', 'en': 'Please enter password', 'jp': 'パスワードを入力してください' },
  'p101_t521': { 'zh-cn': '密码为字母,数字,长度8-32,不含空格', 'en': 'The password contains 8 to 32 letters and digits without Spaces', 'jp': '8文字以上、半角英数字で構成され、スペースなし' },
  'p101_t530': { 'zh-cn': '请输入确认密码', 'en': 'Please enter correct password', 'jp': 'パスワードを再入力してください' },
  'p101_t531': { 'zh-cn': '请输入用户名', 'en': 'Please enter username', 'jp': 'ユーザー名の入力をお願いします' },


  'p101_t550': { 'zh-cn': '请选择角色', 'en': 'Please choose your role', 'jp': 'ユーザー属性を選択してください' },
  'p101_t560': { 'zh-cn': '密码不一致', 'en': 'Password inconsistency', 'jp': 'パスワードが一致しません' },
  'p101_t570': { 'zh-cn': '密码不一致', 'en': 'Password inconsistency', 'jp': 'パスワードが一致しません' },
  'p101_t580': { 'zh-cn': '该账号已注册', 'en': 'The account has been registered', 'jp': 'このメールアドレスは登録済みです' },
  'p101_t590': { 'zh-cn': '该邮箱已注册', 'en': 'The email has been registered', 'jp': 'このメールアドレスは登録済みです' },
  'p101_t600': { 'zh-cn': '请先同意用户协议', 'en': 'Please agree to the user notice first', 'jp': 'ユーザー規約に同意してください' },
  'p101_t601': { 'zh-cn': '注册完成。已给您邮箱发送了激活邮件，请及时激活后登录使用本网站，谢谢！', 'en': 'Registration completed. An activation email has been sent to your mailbox. Please activate it promptly and log in to use the website. Thank you!', 'jp': '登録が完了しました。アクティベーションメールがあなたのメールボックスに送信されました。速やかにアクティベーションを行い、ログインしてウェブサイトをご利用ください。ありがとうございます！' },
  'p101_t610': { 'zh-cn': '注册成功', 'en': 'Registration Successfully', 'jp': '登録が完了しました' },



  // 'p101_t800': { 'zh-cn': '上传并分享您的资料', 'en': 'Upload and share your information', 'jp': '「マイペイジ」にてリストアップしたい協賛案件の詳細を記入して保存'},
  // 'p101_t810': { 'zh-cn': '通过选择您最喜欢的活动或接受赞助商邀请来开始合作','en':'Begin collaborating by choosing your favourite campaigns or accepting sponsor invitations','jp':''},
  // 'p101_t820': { 'zh-cn': '与赞助商进行一对一沟通，洽谈合作签约','en':'Engage in one-on-one in-depth communication with sponsors and negotiate cooperation details','jp':''},
  // 'p101_t830': { 'zh-cn': '通过力赞平台签约，确保按时收到赞助费','en':'Sign your sponsorship deal on SponsorForce platform to ensure timely receipt of sponsorship fees','jp':''},
  // 'p101_t840': { 'zh-cn': '创建#话题，收获更多赞助商关注','en':'Create #Topics, gain more attention from sponsors','jp':''},
  // 'p101_t141': { 'zh-cn': '赞助商','en':'Sponsors','jp':'スポンサー'},
  // 'p101_t850': { 'zh-cn': '搜索全球体育资源以匹配您的需求','en':'Discover various of global sports properties to meet your needs','jp':''},
  // 'p101_t860': { 'zh-cn': '向心仪的资源方发出邀请','en':'Send an invitation to the desired rights holders','jp':''},
  // 'p101_t870': { 'zh-cn': '发布你的市场计划和需求，让更多资源方看到','en':'Create campaign proposal by setting clear goals and deliverables, attract rights holders to work with you','jp':''},
  // 'p101_t880': { 'zh-cn': '与资源方进行一对一沟通，洽谈合作细节','en':'Engage in one-on-one in-depth communication with sponsors and negotiate cooperation details','jp':''},
  // 'p101_t890': { 'zh-cn': '通过力赞平台签约，确保赞助权益成功落实','en':'Sign your sponsorship deal on SponsorForce platform to ensure the successful implementation of sponsorship rights','jp':''},
  // 'p101_t900': { 'zh-cn': '创建#话题，吸引更多商业伙伴加入','en':'Create #Topics, attract more business partners to join in','jp':''},
  // // 'p101_t150': { 'zh-cn': '其他用户','en':'Other User','jp':''},
  // 'p101_t920': { 'zh-cn': '注册、创建您的资料','en':'Sign up, create your profile','jp':''},
  // 'p101_t930': { 'zh-cn': '创建#话题，提升你的行业影响力','en':'Post #Topics, amplify your industry influence by creating more opportunities, value, and impact.','jp':''},


  'p101_t800': { 'zh-cn': '上传并分享您的资料', 'en': 'Upload and share your information', 'jp': '「マイペイジ」にてリストアップしたい協賛案件の詳細を記入して保存' },
  'p101_t810': { 'zh-cn': '浏览品牌方需求', 'en': 'Browse brand request', 'jp': 'ブランドのニーズを閲覧' },
  'p101_t820': { 'zh-cn': '寻找有共同价值观的赞助商并与之联系', 'en': 'Find a sponsors with common values and contact it', 'jp': '価値観を共有するスポンサーを見つけ、つながる' },
  'p101_t830': { 'zh-cn': '与赞助商进行一对一深度沟通', 'en': 'Make one -to -one in -depth communication with sponsors', 'jp': 'スポンサーとの1対1の綿密なコミュニケーション' },
  'p101_t840': { 'zh-cn': '通过力赞与赞助商签约，确保及时收到赞助费', 'en': 'Signing with sponsors through SponsorForce to ensure that the sponsorship fee is received in time', 'jp': 'SponsorForceを通じてスポンサーと契約し、スポンサー料をタイムリーに受け取る' },
  'p101_t841': { 'zh-cn': '分享动态、数据和经验等内容，收获更多赞助商关注', 'en': 'Share dynamic, data and experience, and gain more sponsors\'attention', 'jp': 'ニュース、データ、経験を共有し、スポンサーからの注目を集める' },

  'p101_t141': { 'zh-cn': '品牌方', 'en': 'Sponsor', 'jp': '広告主' },

  'p101_t850': { 'zh-cn': '搜索全球体育资源，匹配您的需求', 'en': 'Search for global sports resources and match your needs', 'jp': 'ブランドストーリー、ケーススタディやその他のコンテンツを共有することで、より多くのコミュニティメンバーに企業のフィロソフィーを伝える' },
  'p101_t860': { 'zh-cn': '向符合您价值观和需求的资源方发出邀请', 'en': 'Send an invitation to the property party that meets your values and needs', 'jp': 'グローバルベースでニーズに合った案件を検索' },
  'p101_t870': { 'zh-cn': '创建你的品牌活动和计划，让更多人看到您的需求', 'en': 'Create your brand activities and plans so that more people can see your needs', 'jp': '「協賛リクエスト」を作成してリリースし、貴社のニーズをより多くの人々に知ってもらう' },
  'p101_t880': { 'zh-cn': '与资源方进行一对一深度沟通', 'en': 'Make one -on -one in -depth communication with the property side', 'jp': '権利元と1対1のプライベートなコミュニケーションを行う' },
  'p101_t890': { 'zh-cn': '通过力赞与资源方签约，确保赞助权益成功落实', 'en': 'Signing with the property party through SponsorForce to ensure the successful implementation of the sponsorship rights and interests', 'jp': 'SponsorForceのサポートのもと、スポンサーシップ契約を結び、権利の実施を円滑に行う' },
  'p101_t900': { 'zh-cn': '分享品牌故事、业务数据等内容，吸引更多合作者加入', 'en': 'Share brand stories, business data and other content, attract more partners to join', 'jp': 'ブランドストーリー、ビジネスデータ、その他のコンテンツを共有することで、より多くのパートナーに参加してもらうことができます。' },
  'p101_t920': { 'zh-cn': '注册并完善您的资料', 'en': 'Register and improve your information', 'jp': '登録し、情報を改善する' },
  'p101_t930': { 'zh-cn': '创建您的话题，分享您的专业知识和经验，收获更多关注，提升您的行业影响力和领导力', 'en': 'Create your topic, share your professional knowledge and experience, gain more attention, and enhance your industry influence and leadership.', 'jp': '独自のトピックを作成し、専門知識や経験を共有することで、より多くの注目を集め、業界の影響力やリーダーシップを高めることができます。' },


  'p101_t931': { 'zh-cn': '话题（社区）', 'en': 'Community Section', 'jp': 'コミュニティ' },
  'p101_t932': { 'zh-cn': '响应', 'en': 'Apply', 'jp': 'コミュニケーションを取る' },
  'p101_t933': { 'zh-cn': '“即时消息”功能', 'en': 'Instant Messaging', 'jp': 'リアルタイム・メッセージ' },
  'p101_t934': { 'zh-cn': '仅查看', 'en': 'View Only', 'jp': '閲覧のみ' },







  'p102_t100': { 'zh-cn': '登录', 'en': 'LOGIN', 'jp': 'サインイン' },
  'p102_t110': { 'zh-cn': '账号', 'en': 'Account', 'jp': 'アカウント' },
  'p102_t111': { 'zh-cn': '账号为邮箱地址', 'en': 'Account is email', 'jp': 'アカウントはメールアドレスです' },
  'p102_t120': { 'zh-cn': '密码', 'en': 'Password', 'jp': 'パスワード' },
  'p102_t130': { 'zh-cn': '没有账号', 'en': 'Don\'t have an account? ', 'jp': 'アカウントがありません' },
  'p102_t140': { 'zh-cn': '立即注册', 'en': 'Sign up now', 'jp': '今すぐ登録' },
  'p102_t150': { 'zh-cn': '找回密码', 'en': 'Forget your password?', 'jp': 'パスワードの再設定' },
  'p102_t160': { 'zh-cn': '登 录', 'en': 'LOGIN', 'jp': 'サインイン' },
  'p102_t170': { 'zh-cn': '请输入登录账号', 'en': 'Please enter your username', 'jp': 'ログインアカウントを入力してください' },
  'p102_t180': { 'zh-cn': '请输入密码', 'en': 'Please enter password', 'jp': 'パスワードを入力してください' },
  'p103_t100': { 'zh-cn': '找回密码', 'en': 'Forget your password?', 'jp': 'パスワードの再設定' },
  'p103_t110': { 'zh-cn': '邮箱', 'en': 'Contact email address', 'jp': 'メールアドレス' },
  'p103_t120': { 'zh-cn': '立即登录', 'en': 'Login now', 'jp': '今すぐサインイン' },
  'p103_t130': { 'zh-cn': '确认', 'en': 'Confirmation', 'jp': '確認' },
  'p103_t140': { 'zh-cn': '请输入邮箱', 'en': 'Please enter email', 'jp': 'メールアドレスを入力してください' },
  'p103_t150': { 'zh-cn': '已向您填写的邮箱发送了一封邮件，请注意查收', 'en': 'I have sent an email to your email address, please check it carefully', 'jp': 'あなたのメールアドレスにメールが送信されました。メールボックスをご確認ください。' },
  'p104_t100': { 'zh-cn': '登录账号', 'en': 'Login account', 'jp': 'ログインアカウント' },
  'p104_t110': { 'zh-cn': '使用电子邮箱作为登录账号', 'en': 'Use email as login account', 'jp': '電子メールアドレスをログインアカウントとして使用する' },
  'p104_t120': { 'zh-cn': '旧密码', 'en': 'Old password', 'jp': '古いパスワード' },
  'p104_t130': { 'zh-cn': '请输入旧密码', 'en': 'Please enter old password', 'jp': '古いパスワードを入力してください' },
  'p104_t140': { 'zh-cn': '新密码', 'en': 'New password', 'jp': '新しいパスワード' },
  'p104_t150': { 'zh-cn': '请输入新密码', 'en': 'Please enter new password', 'jp': '新しいパスワードを入力してください' },
  'p104_t160': { 'zh-cn': '确认密码', 'en': 'Confirm password', 'jp': 'パスワード再入力' },
  'p104_t170': { 'zh-cn': '请确认密码', 'en': 'Please confirm password', 'jp': 'パスワードを再入力してください' },
  'p104_t180': { 'zh-cn': '修改', 'en': 'Modify', 'jp': '更新' },
  'p104_t190': { 'zh-cn': '两次密码输入不一致', 'en': 'The two password inputs are inconsistent', 'jp': '2回のパスワード入力が一致しません' },

  'p104_t210': { 'zh-cn': '请输入旧密码', 'en': 'Please enter your old password', 'jp': '古いパスワードを入力してください' },
  'p104_t220': { 'zh-cn': '请输入新密码', 'en': 'Please enter a new password', 'jp': '新しいパスワードを入力してください' },
  'p104_t230': { 'zh-cn': '请确认密码', 'en': 'Please confirm password', 'jp': 'パスワードを再入力してください' },

  'p104_t250': { 'zh-cn': '密码修改成功', 'en': 'Password Modification Successfully', 'jp': 'パスワードの変更に成功しました' },
  'p104_t251': { 'zh-cn': '链接复制成功', 'en': 'Link Copy Successfully', 'jp': 'リンクコピーに成功しました' },
  'p104_t252': { 'zh-cn': '链接复制失败,请手动复制', 'en': 'Link Copy Failed，Please copy manually', 'jp': 'リンクコピーに失敗しましたので、手動でコピーしてください' },


  'p105_t089': { 'zh-cn': '请选择需要创建的资源类型', 'en': 'Please select property type', 'jp': '協賛対象の案件タイプを選択してください' },
  'p105_t090': { 'zh-cn': '基本信息', 'en': 'Basic Information', 'jp': '基本情報' },
  'p105_t091': { 'zh-cn': '更多信息', 'en': 'More Information', 'jp': 'より詳しい情報' },
  'p105_t092': { 'zh-cn': '历史成就', 'en': 'Historic achievement', 'jp': '歴史的成果' },
  'p105_t093': { 'zh-cn': '媒体转播', 'en': 'Media', 'jp': '放送及び露出' },

  'p105_t100': { 'zh-cn': '资源信息', 'en': 'Property Information ', 'jp': '案件情報' },
  'p105_t110': { 'zh-cn': '资源类型', 'en': 'Property Type', 'jp': '案件タイプ' },
  'p105_t120': { 'zh-cn': '请选择资源类型', 'en': 'Please select a property type', 'jp': '協賛対象の案件タイプを選択してください' },
  'p105_t130': { 'zh-cn': '姓名', 'en': 'Name', 'jp': '名前' },
  'p105_t140': { 'zh-cn': '名称', 'en': 'Property Name', 'jp': '案件名' },
  'p105_t150': { 'zh-cn': '请输入', 'en': 'Please enter', 'jp': '入力してください' },
  'p105_t151': { 'zh-cn': '英文名称', 'en': 'Alphabetic Name', 'jp': '英文の案件名' },
  'p105_t152': { 'zh-cn': '请输入英文名称', 'en': 'Please enter the name in alphabet if different from above', 'jp': '英文の案件名があれば入力してください' },


  'p105_t160': { 'zh-cn': '姓名', 'en': 'Name', 'jp': '名前' },
  'p105_t161': { 'zh-cn': '请输入姓名', 'en': 'Please enter name', 'jp': '名前を入カしてください' },

  'p105_t170': { 'zh-cn': '名称', 'en': 'Property Name', 'jp': '案件名' },
  'p105_t171': { 'zh-cn': '请输入名称', 'en': 'Please enter property name', 'jp': '案件名を入カしてください' },
  'p105_t172': { 'zh-cn': '授权市场', 'en': 'Available Market', 'jp': '権利市場範囲' },
  'p105_t173': { 'zh-cn': '面议', 'en': 'Negotiable', 'jp': '面接' },

  'p105_t180': { 'zh-cn': '性别', 'en': 'Gender', 'jp': '性別' },
  'p105_t190': { 'zh-cn': '请选择性别', 'en': 'Please select gender', 'jp': '性別を選択してください' },
  'p105_t200': { 'zh-cn': '男', 'en': 'Male', 'jp': '男性' },
  'p105_t210': { 'zh-cn': '女', 'en': 'Female', 'jp': '女性' },
  'p105_t211': { 'zh-cn': '无', 'en': 'None', 'jp': 'なし' },

  'p105_t220': { 'zh-cn': '其他', 'en': 'Others', 'jp': 'その他' },
  'p105_t230': { 'zh-cn': '生日', 'en': 'Date of Birth', 'jp': '生年月日' },
  'p105_t240': { 'zh-cn': '请选择生日', 'en': 'Please select your birthday', 'jp': '生年月日を入力してください' },
  'p105_t250': { 'zh-cn': '头像/标识', 'en': 'Profile Image/Logo', 'jp': 'プロフィール写真/Logo' },
  'p105_t260': { 'zh-cn': '照片', 'en': 'Photo', 'jp': 'ギャラリー' },
  'p105_t270': { 'zh-cn': '最多5张', 'en': 'Up to 5 images', 'jp': '最大5枚' },
  'p105_t280': { 'zh-cn': '国家', 'en': 'Country', 'jp': '国名' },
  'p105_t290': { 'zh-cn': '地区', 'en': 'Region', 'jp': '地域' },
  'p105_t291': { 'zh-cn': '国家/地区', 'en': 'Country/Region', 'jp': '国名/地域' },
  'p105_t300': { 'zh-cn': '请选择国家/地区', 'en': 'Please select a country/region', 'jp': '国/地域を選択してください' },
  'p105_t310': { 'zh-cn': '地区', 'en': 'Region', 'jp': '地域' },
  'p105_t320': { 'zh-cn': '请输入城市,多个城市用逗号（,）分割', 'en': 'Multiple cities are separated by commas (,)', 'jp': '複数の都市をコンマで分割しま' },

  'p105_t340': { 'zh-cn': '项目', 'en': 'Sports', 'jp': '案件種類・スポーツ種目' },
  'p105_t350': { 'zh-cn': '请选择项目', 'en': 'Please select a sports event', 'jp': '案件種類・スポーツ種目を入力してください' },
  'p105_t351': { 'zh-cn': '当前状态', 'en': 'Current Status', 'jp': '現在の状態' },
  'p105_t352': { 'zh-cn': '请输入当前状态', 'en': 'Please enter current status', 'jp': '現在の状態を入力してください' },
  'p105_t353': { 'zh-cn': '居住地', 'en': 'Residence', 'jp': '居住地' },
  'p105_t354': { 'zh-cn': '请输入居住地', 'en': 'Please enter residence', 'jp': '居住地を入力してください' },




  'p105_t360': { 'zh-cn': '个人主页', 'en': 'Homepage', 'jp': '公式ウェブサイト' },
  'p105_t370': { 'zh-cn': '请输入个人主页', 'en': 'Please enter your homepage URL', 'jp': '公式ウェブサイトまたはWikipediaなどの紹介URLを入力してください' },
  'p105_t380': { 'zh-cn': '兴趣爱好', 'en': 'Areas of Interest', 'jp': '関心のあるトピック' },
  'p105_t390': { 'zh-cn': '请选择兴趣爱好', 'en': 'Please select areas of interest', 'jp': '関心のあるトピックを選択してください' },
  'p105_t400': { 'zh-cn': '效力队伍', 'en': 'Involved team', 'jp': '所属' },
  'p105_t410': { 'zh-cn': '请输入效力队伍', 'en': 'Please enter the effectiveness team', 'jp': '所属チームや会社名を入力してください' },
  'p105_t420': { 'zh-cn': '成就及获奖记录', 'en': 'Awards and Accolades', 'jp': '受賞歴' },
  'p105_t421': { 'zh-cn': '其他荣誉', 'en': 'Others (if any)', 'jp': 'その他の栄誉' },
  'p105_t430': { 'zh-cn': '请输入成就及获奖记录', 'en': 'Please enter the award record', 'jp': '受賞歴がある場合は入力してください' },
  'p105_t431': { 'zh-cn': '请输入其他荣誉', 'en': 'Please enter other awards and accolades', 'jp': 'その他の栄誉は入力してください' },
  'p105_t432': { 'zh-cn': '时间格式:年月日', 'en': 'Time format:yyyy-mm-dd', 'jp': '時間形式:年月日' },
  'p105_t433': { 'zh-cn': '视频片段', 'en': 'Video Clips', 'jp': '動画リスト' },
  'p105_t434': { 'zh-cn': '视频网址', 'en': 'Video URL', 'jp': '動画リンク' },
  'p105_t440': { 'zh-cn': '重要赛事或活动', 'en': 'Important Events', 'jp': '重要な試合やイベント' },
  'p105_t450': { 'zh-cn': '重要赛事或活动', 'en': 'Important Events', 'jp': '重要な試合やイベント' },
  'p105_t460': { 'zh-cn': '时间', 'en': 'Important Events Time', 'jp': '重要な試合やイベント日程' },
  'p105_t470': { 'zh-cn': '请输入备注', 'en': 'Remarks', 'jp': '詳細を入力' },
  'p105_t480': { 'zh-cn': '成就的标签', 'en': 'Achievement', 'jp': 'メダル獲得などの成果' },
  'p105_t490': { 'zh-cn': '成就的时间', 'en': 'Achievement Time', 'jp': '時間' },
  'p105_t500': { 'zh-cn': '成就的级别', 'en': ' Achievement Level', 'jp': '成果レベル' },
  'p105_t510': { 'zh-cn': '国家级', 'en': 'National Level', 'jp': '国レベル' },
  'p105_t520': { 'zh-cn': '国家级', 'en': 'National Level', 'jp': '国レベル' },
  'p105_t530': { 'zh-cn': '洲际', 'en': 'Intercontinental', 'jp': '大陸レベル' },
  'p105_t540': { 'zh-cn': '洲际', 'en': 'Intercontinental', 'jp': '大陸レベル' },
  'p105_t550': { 'zh-cn': '世界级', 'en': 'World-class', 'jp': '世界レベル' },
  'p105_t560': { 'zh-cn': '世界级', 'en': 'World-class', 'jp': '世界レベル' },
  'p105_t570': { 'zh-cn': '成就的类型', 'en': 'Achievement Type', 'jp': 'メダルの種類' },
  'p105_t580': { 'zh-cn': '第一名', 'en': 'Champion ', 'jp': '金メダル' },
  'p105_t590': { 'zh-cn': '第一名', 'en': 'Champion ', 'jp': '金メダル' },
  'p105_t600': { 'zh-cn': '第二名', 'en': 'Second ', 'jp': '銀メダル' },
  'p105_t610': { 'zh-cn': '第二名', 'en': 'Second ', 'jp': '銀メダル' },
  'p105_t620': { 'zh-cn': '第三名', 'en': 'Third ', 'jp': '銅メダル' },
  'p105_t630': { 'zh-cn': '第三名', 'en': 'Third ', 'jp': '銅メダル' },
  'p105_t640': { 'zh-cn': '其他', 'en': 'Others', 'jp': 'その他' },
  'p105_t650': { 'zh-cn': '其他', 'en': 'Others', 'jp': 'その他' },
  'p105_t660': { 'zh-cn': '成就的描述', 'en': 'Description of Achievements', 'jp': '詳細' },
  'p105_t670': { 'zh-cn': '国家', 'en': 'Country', 'jp': '国名' },
  'p105_t680': { 'zh-cn': '地区', 'en': 'Region', 'jp': '地域' },
  'p105_t690': { 'zh-cn': '请选择国家', 'en': 'Please select a country', 'jp': '国を選択してください' },
  'p105_t700': { 'zh-cn': '地区', 'en': 'Region', 'jp': '地域' },
  'p105_t710': { 'zh-cn': '城市', 'en': 'City', 'jp': '都市' },
  'p105_t720': { 'zh-cn': '请输入城市,多个城市用逗号（,）分割', 'en': 'Multiple cities are separated by commas (,)', 'jp': '複数の都市をコンマで分割しま' },
  'p105_t730': { 'zh-cn': '地点', 'en': 'place', 'jp': '地区' },
  'p105_t740': { 'zh-cn': '请输入地点', 'en': 'Please enter the location', 'jp': '地区を入力してください' },
  'p105_t750': { 'zh-cn': '时间范围', 'en': 'Time Frame', 'jp': '開催期間' },
  'p105_t760': { 'zh-cn': '起始时间', 'en': 'Sponsor Period', 'jp': '開始日' },
  'p105_t770': { 'zh-cn': '结束时间', 'en': 'End Date', 'jp': '終了日' },
  'p105_t780': { 'zh-cn': '播出方式', 'en': 'Broadcasting Method', 'jp': '放送方式' },
  'p105_t790': { 'zh-cn': '请选择播出类型', 'en': 'Please select the broadcast type', 'jp': '放送タイプを選択してください' },
  'p105_t800': { 'zh-cn': '电视直播', 'en': 'Live TV broadcasting', 'jp': 'テレビ中継' },
  'p105_t810': { 'zh-cn': '电视直播', 'en': 'Live TV broadcasting', 'jp': 'テレビ中継' },
  'p105_t820': { 'zh-cn': '网络直播', 'en': 'Online live streaming', 'jp': 'Webストリーミング' },
  'p105_t830': { 'zh-cn': '网络直播', 'en': 'Online live streaming', 'jp': 'Webストリーミング' },
  'p105_t840': { 'zh-cn': '其他形式播出', 'en': 'Other forms of broadcasting', 'jp': 'その他の形式で放送' },
  'p105_t850': { 'zh-cn': '其他形式播出', 'en': 'Other forms of broadcasting', 'jp': 'その他の形式で放送' },
  'p105_t860': { 'zh-cn': '播出平台', 'en': 'Broadcast Platform', 'jp': '放送プラットフォーム' },
  'p105_t861': { 'zh-cn': '其他媒体合作伙伴', 'en': 'Other Media', 'jp': 'その他放送及び露出媒体' },
  'p105_t862': { 'zh-cn': '请输入其他媒体合作伙伴', 'en': 'Please enter other media', 'jp': 'その他放送及び露出媒体を入カしてください' },
  'p105_t870': { 'zh-cn': '请输入播出平台', 'en': 'Please enter the broadcasting platform', 'jp': '放送プラットフォームを入力してください' },
  'p105_t880': { 'zh-cn': '项目', 'en': 'Sports', 'jp': '案件種類・スポーツ種目' },
  'p105_t890': { 'zh-cn': '请选择项目', 'en': 'Please select a sports event', 'jp': '案件種類・スポーツ種目を入力してください' },
  'p105_t891': { 'zh-cn': '发布成功', 'en': 'Publish Successfully', 'jp': 'リリース成功です' },

  'p105_t900': { 'zh-cn': '相册', 'en': 'Album', 'jp': 'アルバム冊' },
  'p105_t910': { 'zh-cn': '最多5张', 'en': 'Up to 5 images', 'jp': '最大アップロード数: 5枚' },
  'p105_t911': { 'zh-cn': '上传大小不能超过5Mb', 'en': '5MB max file size', 'jp': 'サイズ制限: 5MBまで' },
  'p105_t920': { 'zh-cn': '网站', 'en': 'Website', 'jp': 'ホームページ' },
  'p105_t930': { 'zh-cn': '请输入网站', 'en': 'Please enter the website', 'jp': 'URLを入力してください' },
  // 'p105_t940': { 'zh-cn': '对外的报价', 'en': 'External quotation', 'jp': '対外的オファー'},
  // 'p105_t950': { 'zh-cn': '请输入对外的报价', 'en': 'Please enter the external quotation', 'jp': '外部見積もりを入力してください'},
  'p105_t960': { 'zh-cn': '性质', 'en': 'Profession Type', 'jp': 'クラブタイプ' },
  'p105_t970': { 'zh-cn': '性质', 'en': 'Profession Type', 'jp': 'クラブタイプ' },
  'p105_t980': { 'zh-cn': '职业', 'en': 'Professional', 'jp': 'プロフェッショナル' },
  'p105_t990': { 'zh-cn': '职业', 'en': 'Professional', 'jp': 'プロフェッショナル' },
  'p105_t1000': { 'zh-cn': '非职业', 'en': 'Non Professional', 'jp': 'アマチュア' },
  'p105_t1010': { 'zh-cn': '非职业', 'en': 'Non Professional', 'jp': 'アマチュア' },
  'p105_t1020': { 'zh-cn': '职业', 'en': 'Occupation', 'jp': 'プロフェッショナル' },
  'p105_t1030': { 'zh-cn': '职业等级', 'en': 'Professional level', 'jp': '職業等級' },
  'p105_t1040': { 'zh-cn': '职业等级', 'en': 'Professional level', 'jp': '職業等級' },
  'p105_t1050': { 'zh-cn': '一级', 'en': 'First level', 'jp': '1レベル' },
  'p105_t1060': { 'zh-cn': '一级', 'en': 'First level', 'jp': '1レベル' },
  'p105_t1070': { 'zh-cn': '二级', 'en': 'Second level', 'jp': 'にだん' },
  'p105_t1080': { 'zh-cn': '二级', 'en': 'Second level', 'jp': 'にだん' },
  'p105_t1090': { 'zh-cn': '三级', 'en': 'Level 3', 'jp': '3レベル' },
  'p105_t1100': { 'zh-cn': '三级', 'en': 'Level 3', 'jp': '3レベル' },
  'p105_t1110': { 'zh-cn': '其他', 'en': 'Others', 'jp': 'その他' },
  'p105_t1120': { 'zh-cn': '其他', 'en': 'Others', 'jp': 'その他' },
  'p105_t1130': { 'zh-cn': '现有赞助商', 'en': 'Existing Sponsors', 'jp': 'スポンサー' },
  'p105_t1140': { 'zh-cn': '赞助商', 'en': 'Sponsors', 'jp': 'スポンサー' },
  'p105_t1150': { 'zh-cn': '赞助商类别', 'en': 'Sponsorship Category', 'jp': 'プロダクトカテゴリー' },
  'p105_t1160': { 'zh-cn': '赞助截止日期', 'en': 'Sponsorship Deadline', 'jp': '契約終了日' },
  'p105_t1170': { 'zh-cn': '简介', 'en': 'Introduction', 'jp': '案件概要' },
  'p105_t1180': { 'zh-cn': '请输入简介。'+'\n'+'示例:世界跆拳道（WT）是负责全球范围内跆拳道运动的国际管理机构。WT成立于1973年，总部位于韩国首尔，是监督这种充满活力、全方位接触式武术的开发、推广和监管的公认权威机构。', 'en': 'Please enter the brief introduction of the property', 'jp': '案件概要を入力してください' },
  'p105_t1190': { 'zh-cn': '创始年份', 'en': 'Founding Year', 'jp': '創設年' },
  'p105_t1200': { 'zh-cn': '创始年份', 'en': 'Founding Year', 'jp': '創設年' },
  'p105_t1210': { 'zh-cn': '不支持的行业类型', 'en': 'Unacceptable Categories', 'jp': '協賛できないカテゴリー' },
  'p105_t1220': { 'zh-cn': '请选择不支持的行业类型', 'en': 'Please select an unacceptable categories', 'jp': '協賛できないカテゴリーを選択してください' },
  'p105_t1230': { 'zh-cn': '博彩业', 'en': 'Gaming Industry', 'jp': '賭博業' },
  'p105_t1240': { 'zh-cn': '博彩业', 'en': 'Gaming Industry', 'jp': '賭博業' },
  'p105_t1250': { 'zh-cn': '烟草', 'en': 'Cigarettes', 'jp': 'タバコ' },
  'p105_t1260': { 'zh-cn': '烟草', 'en': 'Cigarettes', 'jp': 'タバコ' },
  'p105_t1270': { 'zh-cn': '非处方', 'en': 'Over the Counter', 'jp': '一般用医薬品' },
  'p105_t1280': { 'zh-cn': '非处方', 'en': 'Over the Counter', 'jp': '一般用医薬品' },
  'p105_t1290': { 'zh-cn': '烈性酒', 'en': 'Strong Liquor', 'jp': '蒸留酒などのハードリカー' },
  'p105_t1300': { 'zh-cn': '烈性酒', 'en': 'Strong Liquor', 'jp': '蒸留酒などのハードリカー' },
  'p105_t1310': { 'zh-cn': '其他酒类', 'en': 'Other Alcoholic Beverages', 'jp': 'その他アルコール類' },
  'p105_t1320': { 'zh-cn': '其他酒类', 'en': 'Other Alcoholic Beverages', 'jp': 'その他アルコール類' },
  'p105_t1330': { 'zh-cn': '金融理财', 'en': 'Financial Management', 'jp': '金融資産運用' },
  'p105_t1340': { 'zh-cn': '金融理财', 'en': 'Financial Management', 'jp': '金融資産運用' },
  'p105_t1350': { 'zh-cn': '小额贷', 'en': 'Small Loans', 'jp': '消費者金融' },
  'p105_t1360': { 'zh-cn': '小额贷', 'en': 'Small Loans', 'jp': '消費者金融' },
  'p105_t1370': { 'zh-cn': '区块链及虚拟货币', 'en': 'Blockchain and Virtual Currency', 'jp': 'ブロックチェーンと仮想通貨' },
  'p105_t1380': { 'zh-cn': '区块链及虚拟货币', 'en': 'Blockchain and Virtual Currency', 'jp': 'ブロックチェーンと仮想通貨' },
  'p105_t1390': { 'zh-cn': '社交媒体粉丝数', 'en': 'Social Media', 'jp': 'SNSフォロワー数' },
  'p105_t1400': { 'zh-cn': 'Facebook粉丝数', 'en': 'Facebook Followers', 'jp': 'Facebookフォロワー数' },
  'p105_t1410': { 'zh-cn': 'Instgram粉丝数', 'en': 'Instagram Followers', 'jp': 'Instgramフォロワー数' },
  'p105_t1420': { 'zh-cn': '推特粉丝数', 'en': 'X (Twitter) Followers', 'jp': 'X (旧ツイッター）フォロワー数' },
  'p105_t1430': { 'zh-cn': 'Tiktok粉丝数', 'en': 'TikTok Followers', 'jp': 'Tiktokフォロワー数' },
  'p105_t1440': { 'zh-cn': '微博粉丝数', 'en': 'Webio Followers', 'jp': '微博フォロワー数' },
  'p105_t1450': { 'zh-cn': '抖音粉丝数', 'en': 'Jitterbug Followers', 'jp': 'Jitterbugフォロワー数' },
  'p105_t1460': { 'zh-cn': '小红书粉丝数', 'en': 'Xiaohongshu Followers', 'jp': 'The Redフォロワー' },
  'p105_t1470': { 'zh-cn': '新增', 'en': 'Save', 'jp': '新規作成' },
  'p105_t1480': { 'zh-cn': '修改', 'en': 'Modify', 'jp': '更新' },
  'p105_t1481': { 'zh-cn': '配置赞助包', 'en': 'Config Sponsorship', 'jp': '協賛パッケージ管理' },
  'p105_t1482': { 'zh-cn': '保存', 'en': 'Save', 'jp': '保存' },
  'p105_t1483': { 'zh-cn': '保存', 'en': 'Save', 'jp': '保存' },

  'p105_t1490': { 'zh-cn': '确认删除该行数据', 'en': 'Confirm deleting the data in this row', 'jp': '行データの削除の確認' },
  'p105_t1500': { 'zh-cn': '确认', 'en': 'Confirmation', 'jp': '確認' },
  'p105_t1510': { 'zh-cn': '确定', 'en': 'Confirm', 'jp': 'を選択して、' },
  'p105_t1520': { 'zh-cn': '取消', 'en': 'Cancel', 'jp': 'キャンセル' },
  'p105_t1530': { 'zh-cn': '请选择资源类型', 'en': 'Please select a property type', 'jp': '協賛対象の案件タイプを選択してください' },
  'p105_t1531': { 'zh-cn': '请输入时间范围', 'en': 'Please enter time range', 'jp': '時間範囲を入力願います ' },
  'p105_t1532': { 'zh-cn': '请输入城市', 'en': 'Please enter city', 'jp': '都市を入力します' },
  'p105_t1533': { 'zh-cn': '请输入性别', 'en': 'Please enter gender', 'jp': '性別入力お願いします' },
  'p105_t1540': { 'zh-cn': '请输入资源名称', 'en': 'Please enter the property name', 'jp': '案件名を入力してください' },
  'p105_t1541': { 'zh-cn': '请上传头像或Logo', 'en': 'Please upload profile photo or logo', 'jp': 'プロフィール写真をお願いします' },

  'p105_t1550': { 'zh-cn': '请选择国家', 'en': 'Please select a country', 'jp': '国を選択してください' },
  'p105_t1560': { 'zh-cn': '地区', 'en': 'Region', 'jp': '地域' },
  'p105_t1570': { 'zh-cn': '请输入简介', 'en': 'Please enter an introduction', 'jp': '概要を入力してください' },
  'p105_t1580': { 'zh-cn': '生日必须输入', 'en': 'Birthday must be entered', 'jp': '誕生日は必ず入力してください' },
  'p105_t1590': { 'zh-cn': '请输入项目', 'en': 'Please enter sports items', 'jp': '案件種類・スポーツ種目を入力してください' },
  'p105_t1600': { 'zh-cn': '请选择职业类型', 'en': 'Please select profession type', 'jp': '職種を選択してください' },
  'p105_t1610': { 'zh-cn': '至少上传一张照片', 'en': 'Upload at least one photo', 'jp': '少なくとも1枚の写真をアップロードしてください' },


  'p105_t1640': { 'zh-cn': '确定移除该图片', 'en': 'Confirm to remove the image', 'jp': '写真を削除する' },
  'p105_t1650': { 'zh-cn': '提示', 'en': 'Reminder.', 'jp': 'リマインダー' },
  'p105_t1660': { 'zh-cn': '确定', 'en': 'Confirm', 'jp': 'を選択して、' },
  'p105_t1670': { 'zh-cn': '取消', 'en': 'Cancel', 'jp': 'キャンセル' },
  'p105_t1680': { 'zh-cn': '上传大小不能超过5Mb', 'en': 'The upload size cannot exceed 5Mb', 'jp': 'サイズ制限: 5MBまで' },
  'p105_t1690': { 'zh-cn': '最多5张', 'en': 'Up to 5 images', 'jp': '最大5枚' },
  'p105_t1700': { 'zh-cn': '上传中', 'en': 'Uploading', 'jp': 'アップロード中' },



  'p105_t1740': { 'zh-cn': '保存成功', 'en': 'Save Successfully', 'jp': '保存に成功しました' },
  'p105_t1750': { 'zh-cn': '修改已审核资源会触发重新审核', 'en': 'Modifying approved resources will trigger a re audit', 'jp': 'レビュー済み案件を変更すると再レビューがトリガーされます' },
  'p105_t1760': { 'zh-cn': '是否继续?', 'en': 'Do you want to continue?', 'jp': 'レビュー済み案件を変更すると再レビューがトリガーされます' },
  'p105_t1770': { 'zh-cn': '确认', 'en': 'Confirmation', 'jp': '確認' },
  'p105_t1780': { 'zh-cn': '确定', 'en': 'Confirm', 'jp': 'を選択して、' },
  'p105_t1790': { 'zh-cn': '取消', 'en': 'Cancel', 'jp': 'キャンセル' },
  'p105_t1800': { 'zh-cn': '资源修改成功', 'en': 'Property Modification Successfully', 'jp': '案件内容の変更に成功しました' },



  'p105_t1840': { 'zh-cn': '赞助包价格必须大于0', 'en': 'sponsorship price cannot be 0', 'jp': '協賛パッケージ価格は0にしてはならない' },
  'p105_t1841': { 'zh-cn': '请填写币种', 'en': 'Please enter currency', 'jp': '通貨を入力してください' },
  'p105_t1850': { 'zh-cn': '请输入价格', 'en': 'Please enter price', 'jp': '価格を入力願います' },
  'p105_t1851': { 'zh-cn': '请输入授权市场', 'en': 'Please enter available market', 'jp': '権利市場範囲を入力願います' },

  // 'p105_t2000': { 'zh-cn': '日元', 'en': 'JPY', 'jp': '日本円'},
  // 'p105_t2010': { 'zh-cn': '人民币', 'en': 'CNY', 'jp': '人民元'},
  // 'p105_t2020': { 'zh-cn': '港币', 'en': 'HKD', 'jp': '香港ドル（HK$）'},
  // 'p105_t2030': { 'zh-cn': '瑞士法郎', 'en': 'CHF', 'jp': 'スイスフラン'},
  // 'p105_t2040': { 'zh-cn': '新加坡元', 'en': 'SGD', 'jp': 'シンガポール・ドル'},
  // 'p105_t2050': { 'zh-cn': '美元', 'en': 'USD', 'jp': '米ドル（US$）'},
  // 'p105_t2060': { 'zh-cn': '澳元', 'en': 'AUD', 'jp': 'オーストラリア・ドル（AU$）'},
  // 'p105_t2070': { 'zh-cn': '英镑', 'en': 'GBP', 'jp': '英ポンド（£）'},
  // 'p105_t2080': { 'zh-cn': '欧元', 'en': 'EUR', 'jp': 'ユーロ（€）'},
  // 'p105_t2090': { 'zh-cn': '加币', 'en': 'CAD', 'jp': 'カナダドル'},

  'p105_t2000': { 'zh-cn': '日元', 'en': 'JPY', 'jp': 'JPY' },
  'p105_t2010': { 'zh-cn': '人民币', 'en': 'CNY', 'jp': 'CNY' },
  'p105_t2020': { 'zh-cn': '港币', 'en': 'HKD', 'jp': 'HKD' },
  'p105_t2030': { 'zh-cn': '瑞士法郎', 'en': 'CHF', 'jp': 'CHF' },
  'p105_t2040': { 'zh-cn': '新加坡元', 'en': 'SGD', 'jp': 'SGD' },
  'p105_t2050': { 'zh-cn': '美元', 'en': 'USD', 'jp': 'USD' },
  'p105_t2060': { 'zh-cn': '澳元', 'en': 'AUD', 'jp': 'AUD' },
  'p105_t2070': { 'zh-cn': '英镑', 'en': 'GBP', 'jp': 'GBP' },
  'p105_t2080': { 'zh-cn': '欧元', 'en': 'EUR', 'jp': 'EUR' },
  'p105_t2090': { 'zh-cn': '加币', 'en': 'CAD', 'jp': 'CAD' },

  'p105_t2100':{ 'zh-cn': '现役运动员', 'en': 'Active Athlete', 'jp': '現役の選手' },
  'p105_t2101':{ 'zh-cn': '退役', 'en': 'Retired', 'jp': '退役' },
  'p105_t2103':{ 'zh-cn': '其他', 'en': 'Others', 'jp': 'その他' },

  'p105_t2110':{ 'zh-cn': '翻译', 'en': 'Translate', 'jp': '翻訳' },



  // 'p106_t100': { 'zh-cn': '新增权益包', 'en': 'Add benifit package', 'jp': '協賛パッケージを追加'},
  'p106_t101': { 'zh-cn': '新增权益', 'en': 'Add Another Benefit', 'jp': '協賛権利の追加' },
  'p106_t102': { 'zh-cn': '新增赞助包', 'en': 'Add Sponsorship Package', 'jp': '協賛パッケージの追加' },

  'p106_t110': { 'zh-cn': '返回', 'en': 'Return', 'jp': '戻る' },
  // 'p106_t120': { 'zh-cn': '权益包名称', 'en': 'Sponsor Level Name', 'jp': '資本パッケージ名'},
  'p106_t121': { 'zh-cn': '赞助包级别', 'en': 'Sponsorship Level', 'jp': '協賛パッケージレベル' },
  // 'p106_t130': { 'zh-cn': '权益包价格', 'en': 'Sponsor Levelprice', 'jp': '資本パッケージ価格'},
  'p106_t131': { 'zh-cn': '价格', 'en': 'Price', 'jp': '価格' },
  'p106_t140': { 'zh-cn': '创建时间', 'en': 'Creation Time', 'jp': '作成日時' },
  'p106_t141': { 'zh-cn': '查看', 'en': 'View', 'jp': 'びゅー' },
  'p106_t150': { 'zh-cn': '操作', 'en': 'Operation', 'jp': '管理' },
  'p106_t160': { 'zh-cn': '详情', 'en': 'Details', 'jp': '詳細' },
  'p106_t170': { 'zh-cn': '确定删除该赞助包？', 'en': 'Are you sure to delete this sponsorship?', 'jp': '協賛パッケージを削除しますか？' },
  'p106_t180': { 'zh-cn': '删除', 'en': 'Delete', 'jp': '削除' },
  'p106_t190': { 'zh-cn': '删除成功', 'en': 'Delete Successfully', 'jp': '削除成功' },
  // 'p107_t100': { 'zh-cn': '权益包名称', 'en': 'Sponsor Level Name', 'jp': '資本パッケージ名'},
  'p107_t101': { 'zh-cn': '赞助包级别', 'en': 'Sponsorship Level', 'jp': '協賛パッケージレベル' },
  // 'p107_t110': { 'zh-cn': '请输入权益包名称', 'en': 'Please enter the name of the benefit package', 'jp': '資本パッケージ名を入力してください'},
  'p107_t111': { 'zh-cn': '赞助包级别', 'en': 'Please enter Sponsorship Level', 'jp': '協賛パッケージレベル' },
  // 'p107_t120': { 'zh-cn': '权益包价格', 'en': 'Sponsor Levelprice', 'jp': '資本パッケージ価格'},
  'p107_t121': { 'zh-cn': '参考价格', 'en': 'Price', 'jp': '概算価格' },
  'p107_t130': { 'zh-cn': '日元', 'en': 'Japanese Yen', 'jp': '円' },
  'p107_t140': { 'zh-cn': '币种', 'en': 'Currency', 'jp': '通貨' },

  // 'p107_t350': { 'zh-cn': '添加权益', 'en': 'Add Benefits', 'jp': '権利内容を追加'},

  // 'p107_t370': { 'zh-cn': '请选择赞助权益类型', 'en': 'Please select sponsorship benefit type', 'jp': '協賛権利の種類を選択してください'},
  'p107_t380': { 'zh-cn': '请输入活动名称', 'en': 'Please enter the activity name', 'jp': 'アクティビティ名を入力してください' },
  'p107_t390': { 'zh-cn': '规格数', 'en': 'Number of specifications', 'jp': '規格数' },
  'p107_t400': { 'zh-cn': '请选择规格', 'en': 'Specifications', 'jp': '仕様を選択' },
  'p107_t410': { 'zh-cn': '次', 'en': 'Second', 'jp': '次' },
  'p107_t420': { 'zh-cn': '次', 'en': 'Second', 'jp': '次' },
  'p107_t430': { 'zh-cn': '月', 'en': 'Month', 'jp': '月' },
  'p107_t440': { 'zh-cn': '月', 'en': 'Month', 'jp': '月' },
  'p107_t450': { 'zh-cn': '年', 'en': 'Year', 'jp': '年' },
  'p107_t460': { 'zh-cn': '年', 'en': 'Year', 'jp': '年' },
  'p107_t470': { 'zh-cn': '请输入备注', 'en': 'Please enter remark', 'jp': '詳細を入力' },
  'p107_t480': { 'zh-cn': '确定删除该行?', 'en': 'Are you sure you want to delete this line?', 'jp': '削除確定ですか?' },
  'p107_t481': { 'zh-cn': '权益内容', 'en': 'Sponsorship Benefits', 'jp': '協賛権利内容' },
  'p107_t490': { 'zh-cn': '保存', 'en': 'Save', 'jp': '保存' },
  'p107_t491': { 'zh-cn': '返回', 'en': 'Back', 'jp': '戻る' },

  'p107_t501': { 'zh-cn': '请至少填写一个赞助包', 'en': 'Please fill in at least one sponsorship package', 'jp': '少なくとも1つの協賛パッケージに記入してください' },
  'p107_t502': { 'zh-cn': '请填写赞助商类别', 'en': 'Please enter sponsorship category', 'jp': 'プロダクトカテゴリーを入力願います' },

  'p107_t510': { 'zh-cn': '请输入名称', 'en': 'Please enter the name of the benefit package', 'jp': '資本パッケージ名を入力してください' },
  'p107_t511': { 'zh-cn': '请输入赞助包级别', 'en': 'Please enter the Sponsorship level', 'jp': '協賛パッケージレベル' },
  'p107_t512': { 'zh-cn': '请输入价格', 'en': 'Please enter the price', 'jp': '価格を入力願います' },
  'p107_t513': { 'zh-cn': '输入权益项', 'en': 'Please enter the sponsorship benefits', 'jp': '協賛権利を入力願います' },


  'p107_t514': { 'zh-cn': '权益', 'en': 'Benefit', 'jp': '協賛権利' },

  'p107_t530': { 'zh-cn': '保存成功', 'en': 'Save Successfully', 'jp': '保存に成功しました' },


  'p107_t550': { 'zh-cn': '冠名赞助商', 'en': 'Title sponsor', 'jp': 'タイトル・スポンサー' },
  'p107_t560': { 'zh-cn': '战略合作伙伴', 'en': 'Strategic partners', 'jp': '戦略的パートナー' },
  'p107_t570': { 'zh-cn': '官方赞助商', 'en': 'Official sponsor', 'jp': 'オフィシャル・スポンサー' },
  'p107_t580': { 'zh-cn': '官方供应商', 'en': 'Official supplier', 'jp': 'オフィシャル・サプライヤー' },
  'p107_t590': { 'zh-cn': '其他', 'en': 'Others', 'jp': 'その他' },

  'p107_t591': { 'zh-cn': '赞助方式', 'en': 'Sponsorship Packages', 'jp': '協賛パッケージ' },
  'p107_t592': { 'zh-cn': '选取赞助包', 'en': 'Select Sponsorship', 'jp': '協賛パッケージの選択' },
  'p107_t593': { 'zh-cn': '是否显示价格', 'en': 'Need Show Price?', 'jp': '価格非表示' },
  'p107_t594': { 'zh-cn': '显示价格', 'en': 'Display the Price', 'jp': '価格表示' },
  'p107_t595': { 'zh-cn': '隐藏价格', 'en': 'Hide the Price', 'jp': '価格非表示' },
  'p107_t596': { 'zh-cn': '即使设置“显示价格”，您填写的赞助价格也只将平台认证过的赞助商开放显示。<br>如果设置“隐藏价格”，则您填写的赞助价格将处于任何用户都无法看到的状态。', 'en': 'If you set "Display the Price" the sponsorship price you enter will only be visible to sponsors certified by the platform.<br>If you set "Hide the Price" the sponsorship price you enter will not be visible to any users.', 'jp': '「価格表示」を設定しても、入力されたスポンサー価格情報はプラットフォームによって認証されたスポンサーにのみ表示されます。<br>「価格非表示」を設定すると、入力されたスポンサー価格情報はすべてのユーザーに表示されなくなります。' },
  'p107_t597': { 'zh-cn': '您输入的价格只会向“赞助商用户”显示。', 'en': 'The sponsorship price you enter will only be visible to those Sponors Accounts.', 'jp': '入力したスポンサー価格はスポンサーアカウントにのみ表示されます。' },

  'p107_t600': { 'zh-cn': '品牌代言', 'en': 'Endorsement', 'jp': 'ブランドエンドースメント' },
  'p107_t610': { 'zh-cn': '社交媒体', 'en': 'Social Media Collaboration', 'jp': 'SNS投稿' },
  'p107_t620': { 'zh-cn': '出席活动', 'en': 'Event Appearance', 'jp': 'イベント参加' },
  'p107_t630': { 'zh-cn': '参与平面及视频拍摄', 'en': 'Photo/Video Shooting', 'jp': '撮影' },
  'p107_t640': { 'zh-cn': '肖像授权', 'en': 'Usage of Image Rights', 'jp': '肖像権使用' },
  'p107_t650': { 'zh-cn': '直播带货', 'en': 'Live-Commerce Appearance', 'jp': 'ライブ・コマース' },
  'p107_t660': { 'zh-cn': '实物赞助', 'en': 'In-kind Sponsorship Deal', 'jp': 'VIK' },

  'p107_t700': { 'zh-cn': '参考价格', 'en': 'Price', 'jp': '概算価格' },
  'p107_t701': { 'zh-cn': '最低价格', 'en': 'Starting Price', 'jp': '最低価格' },
  'p107_t702': { 'zh-cn': '请输入最低价格', 'en': 'Please enter starting price', 'jp': '最低価格を入力願います' },

  'p107_t710': { 'zh-cn': '备注', 'en': 'Remark', 'jp': '備考' },

  'p107_t719': { 'zh-cn': '您好，我对与您发布的资源合作感兴趣，是否可以沟通一下？', 'en': 'We are interested in collaborating with the property you have posted. Could we discuss this further?', 'jp': 'こんにちは、あなたが発表したリソースの協力に興味があります。話し合うことはできますか。' },

  'p107_t720': { 'zh-cn': '您好，我们是【%1】行业的品牌，我对与您发布的【%2】资源合作感兴趣，是否可以沟通一下？', 'en': 'We are a brand in the [%1] industry, and we are interested in collaborating with the [%2] property you have posted. Could we discuss this further?', 'jp': '私たちは【%1】カテゴリーの企業であり、御社が掲載されている【%2】の案件との協力に興味があります。詳細についてお話しさせていただけないでしょうか？' },
  'p107_t721': { 'zh-cn': '我的行业', 'en': 'My product category is', 'jp': '弊社の製品カテゴリーは ' },
  'p107_t722': { 'zh-cn': 'です', 'en': '', 'jp': 'です' },



  'p108_t100': { 'zh-cn': '资源名称', 'en': 'Property Name', 'jp': '案件名' },
  'p108_t110': { 'zh-cn': '资源名称', 'en': 'Property Name', 'jp': '案件名' },
  'p108_t120': { 'zh-cn': '资源类型', 'en': 'Property Type', 'jp': '案件タイプ' },
  'p108_t130': { 'zh-cn': '资源类型', 'en': 'Property Type', 'jp': '案件タイプ' },
  'p108_t140': { 'zh-cn': '查询', 'en': 'Search', 'jp': '検索' },
  'p108_t150': { 'zh-cn': '新增资源', 'en': 'Add Property', 'jp': '新規案件登録' },
  'p108_t160': { 'zh-cn': '资源', 'en': 'Property', 'jp': '案件' },
  'p108_t170': { 'zh-cn': '资源名称', 'en': 'Property Name', 'jp': '案件名' },
  'p108_t180': { 'zh-cn': '资源类型', 'en': 'Property Type', 'jp': '案件タイプ' },
  'p108_t181': { 'zh-cn': '查看次数', 'en': 'Views', 'jp': '閲覧回数' },
  'p108_t190': { 'zh-cn': '资源状态', 'en': 'Property Status', 'jp': '状態' },
  'p108_t200': { 'zh-cn': '待审核', 'en': 'Pending Review', 'jp': 'レビュー保留中' },
  'p108_t201': { 'zh-cn': '未发布', 'en': 'Unpublished', 'jp': '未公開' },
  'p108_t202': { 'zh-cn': '已发布', 'en': 'Published', 'jp': '公開済み' },
  'p108_t210': { 'zh-cn': '审核通过', 'en': 'Approved', 'jp': 'レビューパス' },
  'p108_t220': { 'zh-cn': '审核拒绝', 'en': 'Review Reject', 'jp': 'レビュー拒否' },
  'p108_t230': { 'zh-cn': '废弃', 'en': 'Abandoned', 'jp': '廃棄' },


  'p108_t260': { 'zh-cn': '创建时间', 'en': 'Creation Time', 'jp': '作成日時' },
  'p108_t261': { 'zh-cn': '发布时间', 'en': 'Published Time', 'jp': '投稿日時' },
  'p108_t270': { 'zh-cn': '操作', 'en': 'Operation', 'jp': '管理' },
  'p108_t280': { 'zh-cn': '权益配置', 'en': 'Benifit Allocation', 'jp': '協賛権利管理' },
  'p108_t290': { 'zh-cn': '修改', 'en': 'Modify', 'jp': '更新' },
  'p108_t291': { 'zh-cn': '撤销发布', 'en': 'Unpublish', 'jp': '投稿を取り消す' },
  'p108_t292': { 'zh-cn': '确定撤销发布?', 'en': 'Confirm to unpublish?', 'jp': '投稿の取消を確定しますか?' },
  'p108_t300': { 'zh-cn': '确定删除该资源?', 'en': 'Confirm to delete this property?', 'jp': '案件の削除を確定しますか?' },
  'p108_t310': { 'zh-cn': '删除', 'en': 'Delete', 'jp': '削除' },
  'p108_t320': { 'zh-cn': '删除成功', 'en': 'Delete Successfully', 'jp': '案件を削除しました' },
  'p108_t321': { 'zh-cn': '撤销成功', 'en': 'Undo Successfully', 'jp': '投稿を取り消しました' },


  'p111_t100': { 'zh-cn': '浏览', 'en': 'Browse', 'jp': '閲覧' },
  'p111_t110': { 'zh-cn': '点赞', 'en': 'Like', 'jp': 'いいねを押す' },
  'p111_t120': { 'zh-cn': '评论', 'en': 'Comment', 'jp': 'コメント' },
  'p111_t130': { 'zh-cn': '发布评论', 'en': 'Post Comments', 'jp': 'コメントの投稿' },
  'p111_t140': { 'zh-cn': '发布', 'en': 'Post', 'jp': '投稿' },
  'p111_t150': { 'zh-cn': '评论', 'en': 'Comment', 'jp': 'コメント' },
  'p111_t160': { 'zh-cn': '查看更多评论', 'en': 'View More Comments', 'jp': 'コメントの表示' },
  'p111_t170': { 'zh-cn': '没有更多了', 'en': 'There\'s nothing more left', 'jp': 'これ以上ない' },
  'p111_t180': { 'zh-cn': '暂无数据', 'en': 'Not Available', 'jp': 'データなし' },


  'p111_t210': { 'zh-cn': '评论成功', 'en': 'Comment Successfully', 'jp': 'コメント成功' },
  'p111_t220': { 'zh-cn': '点赞成功', 'en': 'Like Successfully', 'jp': 'いいねを押して成功した' },
  'p111_t230': { 'zh-cn': '收藏成功', 'en': 'Collection Successfully', 'jp': 'お気に入り追加に成功しました' },
  'p112_t100': { 'zh-cn': '话题 为您带来明天的机遇', 'en': 'Publish Topics For Sharing And Discussions', 'jp': 'トピックや意見をシェアしてみましょう' },
  'p112_t110': { 'zh-cn': '开启您的内容创建模式，发表有意思的话题，分享有价值的内容。', 'en': 'To share your insights, cases and point of views at the Community', 'jp': 'コンテンツを作成してみる，皆さんに興味持たれるトピックを投稿する。価値あるコンテンツを共有する。' },
  'p112_t120': { 'zh-cn': '发布话题', 'en': 'Post a Topic', 'jp': 'トピックを投稿する' },
  'p112_t130': { 'zh-cn': '暂无数据', 'en': 'Not Available', 'jp': 'データなし' },
  'p113_t100': { 'zh-cn': '粉丝数', 'en': 'Number of followers', 'jp': 'フォロワー' },
  'p113_t110': { 'zh-cn': '人民币', 'en': 'CNY', 'jp': '人民元' },
  'p113_t120': { 'zh-cn': '元', 'en': 'Yuan', 'jp': '元' },
  'p114_t100': { 'zh-cn': '资源简介', 'en': 'Property introduction', 'jp': 'プロパティ紹介' },
  'p114_t110': { 'zh-cn': '社交媒体粉丝数', 'en': 'Social Media', 'jp': 'SNSフォロワー数' },
  'p114_t120': { 'zh-cn': '粉丝', 'en': 'Followers', 'jp': 'フォロワー数' },
  'p114_t130': { 'zh-cn': '现有赞助商', 'en': 'Current Sponsor(s)', 'jp': 'スポンサー' },
  'p114_t140': { 'zh-cn': '不支持行业类型', 'en': 'Unacceptable Categories', 'jp': '対象外の業界' },
  'p114_t150': { 'zh-cn': '详细信息', 'en': 'Details', 'jp': '詳細情報' },
  //'p114_t160': { 'zh-cn': '当前权益包', 'en': 'Current benifit package', 'jp': '現在のエクイティパッケージ'},
  'p114_t161': { 'zh-cn': '当前赞助包', 'en': 'Current benifit package', 'jp': '現在の協賛パッケージ' },
  'p114_t170': { 'zh-cn': '活动名称', 'en': 'Event Name', 'jp': 'イベント名' },
  'p114_t180': { 'zh-cn': '规格', 'en': 'Specification', 'jp': '仕様' },
  'p114_t190': { 'zh-cn': '数量', 'en': 'Quantity', 'jp': '数量' },
  'p114_t200': { 'zh-cn': '内容', 'en': 'Content', 'jp': '内容' },
  //'p114_t210': { 'zh-cn': '所有权益包', 'en': 'Ownership package', 'jp': 'オールライツパッケージ'},
  'p114_t211': { 'zh-cn': '所有赞助包', 'en': 'All sponsorship', 'jp': 'オール協賛パッケージ' },
  'p114_t212': { 'zh-cn': '赞助包', 'en': 'Sponsorship', 'jp': '協賛パッケージ' },
  'p114_t220': { 'zh-cn': '活动名称', 'en': 'Event Name', 'jp': 'イベント名' },
  'p114_t230': { 'zh-cn': '规格', 'en': 'Specification', 'jp': '仕様' },
  'p114_t240': { 'zh-cn': '数量', 'en': 'Quantity', 'jp': '数量' },
  'p114_t250': { 'zh-cn': '内容', 'en': 'Content', 'jp': '内容' },
  'p114_t260': { 'zh-cn': '名字/名称', 'en': 'Last Name', 'jp': '姓' },
  'p114_t270': { 'zh-cn': '性别', 'en': 'Gender', 'jp': '性別' },
  // 'p114_t280': { 'zh-cn': '生日/创始时间', 'en': 'Date of Establishment', 'jp': '設立日'},
  'p114_t281': { 'zh-cn': '生日', 'en': 'Date of Birth', 'jp': '生年月日' },
  'p114_t290': { 'zh-cn': '(曾) 效力队伍', 'en': '(Former) Associated Team', 'jp': '（元）所属チーム' },
  'p114_t300': { 'zh-cn': '兴趣爱好', 'en': 'Areas of Interest', 'jp': '関心のあるトピック' },
  'p114_t310': { 'zh-cn': '官网', 'en': 'Official website', 'jp': '公式ウェブサイト' },
  'p114_t320': { 'zh-cn': '重要成绩', 'en': 'Important achievement(s)', 'jp': '重要な実績' },
  'p114_t330': { 'zh-cn': '重要赛事时间', 'en': 'Important event time', 'jp': '重要なイベント時間' },
  'p114_t331': { 'zh-cn': '重要赛事或活动', 'en': 'Important Events', 'jp': '重要な試合やイベント' },
  'p114_t332': { 'zh-cn': '成就及获奖记录', 'en': 'Award Records', 'jp': '受賞歴' },

  'p114_t340': { 'zh-cn': '名字/名称', 'en': 'Last Name', 'jp': '姓' },
  // 'p114_t350': { 'zh-cn': '生日/创始时间', 'en': 'Date of Establishment', 'jp': '設立日'},
  'p114_t351': { 'zh-cn': '创始时间', 'en': 'Date of Establishment', 'jp': '設立日' },
  'p114_t360': { 'zh-cn': '官网', 'en': 'Official website', 'jp': '公式ウェブサイト' },
  'p114_t370': { 'zh-cn': '名字/名称', 'en': 'Last Name', 'jp': '姓' },
  // 'p114_t380': { 'zh-cn': '生日/创始时间', 'en': 'Date of Establishment', 'jp': '設立日'},
  'p114_t390': { 'zh-cn': '官网', 'en': 'Official website', 'jp': '公式ウェブサイト' },
  'p114_t400': { 'zh-cn': '项目时间', 'en': 'Project Time', 'jp': 'プロジェクト時間' },
  'p114_t410': { 'zh-cn': '转播方式', 'en': 'Broadcasting Method', 'jp': '放送方法' },
  'p114_t420': { 'zh-cn': '播出平台', 'en': 'Broadcast Platform', 'jp': '放送プラットフォーム' },

  'p114_t1510': { 'zh-cn': '我要沟通', 'en': 'Communicate', 'jp': 'コミュニケーションを取る' },
  'p114_t1511': { 'zh-cn': '更多赞助包', 'en': 'More', 'jp': 'More' },
  'p114_t1520': { 'zh-cn': '收藏', 'en': 'Bookmark', 'jp': 'お気に入り追加' },
  'p114_t1530': { 'zh-cn': '取消收藏', 'en': 'Cancel collection', 'jp': 'お気に入り取消し' },
  'p114_t1540': { 'zh-cn': '沟通内容', 'en': 'Communication', 'jp': '先方とコミュニケーションを取りますか？' },
  'p114_t1550': { 'zh-cn': '请确认是否通过以下联系方式联系你？', 'en': 'Please confirm whether you will be contacted by the following contact information?', 'jp': '以下の連絡手段でお客様に連絡することを確認いたしますか？' },
  'p114_t1560': { 'zh-cn': '手机号', 'en': 'Mobile', 'jp': '携帯番号' },
  'p114_t1570': { 'zh-cn': '请输入登录账号', 'en': 'Please enter the login account', 'jp': 'ログインアカウントを入力してください' },
  'p114_t1580': { 'zh-cn': '邮箱', 'en': 'Email', 'jp': 'メールアドレス' },
  'p114_t1600': { 'zh-cn': '取消', 'en': 'Cancel', 'jp': 'キャンセル' },
  'p114_t1610': { 'zh-cn': '确认', 'en': 'Confirm', 'jp': '確認' },

  'p114_t1700':{ 'zh-cn': '精彩照片', 'en': 'Photo', 'jp': '写真' },



  'p115_t100': { 'zh-cn': '探索全球丰富体育资源, 涵盖运动员、队伍、赛事、职业联盟、协会等丰富资源选择。', 'en': 'Effortlessly explore and connect with global sports athletes, teams, events, and leagues.', 'jp': '世界中のスポーツアスリート、チーム、イベント、リーグを簡単に探索してつながることができる' },
  'p115_t101': { 'zh-cn': '本地推荐资源', 'en': 'Local Recommended Properties', 'jp': 'ローカル推奨案件' },
  'p115_t102': { 'zh-cn': '热门推荐资源', 'en': 'Popular Recommended Properties', 'jp': '人気の推奨リソース' },
  'p115_t103': { 'zh-cn': '最新资源', 'en': 'Latest Properties', 'jp': '新着プロパティ情報' },
  'p115_t104': { 'zh-cn': '个人', 'en': '', 'jp': '' },


  'p115_t105': { 'zh-cn': '查询结果', 'en': 'Search Result', 'jp': '検索結果' },

  'p115_t110': { 'zh-cn': '涵盖运动员、队伍、赛事、职业联盟、协会等丰富资源选择。', 'en': 'Easy access to a variety of sports rights holders including athletes, teams, leagues, and more.', 'jp': '権利元が投稿した案件にスポンサーが応募することができます' },
  // 'p115_t110': { 'zh-cn': '申请加入您心仪的品牌方项目，或接受赞助商接洽邀约', 'en': '', 'jp': ''},

  'p115_t120': { 'zh-cn': '输入资源名称、项目或地区', 'en': 'Enter property name, project, or region', 'jp': '案件名を入力' },
  'p115_t130': { 'zh-cn': '类别', 'en': 'Property Category', 'jp': '種類' },
  'p115_t140': { 'zh-cn': '请选择类别', 'en': 'Please select a property category', 'jp': 'カテゴリーを選択してください' },
  'p115_t150': { 'zh-cn': '项目', 'en': 'Subject', 'jp': '種目' },
  'p115_t160': { 'zh-cn': '请选择项目', 'en': 'Please select a project', 'jp': 'プロジェクトを選択してください' },
  'p115_t170': { 'zh-cn': '粉丝数', 'en': 'Number of followers', 'jp': 'フォロワー' },
  'p115_t180': { 'zh-cn': '请选择粉丝数', 'en': 'Please select the number of followers', 'jp': 'フォロワーを選択してください' },
  'p115_t190': { 'zh-cn': '国家/地区', 'en': 'Region/Country', 'jp': '国/地域' },
  'p115_t200': { 'zh-cn': '请选择国家/地区', 'en': 'Please select region/country', 'jp': '国/地域を選択します。' },
  'p115_t210': { 'zh-cn': '价格（人民币）', 'en': 'Price (CNY)', 'jp': '価格' },
  'p115_t220': { 'zh-cn': '请选择价格', 'en': 'Please select a price', 'jp': '価格を選択してください' },
  'p115_t230': { 'zh-cn': '清除全部', 'en': 'Clear all', 'jp': 'すべてクリア」の' },
  'p115_t240': { 'zh-cn': '搜索', 'en': 'Search', 'jp': '検索' },
  'p115_t250': { 'zh-cn': '暂无数据', 'en': 'Not Available', 'jp': 'データなし' },


  'p118_t100': { 'zh-cn': '让赞助更简单', 'en': 'Sponsorship made even easier', 'jp': 'スポンサーシップを更に簡単に' },
  'p118_t110': { 'zh-cn': '力赞是一个创新的体育赞助数字化交易平台社区。以数据为驱动，专注于建立资源持有者、赞助方与专业人士之间独特的连接。我们让赞助变得更简单！', 'en': 'SponsorForce is an innovative digital sports sponsorship trading platform community. Driven by data, we focus on establishing unique connections between property holders, sponsors, and professionals. We make sponsorship easier!', 'jp': 'SponsorForceはスポンサーシップのための、革新的なデジタルプラットフォームです。プロのスポーツコンサルタントのノウハウをはじめとした独自データベースを基に、権利元や広告主間の関係構築をサポート。スポンサーシップをより簡単にします。' },
  'p118_t120': { 'zh-cn': '探索全球体育资源', 'en': 'Exploring the world\'s sponsorable properties.', 'jp': '世界の協賛可能な案件を探す' },
  'p118_t130': { 'zh-cn': '涵盖运动员、队伍、赛事、职业联盟、协会等丰富资源选择。', 'en': 'Easy access to a variety of sports rights holders including athletes, teams, leagues, and more.', 'jp': '選手、チーム、イベント、リーグ、協会、連盟など、豊富な案件を網羅' },
  // 'p118_t130': { 'zh-cn': '申请加入您心仪的品牌方项目，或接受赞助商接洽邀约', 'en': '', 'jp': ''},
  'p118_t140': { 'zh-cn': '输入资源名称、项目或地区', 'en': 'Enter property name, project, or region', 'jp': 'キーワードを入力' },
  'p118_t150': { 'zh-cn': '更多选项', 'en': 'More options', 'jp': '詳細検索' },
  'p118_t160': { 'zh-cn': '基于真实、透明与公平的体育赞助新方式', 'en': 'A new way of sports sponsorship based on authenticity, transparency, and fairness', 'jp': '透明性と公正性に基づくスポンサーシップの新たな形' },
  'p118_t170': { 'zh-cn': '解锁更多可能', 'en': 'More Opportunities', 'jp': '広がる可能性' },
  'p118_t180': { 'zh-cn': '告诉我们您是谁，您的使命是什么，并展现您的价值。', 'en': 'Tell us who you are, what your mission is, and showcase your value.', 'jp': 'あなたの情報やミッションを教えてください' },
  'p118_t190': { 'zh-cn': '浏览品牌需求，申请加入您感兴趣的赞助活动，发现更多赞助机会。', 'en': 'Explore brand requests, apply to join sponsorship activities that interest you, and discover more sponsorship opportunities.', 'jp': '広告主から投稿される「協賛リクエスト」を簡単に確認・申込いただけます。より多くのスポンサーシップのチャンスを作りましょう。' },
  'p118_t200': { 'zh-cn': '发布资源', 'en': 'List your properties', 'jp': 'プロパティを掲載する' },
  'p118_t210': { 'zh-cn': '轻松找到好IP', 'en': 'Easily find good IP addresses', 'jp': '「協賛リクエスト」に応募してみる' },
  // 'p118_t210': { 'zh-cn': '浏览品牌方需求，释放更多的赞助可能', 'en': '', 'jp': ''},

  'p118_t220': { 'zh-cn': '使用我们最全面的搜索工具来锁定能够实现您所有目标的优质资源。', 'en': 'Use our most comprehensive search tools to lock in high-quality resources that can achieve all your goals.', 'jp': '検索ツールで希望に合う高品質な案件を探す' },
  'p118_t230': { 'zh-cn': '创建您的赞助需求，鼓励资源持有者加入到您的品牌活动中，并通过力赞支付费用，无额外收费，更便捷、透明且安全。', 'en': 'Create your sponsorship needs, encourage property holders to join your brand activities, and pay through likes without additional fees, making it more convenient, transparent, and secure.', 'jp': '「協賛リクエスト」を発表し、より多くの権利元に声を届けます。アプローチから契約完了まで、透明性を重要視するSponsorForceを通じて安心してご利用いただけます。' },
  'p118_t240': { 'zh-cn': '发布需求', 'en': 'Publish your sponsorship requests', 'jp': '協賛リクエストを発表' },
  'p118_t250': { 'zh-cn': '越紧密，越可靠', 'en': 'Connecting The Dots', 'jp': '安心なメッセージ機能' },
  'p118_t260': { 'zh-cn': '给您心仪的潜在合作伙伴或赞助商留言， 获取更详细的信息。', 'en': 'Leave a message to your desired potential partner or sponsor for more detailed information.', 'jp': 'スポンサーシップのパートナー候補とより詳細なやりとりを実施' },
  'p118_t270': { 'zh-cn': '我们的即时留言功能将确保对方在第一时间收到您的留言并给予回复。', 'en': 'Our instant messaging feature will ensure that the recipient receives your message and responds promptly.', 'jp': 'インスタントメッセージ機能で素早く、確実にメッセージを送受信できます。' },
  'p118_t280': { 'zh-cn': '查看留言', 'en': 'View messages', 'jp': 'メッセージを見る' },
  'p118_t290': { 'zh-cn': '用数据说话', 'en': 'Data-driven decision-making', 'jp': 'データに基づいたマッチング' },
  'p118_t300': { 'zh-cn': '我们的人工智能算法会考虑目标受众、价值观、目标和地理范围，将赞助商与最兼容的资源持有者进行匹配。', 'en': 'Our artificial intelligence algorithm takes into account the target audience, values, goals, and geographical scope, matching sponsors with the most compatible property holders.', 'jp': 'SponsorForceのAIアルゴリズムがターゲット層を詳細に分析し、最適な権利元とスポンサーをマッチング' },
  'p118_t310': { 'zh-cn': '借助力赞的数据服务，快速发现您从未知道的跨市场机会，以获得最佳投资回报，并轻松查看每个可交付成果的指标。', 'en': 'With the help of SponsorForce\'s data services, quickly discover cross market opportunities that you have never known before, in order to achieve the best return on investment, and easily view metrics for each deliverable.', 'jp': '新たな機会や市場を素早く発見し、目標KPIに向けて最高のROI実現を可能にします。' },
  'p118_t320': { 'zh-cn': '了解更多', 'en': 'Find out more.', 'jp': '詳細はこちら' },
  'p118_t330': { 'zh-cn': '#话题，为您带来明天的机遇', 'en': 'Publish topics for sharing and discussions', 'jp': 'コミュニティを通じた新たなチャンス' },
  'p118_t340': { 'zh-cn': '开启您的内容创建模式，发表有意思的话题，分享有价值的内容。', 'en': 'Activate your content creation mode, post interesting topics, and share valuable content.', 'jp': 'スポンサーシップに関連したニュースやコンテンツの投稿を通じて、関係者とのコミュニケーションや関係を構築。' },
  'p118_t350': { 'zh-cn': '收获更多关注，提升您的行业影响力和领导力。', 'en': 'Gain more attention, enhance your industry influence and leadership.', 'jp': 'より多くの注目を集める，業界への影響力とリーダーシップを高める。' },
  'p118_t360': { 'zh-cn': '发布话题', 'en': 'POST A TOPIC', 'jp': 'トピックを投稿する' },


  'p121_t100': { 'zh-cn': '浏览品牌方需求，释放更多的赞助可能', 'en': 'Connect With Brands Seeking Sponsorship Opportunities', 'jp': '「協賛リクエスト」に応募してみる' },
  'p121_t110': { 'zh-cn': '申请加入您心仪的品牌方项目，或接受赞助商接洽邀约', 'en': 'Rights holders can establish direct communication with brands when their requests align with the properties', 'jp': '権利元が、スポンサーが投稿した「協賛リクエスト」に応募することができます' },
  'p121_t120': { 'zh-cn': '暂无数据', 'en': 'Not Available', 'jp': 'データなし' },


  'p124_t100': { 'zh-cn': '欢迎来到力赞的世界', 'en': 'Welcome to the SponsorForce', 'jp': 'SponsorForceの世界へようこそ' },
  'p124_t110': { 'zh-cn': '让赞助更简单', 'en': 'Sponsorship made even easier', 'jp': 'スポンサーシップを更に簡単に' },
  'p124_t120': { 'zh-cn': '力赞是一个专注于体育赞助的创新交易平台，为品牌和体育赛事提供一套以数据为驱动的数字化赞助解决方案，从而使赞助商轻松地发现和连接到更加适合的赞助机会。为此力赞将向用户提供先进的智能匹配算法，根据赞助商的需求和资源的特点进行匹配。此外，我们还将建立强大的数据分析工具，帮助赞助商衡量回报率。', 'en': 'SponsorForce is an innovative trading platform focusing on sports sponsorship, providing a set of digital sponsorship solutions for brand and sports events, so that sponsors can easily discover and connect to more suitable sponsorship opportunities. For this reason, SponsorForce will provide users with an advanced intelligent matching algorithm, and match the needs of the sponsors and the characteristics of resources. In addition, we will establish a powerful data analysis tool to help sponsor to measure the return rate.', 'jp': 'SponsorForceはスポーツスポンサーシップに特化した革新的な取引プラットフォームで、ブランドやスポーツイベント向けにデータ主導型のデジタルスポンサーシップソリューション一式を提供し、スポンサーがより適切なスポンサーシップ機会を簡単に発見し、結びつけることを可能にします。 この目的のため、SponsorForceは、スポンサーのニーズとプロパティの特性に基づいてスポンサーをマッチングする高度なインテリジェントマッチングアルゴリズムをユーザーに提供します。 さらに、スポンサーがリターンを測定できるよう、強力なデータ分析ツールを構築します。' },
  'p124_t130': { 'zh-cn': '下载力赞介绍', 'en': 'Introduction to Download Likes', 'jp': 'ダウンロード力賛紹介' },
  'p124_t140': { 'zh-cn': '力赞价值观', 'en': 'SponsorForce values', 'jp': 'SponsorForceの価値観' },
  'p124_t150': { 'zh-cn': '真实、平等、透明', 'en': 'True, equality, transparency', 'jp': '信頼性、平等性、透明性' },
  'p124_t160': { 'zh-cn': '力赞的使命', 'en': 'Our Missions', 'jp': 'SponsorForceの使命' },
  'p124_t170': { 'zh-cn': '推动体育赞助行业的可持续发展，为全球的体育赞助市场带来更多透明度和效率，并且为品牌和体育资源提供增长机会，构建跨文化、跨区域的合作与交流网络。', 'en': 'Promote the sustainable development of the sports sponsorship industry, bring more transparency and efficiency to the global sports sponsorship market, and provide growth opportunities for brands and sports resources to build cross -cultural and cross -regional cooperation and exchange networks.', 'jp': 'スポーツスポンサーシップ産業の持続可能な発展を促進し、世界のスポーツスポンサーシップ市場に透明性と効率性をもたらし、ブランドとスポーツ権利元に成長機会を提供し、文化や地域を超えた協力と交流のネットワークを構築すること。' },
  'p124_t180': { 'zh-cn': '力赞优势', 'en': 'Zanzan advantage', 'jp': 'SponsorForceの強み' },
  'p124_t190': { 'zh-cn': '我们以独特的行业见解和创新的技术为基石，通过整合先进的互联网科技，打破传统体育赞助的界限和壁垒，为赞助商和资源方提供全面、高效和安全的解决方案。', 'en': 'We take the unique industry insights and innovation technology as the cornerstone. By integrating advanced Internet technology, we break the boundaries and barriers of traditional sports sponsorship, and provide comprehensive, efficient and secure solutions for sponsors and property parties.', 'jp': '独自の業界洞察力と革新的なテクノロジーを礎に、先進的なインターネットテクノロジーの統合により、従来のスポーツスポンサーシップの境界や障壁を打ち破り、スポンサーと権利元に包括的、効率的、安全なソリューションを提供します。' },
  'p124_t200': { 'zh-cn': '基于真实、可靠和独特连接的体育赞助新方式', 'en': 'New way of sports sponsorship based on authenticity, reliability and unique connection', 'jp': '信頼できる繋がりとスポンサーシップの新しいアプローチ' },
  'p124_t210': { 'zh-cn': '力赞将全球运动员、赛事主办方、联盟协会等资源持有者或代理方与赞助商社区联系起来。  互相支持，共同努力，分享激情和成功。', 'en': 'SponsorForce connects global athletes, event organizers, alliance associations, and other property holders or agents with the sponsor community. Support each other, work together, share passion and success.', 'jp': 'SponsorForceは、世界範囲内で権利元と広告主をスポンサーシップコミュニティの中で結びつけ、知識やノウハウをシェアし、スポンサーシップ取引の簡潔化と効率向上をサポートします。' },
  'p124_t220': { 'zh-cn': '作为体育赞助领域的领军者，我们十分关注跨文化交流，通过构建一个更加开放的跨域合作网络，连接更多体育营销专业人士和公司参与到市场的发展中，无论是本地还是全球。', 'en': 'As a leader in the field of sports sponsorship, we are very concerned about cross -cultural exchanges. By building a more open cross -domain cooperation network, we connect more sports marketing professionals and companies to participate in the development of the market, whether local or global.', 'jp': 'スポーツスポンサーシップのリーダーとして、私たちは異文化コミュニケーションを重視し、より多くのスポーツマーケティングの専門家や企業を結びつけ、よりオープンな領域横断的協力ネットワークを構築することで、国内外を問わず市場の発展に参加しています。' },
  'p124_t230': { 'zh-cn': '一个平台，无限可能', 'en': 'A platform, unlimited possibility', 'jp': '可能性に満ちたプラットフォーム' },
  'p124_t240': { 'zh-cn': '探索一个无缝地、易于使用的力赞体育赞助平台，发现匹配您的营销计划原来是这么的简单。', 'en': 'Exploring a seamless, easy -to -use SponsorForce sports sponsorship platform, found that the marketing plan that matches your market is so simple.', 'jp': '簡単にマッチング機能が使えるSponsorForceのプラットフォームをお試し下さい' },
  'p124_t250': { 'zh-cn': '我是资源方', 'en': 'I am a property party', 'jp': '権利元' },
  'p124_t260': { 'zh-cn': '我是品牌方', 'en': 'I am the brand', 'jp': '広告主' },
  'p124_t270': { 'zh-cn': '全球性资源：提供全球最广泛的体育资源选择', 'en': 'Global Properties: Provide the world\'s most widely selected choice of sports resources', 'jp': 'グローバルプロパティ：世界で最も豊富なスポーツプロパティを提供します。' },
  'p124_t280': { 'zh-cn': '力赞认证：提供全面认证，更值得信赖', 'en': 'SponsorForce certification: providing comprehensive certification, more trustworthy', 'jp': 'SponsorForce認証：包括的な認証を提供し、より信頼できる。' },
  'p124_t290': { 'zh-cn': '最优价格：会员享最优佣金，且无隐藏费用', 'en': 'The optimal price: Member enjoys the best commission, and there is no hidden cost', 'jp': 'ベストプライス: 会員は隠された手数料のないベストコミッションを享受できる' },
  'p124_t300': { 'zh-cn': '可靠：隐私数据保护与安全的支付方案', 'en': 'Reliability: Payment plan for privacy data protection and security', 'jp': '信頼性: プライベートデータ保護と安全な支払いソリューション' },
  'p124_t310': { 'zh-cn': '数据驱动: 智能匹配算法，根据赞助商的需求和资源的特点进行匹配', 'en': 'Data driver: Intelligent matching algorithm, matching according to the needs of the sponsors and the characteristics of resources', 'jp': 'データドリブン：スポンサーのニーズとプロパティの特徴に基づいたインテリジェントなマッチングアルゴリズム' },
  'p124_t320': { 'zh-cn': '个性化服务：联系我们的赞助营销专家', 'en': 'Personalized service: contact our sponsorship marketing expert', 'jp': '個人向けサービス：スポンサーシップマーケティングの専門家にお問い合わせください。' },
  'p124_t330': { 'zh-cn': '资源方', 'en': 'Rights Holder', 'jp': '権利元' },
  'p124_t340': { 'zh-cn': '上传并分享您的资料', 'en': 'Upload and share your information', 'jp': '「マイペイジ」にてリストアップしたい協賛案件の詳細を記入して保存' },
  'p124_t350': { 'zh-cn': '浏览品牌方需求', 'en': 'Browse brand request', 'jp': 'ブランドのニーズを閲覧' },
  'p124_t360': { 'zh-cn': '寻找有共同价值观的赞助商并与之联系', 'en': 'Find a sponsors with common values and contact it', 'jp': '価値観を共有するスポンサーを見つけ、つながる' },
  'p124_t370': { 'zh-cn': '与赞助商进行一对一深度沟通', 'en': 'Make one -to -one in -depth communication with sponsors', 'jp': 'スポンサーとの1対1の綿密なコミュニケーション' },
  'p124_t380': { 'zh-cn': '通过力赞与赞助商签约，确保及时收到赞助费', 'en': 'Signing with sponsors through SponsorForce to ensure that the sponsorship fee is received in time', 'jp': 'SponsorForceを通じてスポンサーと契約し、スポンサー料をタイムリーに受け取る' },
  'p124_t390': { 'zh-cn': '分享动态、数据和经验等内容，收获更多赞助商关注', 'en': 'Share dynamic, data and experience, and gain more sponsors\'attention', 'jp': 'ニュース、データ、経験を共有し、スポンサーからの注目を集める' },
  'p124_t400': { 'zh-cn': '注册 “资源方” 账户', 'en': 'Register the \'Property Fang\'account', 'jp': 'Sources アカウントに登録する' },
  'p124_t410': { 'zh-cn': '品牌方', 'en': 'Sponsor', 'jp': '広告主' },
  'p124_t420': { 'zh-cn': '搜索全球体育资源，匹配您的需求', 'en': 'Search for global sports resources and match your needs', 'jp': 'ブランドストーリー、ケーススタディやその他のコンテンツを共有することで、より多くのコミュニティメンバーに企業のフィロソフィーを伝える' },
  'p124_t430': { 'zh-cn': '向符合您价值观和需求的资源方发出邀请', 'en': 'Send an invitation to the property party that meets your values and needs', 'jp': 'グローバルベースでニーズに合った案件を検索' },
  'p124_t440': { 'zh-cn': '创建你的品牌活动和计划，让更多人看到您的需求', 'en': 'Create your brand activities and plans so that more people can see your needs', 'jp': '「協賛リクエスト」を作成してリリースし、貴社のニーズをより多くの人々に知ってもらう' },
  'p124_t450': { 'zh-cn': '与资源方进行一对一深度沟通', 'en': 'Make one -on -one in -depth communication with the property side', 'jp': '権利元と1対1のプライベートなコミュニケーションを行う' },
  'p124_t460': { 'zh-cn': '通过力赞与资源方签约，确保赞助权益成功落实', 'en': 'Signing with the property party through SponsorForce to ensure the Successfully implementation of the sponsorship rights and interests', 'jp': 'SponsorForceのサポートのもと、スポンサーシップ契約を結び、権利の実施を円滑に行う' },
  'p124_t470': { 'zh-cn': '分享品牌故事、业务数据等内容，吸引更多合作者加入', 'en': 'Share brand stories, business data and other content, attract more partners to join', 'jp': 'ブランドストーリー、ビジネスデータ、その他のコンテンツを共有することで、より多くのパートナーに参加してもらうことができます。' },
  'p124_t480': { 'zh-cn': '注册 “品牌方” 账户', 'en': 'Register the \'Brand Fang\'account', 'jp': 'ブランドパートナー」アカウントに登録する' },
  'p124_t490': { 'zh-cn': '其他用户', 'en': 'Other User', 'jp': 'その他のユーザー' },
  'p124_t500': { 'zh-cn': '注册并完善您的资料', 'en': 'Register and improve your information', 'jp': '登録し、情報を改善する' },
  'p124_t510': { 'zh-cn': '创建您的话题，分享您的专业知识和经验，收获更多关注，提升您的行业影响力和领导力。', 'en': 'Create your topic, share your professional knowledge and experience, gain more attention, and enhance your industry influence and leadership.', 'jp': '独自のトピックを作成し、専門知識や経験を共有することで、より多くの注目を集め、業界の影響力やリーダーシップを高めることができます。' },
  'p124_t520': { 'zh-cn': '注册 “其他用户” 账户', 'en': 'Register the \'Other User\'account', 'jp': 'その他のユーザー」アカウントに登録する' },
  'p124_t530': { 'zh-cn': '立即注册', 'en': 'Register now', 'jp': '今すぐ登録' },
  'p124_t540': { 'zh-cn': '创建 “资源方” 帐户，开始释放赞助机会。', 'en': 'Create a \'property party\'account and start to release sponsorship opportunities.', 'jp': '「権利元」アカウントを作成し、スポンサーシップの可能性を広げましょう' },
  'p124_t550': { 'zh-cn': '立即注册', 'en': 'Register now', 'jp': '今すぐ登録' },
  'p124_t560': { 'zh-cn': '轻松创建 “品牌方” 帐户，登录后，您可以轻松搜索并直接连接到资源方。', 'en': 'Easily create a \'brand party\'account. After logging in, you can easily search and connect directly to the property side.', 'jp': '「広告主」アカウントを作成し、 協賛案件を簡単に検索して繋がりましょう' },
  'p124_t570': { 'zh-cn': '发布资源', 'en': 'List your properties', 'jp': 'プロパティを掲載する' },
  'p124_t580': { 'zh-cn': '完善您的资源信息，吸引尽可能多的品牌， 包括权益、价格、以及您的社交媒体信息—— 我们将在匹配算法中使用他们。', 'en': 'Improve your property information and attract as many brands as possible, including benifit, price, and your social media information -we will use them in matching algorithms.', 'jp': '権利パッケージ、価格、ソーシャルメディア情報などを入力することで、プラットフォームがより多くのスポンサーに推薦できるようになります' },
  'p124_t590': { 'zh-cn': '发布需求', 'en': 'Publish your sponsorship requests', 'jp': '「協賛リクエスト」を発表' },
  'p124_t600': { 'zh-cn': '下一步就是寻找可以合作的候选人或项目。通过创建一个营销活动来开始此过程，就像职位发布一样，吸引资源方来申请并与您合作。', 'en': 'The next step is to find candidates or projects that can cooperate. Starting this process by creating a marketing activity, just like the release of the position, it attracts the resources to apply and cooperate with you.', 'jp': '権利元向けに求人広告のようにスポンサーシップ要望を作成して「協賛リクエスト」として発表してみましょう' },
  'p124_t610': { 'zh-cn': '此外，您也可以通过使用我们的搜索引擎搜索我们的数据库，主动寻找合作的候选人或项目。 让我们帮助您确定谁是最合适的人选，并邀请他们申请参加您的活动。', 'en': 'In addition, you can also use our search engine to search our database to actively find a cooperative candidate or project. Let us help you determine who is the most suitable candidate and invite them to apply for participation in your activities.', 'jp': 'また、当社の検索機能を使ってデータベースを検索し、協賛できる案件を探して興味のある権利元と連絡を取ってみましょう' },
  'p124_t620': { 'zh-cn': '建立合作', 'en': 'To establish cooperation', 'jp': 'スポンサーと繋がる' },
  'p124_t630': { 'zh-cn': '通过选择您最喜欢的品牌活动或接受赞助商邀请来建立合作意向。', 'en': 'Establish a cooperation intention by selecting your favorite brand activities or receiving the invitation of sponsors.', 'jp': '興味のある「協賛リクエスト」に応募していただくか、' },
  'p124_t640': { 'zh-cn': '如果您被品牌选中，将收到品牌向您发送邀请信息。希望这就是您所寻找的，并且接受该邀请。 ', 'en': 'If you are selected by the brand, you will receive an invitation message from the brand. Hope this is what you are looking for and accept the invitation.', 'jp': 'またはプラットフォーム上で広告主からの連絡を受けて、スポンサーシップの商談に入ることができます' },
  'p124_t650': { 'zh-cn': '同时，您可以通过我们的直接访问轻松与品牌方建立联系，并使用 “消息” 功能，与品牌方进行深度沟通。这将促进双方进入合作签约的关键步骤。记住，在平台上保持所有沟通可确保力赞的团队在您需要的时候介入并提供帮助。', 'en': 'At the same time, you can easily connect with the brand side through our direct access, and use the \'message\'function to communicate in depth with the brand side. This will promote the key steps for the two parties to enter the cooperation contract. Remember, maintaining all communication on the platform to ensure that SponsorForce can involve and help when you need it.', 'jp': '同時に、あなたは私たちのダイレクトアクセスを通じてブランドと簡単につながり、「メッセージ」機能を使ってブランドと深くコミュニケーションをとることができます。 そうすることで、両者がパートナーシップの締結という重要なステップに進みやすくなります。 すべてのコミュニケーションをプラットフォーム上で行うことで、リザンのチームが必要な時に必要なことを確実に提供することができます！' },
  'p124_t660': { 'zh-cn': '选择资源', 'en': 'Choose resources', 'jp': 'プロパティの選択' },
  'p124_t670': { 'zh-cn': '一旦您的项目收到来自资源方的申请，我们就可以轻松找到最合适的候选人。 通过我们的直接访问与资源方建立联系，并使用 “消息” 功能，与他们进行深度沟通。', 'en': 'Once your project receives an application from the property party, we can easily find the most suitable candidate. Through our direct access to establish contact with the property side, and use the \'message\'function to conduct in -depth communication with them.', 'jp': 'あなたのプロジェクトが権利元の応募を受けたら、最適な候補者を簡単に見つけることができます。ダイレクトアクセスで権利元とつながり、\'メッセージ \'機能で深くコミュニケーションをとることができます。' },
  'p124_t680': { 'zh-cn': '当您在浏览资源时，发现了心仪的候选人或项目，您可以主动向他们发出邀请，直接表达您的兴趣和意向。', 'en': 'When you browse resources, you find your favorite candidate or project, you can take the initiative to invite them to express your interests and intentions directly.', 'jp': '興味のある協賛案件をリストアップした権利元メンバーに、直接コンタクトを取り、興味や意思を伝え、スポンサーシップの商談に入ることができます' },
  'p124_t690': { 'zh-cn': '签约合作', 'en': 'Signing cooperation', 'jp': 'スポンサーシップ契約' },
  'p124_t700': { 'zh-cn': '在力赞平台签约赞助合作，享受最优佣金费率，且无任何额外隐藏费用。', 'en': 'Signing on the platform for sponsorship and cooperation on the platform to enjoy the optimal commission rate, and there is no additional hidden costs.', 'jp': 'SponsorForceのサポートのもと、プラットフォームで繋がった広告主との契約に進めましょう' },
  'p124_t710': { 'zh-cn': '签约合作', 'en': 'Signing cooperation', 'jp': 'スポンサーシップ契約' },
  'p124_t720': { 'zh-cn': '我们建议您通过力赞与资源方签约。为此，将协助您完成候选人或项目的审核，确保您的赞助安全，可靠。', 'en': 'We recommend that you sign a contract with the property party through SponsorForce. To this end, it will help you complete the review of candidates or projects to ensure your sponsorship safety and reliable.', 'jp': 'SponsorForceのサポートのもと、プラットフォームで繋がった権利元との契約に進めましょう' },
  'p124_t730': { 'zh-cn': '追踪与改进', 'en': 'Tracking and improvement', 'jp': 'トラッキングと改善' },
  'p124_t740': { 'zh-cn': '我们将跟踪您在平台上的活动，包括申请数量、回复率和品牌反馈。 我们使用此排名向品牌提出赞助建议。 因此，请继续与我们的平台保持联系，并让与您合作的品牌保持愉快的心情。', 'en': 'We will track your activities on the platform, including the number of applications, response rates and brand feedback. We use this ranking to make suggestions for the brand. Therefore, please keep in touch with our platform and keep the brand that cooperates with you.', 'jp': '当社は、より有効な協賛案件の掲載、またより多くの関心を引き寄せるためのコンテンツシェアリングの方法について、トラッキングした上で改善策をメンバーにフィードバックする予定です' },
  'p124_t750': { 'zh-cn': '衡量成功', 'en': 'Successfully measure', 'jp': '成功の測定' },
  'p124_t760': { 'zh-cn': '我们希望您获得最佳的投资回报率，将体育赞助作为一项长期战略，而不仅仅是一次性的活动。升级成为我们的企业会员，使用力赞数据服务，轻松查看每个可交付成果的指标。', 'en': 'We hope that you will get the best return on investment and use sports sponsorship as a long -term strategy, not just a one -time event. Upgrade becomes our corporate members, use SponsorForce data services, and easily check the indicators of each delivery result.', 'jp': 'スポンサーシップのROIを測定し、より科学的なスポンサーシップを目指しましょう。SponsorForceのデータパートナーからの提案を聞きましょう' },
  'p125_t100': { 'zh-cn': '标题', 'en': 'Title', 'jp': 'タイトル' },
  'p125_t110': { 'zh-cn': '查询', 'en': 'Search', 'jp': 'トピックを検索' },
  'p125_t120': { 'zh-cn': '发布话题', 'en': 'Post a Topic', 'jp': 'トピックを投稿する' },
  'p125_t130': { 'zh-cn': '标题', 'en': 'Title', 'jp': 'タイトル' },
  'p125_t140': { 'zh-cn': '标签', 'en': 'Hashtag #', 'jp': 'タグ' },
  'p125_t141': { 'zh-cn': '内容', 'en': 'content #', 'jp': '内容' },
  'p125_t150': { 'zh-cn': '状态', 'en': 'Status', 'jp': 'ステータス' },
  'p125_t160': { 'zh-cn': '草稿', 'en': 'Drafts', 'jp': '下書き' },
  'p125_t170': { 'zh-cn': '审核通过', 'en': 'Approved', 'jp': 'レビューパス' },
  'p125_t180': { 'zh-cn': '审核拒绝', 'en': 'Rejected', 'jp': 'レビュー拒否' },
  'p125_t190': { 'zh-cn': '已提交', 'en': 'Submitted', 'jp': '投稿完了' },
  'p125_t200': { 'zh-cn': '浏览', 'en': 'View', 'jp': '閲覧' },
  'p125_t210': { 'zh-cn': '点赞', 'en': 'Like', 'jp': 'いいね' },
  'p125_t220': { 'zh-cn': '评论', 'en': 'Comment', 'jp': 'コメント' },
  'p125_t230': { 'zh-cn': '创建时间', 'en': 'Creation Time', 'jp': '作成日時' },
  'p125_t240': { 'zh-cn': '操作', 'en': 'Operate', 'jp': '管理' },
  'p125_t250': { 'zh-cn': '提交审核', 'en': 'Submit', 'jp': 'レビューの送信' },
  'p125_t260': { 'zh-cn': '查看详情', 'en': 'Detail', 'jp': '詳細を表示' },
  'p125_t270': { 'zh-cn': '修改', 'en': 'Modify', 'jp': '修正' },
  'p125_t280': { 'zh-cn': '确定删除该话题？', 'en': 'Are you sure you want to delete this topic', 'jp': 'この投稿を削除しますか？' },
  'p125_t290': { 'zh-cn': '删除', 'en': 'Delete', 'jp': '削除' },
  'p125_t300': { 'zh-cn': '提交成功', 'en': 'Submitted Successfully', 'jp': '投稿完了' },
  'p125_t310': { 'zh-cn': '删除成功', 'en': 'Removed Successfully', 'jp': '删除成功' },
  'p126_t100': { 'zh-cn': '标题', 'en': 'Title', 'jp': 'タイトル' },
  'p126_t110': { 'zh-cn': '标签', 'en': 'Hashtag #', 'jp': 'タグ' },
  'p126_t120': { 'zh-cn': '多个标签用逗号(,)分割', 'en': 'Multiple labels separated by commas (,)', 'jp': '「,」で複数タグを追加' },
  'p126_t130': { 'zh-cn': '图片(最多5张)', 'en': 'Cover (up to 5 images)', 'jp': '画像（最大5枚）' },
  'p126_t131': { 'zh-cn': '图片', 'en': 'Images', 'jp': '画像' },
  'p126_t132': { 'zh-cn': '网页链接', 'en': 'Web URL', 'jp': 'Web URL' },
  'p126_t133': { 'zh-cn': '网址解析失败', 'en': 'Url analysis failure', 'jp': 'url解析に失敗しました' },
  'p126_t134': { 'zh-cn': '输入网页链接', 'en': 'Enter web link', 'jp': 'Web リンクを入力してください' },
  'p126_t135': { 'zh-cn': '自动抓取网页链接的标题和头图', 'en': 'Automatically capture the title and header image of web links', 'jp': 'Web リンクのタイトルとヘッダー画像を自動的にキャプチャします。' },
  'p126_t136': { 'zh-cn': '添加', 'en': 'Add', 'jp': 'に追加' },
  'p126_t137': { 'zh-cn': '请输入网页链接', 'en': 'Please enter web link', 'jp': 'ウェブリンクを入力してください' },
  'p126_t138': { 'zh-cn': '请输入正确的网页链接', 'en': 'Please enter the correct web link', 'jp': '正しい Web リンクを入力してください' },

  'p126_t140': { 'zh-cn': '视频链接', 'en': 'Video link', 'jp': '動画リンク' },
  'p126_t150': { 'zh-cn': '内容', 'en': 'Content', 'jp': '内容' },
  'p126_t160': { 'zh-cn': '发布', 'en': 'PUBLISH', 'jp': '投稿' },
  'p126_t170': { 'zh-cn': '暂存', 'en': 'Save', 'jp': '下書きに保存' },
  'p126_t180': { 'zh-cn': '取消', 'en': 'Cancel', 'jp': 'キャンセル' },
  'p126_t190': { 'zh-cn': '请输入标题', 'en': 'Please enter a title', 'jp': 'タイトルを入力してください' },
  'p126_t200': { 'zh-cn': '请输入标签', 'en': 'Please enter hashtag', 'jp': 'タグを追加してください' },
  'p126_t210': { 'zh-cn': '请上传封面', 'en': 'Please upload the cover', 'jp': '画像をアップロードしてください' },
  'p126_t220': { 'zh-cn': '请输入内容', 'en': 'Please enter the content', 'jp': '内容を入力してください' },
  'p126_t240': { 'zh-cn': '最多5个标签', 'en': 'Up to 5 hashtags', 'jp': 'タグは最大5件まで追加可能' },
  'p126_t250': { 'zh-cn': '单个标签最长20个字符', 'en': 'The length of a hashtag is limited to 20 characters', 'jp': '各タグは最長20文字まで入力可能' },
  'p126_t270': { 'zh-cn': '上传大小不能超过5Mb', 'en': '5MB max file size', 'jp': 'サイズ制限: 5MBまで' },
  'p126_t280': { 'zh-cn': '上传图片只能是 JPG/PNG/GIF等常规图片格式', 'en': 'You can only upload images in JPG/PNG/GIF and other common image formats', 'jp': '画像形式: JPG、PNG、GIF' },
  'p126_t290': { 'zh-cn': '只能上传 5 张图片', 'en': 'You can upload up to 5 images at most', 'jp': '最大アップロード数: 5枚' },
  'p126_t300': { 'zh-cn': '发布成功', 'en': 'Published Successfully', 'jp': '投稿完了' },
  'p126_t310': { 'zh-cn': '暂存成功', 'en': 'Saved Successfully', 'jp': '下書き保存完了' },
  'p126_t320': { 'zh-cn': '您上传的头像将在首页被展示', 'en': 'Saved Successfully', 'jp': '下書き保存完了' },

  'p128_t100': { 'zh-cn': '暂无数据', 'en': 'Not Available', 'jp': 'データなし' },

  'p128_t101': { 'zh-cn': '收到的消息', 'en': 'Received Message', 'jp': '受信したメッセージ' },
  'p128_t102': { 'zh-cn': '发送的消息', 'en': 'Sent Message', 'jp': '送信されたメッセージ' },

  'p128_t110': { 'zh-cn': '内容', 'en': 'Content', 'jp': '内容' },
  'p128_t120': { 'zh-cn': '已读', 'en': 'Read', 'jp': '読み済み' },
  'p128_t130': { 'zh-cn': '未读', 'en': 'Unread', 'jp': '未読' },
  'p128_t140': { 'zh-cn': '已读', 'en': 'Read', 'jp': '読み済み' },
  'p128_t150': { 'zh-cn': '发送时间', 'en': 'Sending time', 'jp': '送信時間' },
  'p128_t160': { 'zh-cn': '操作', 'en': 'Operation', 'jp': '管理' },
  'p128_t170': { 'zh-cn': '设为已读', 'en': 'Set as read', 'jp': '既読にする' },
  'p128_t180': { 'zh-cn': '删除', 'en': 'Delete', 'jp': '削除' },
  'p128_t370': { 'zh-cn': '点击留言', 'en': 'Click to leave a message', 'jp': 'メッセージをクリック' },

  'p130_t100': { 'zh-cn': '暂无数据', 'en': 'Not Available', 'jp': 'データなし' },
  'p130_t110': { 'zh-cn': '接收人', 'en': 'Recipient', 'jp': '受取人' },
  'p130_t120': { 'zh-cn': '内容', 'en': 'Content', 'jp': '内容' },
  'p130_t130': { 'zh-cn': '发送时间', 'en': 'Sending time', 'jp': '送信時間' },
  'p131_t100': { 'zh-cn': '暂无数据', 'en': 'Not Available', 'jp': 'データなし' },
  'p131_t110': { 'zh-cn': '发送者', 'en': 'Sender ', 'jp': '送信者' },
  'p131_t120': { 'zh-cn': '内容', 'en': 'Content', 'jp': '内容' },
  'p131_t130': { 'zh-cn': '发送时间', 'en': 'Sending time', 'jp': '送信時間' },
  'p131_t131': { 'zh-cn': '回复', 'en': 'Reply', 'jp': '返信する' },
  'p131_t132': { 'zh-cn': '发送成功', 'en': 'Sent successfully', 'jp': '送信に成功しました' },

  'p131_t133': { 'zh-cn': '请先去签约', 'en': 'Sent successfully', 'jp': '送信に成功しました' },
  'p131_t134': { 'zh-cn': '请选择行业类型', 'en': 'Please select the industry type', 'jp': '業種を選択してください' },
  'p131_t135': { 'zh-cn': '请选择资源', 'en': 'Please select the resorce', 'jp': '案件を選択してください' },
  'p131_t136': { 'zh-cn': '请输入您的信息', 'en': 'Please enter the your content', 'jp': '内容を入力してください' },



  'p133_t100': { 'zh-cn': '收藏的资源', 'en': 'Bookmarked Properties', 'jp': 'ブックマークされた「協賛案件」' },
  'p133_t110': { 'zh-cn': '收藏的需求', 'en': 'Bookmarked Requests', 'jp': 'ブックマークされた協賛リクエスト' },
  'p134_t100': { 'zh-cn': '名称', 'en': 'Title', 'jp': '案件名' },
  'p134_t110': { 'zh-cn': '查看详情', 'en': 'View Details', 'jp': '詳細の表示' },
  'p134_t120': { 'zh-cn': '取消收藏', 'en': 'Cancel Favorite', 'jp': 'お気に入り取消し' },
  'p134_t130': { 'zh-cn': '资源类型', 'en': 'Property Type', 'jp': '案件タイプ' },
  'p134_t140': { 'zh-cn': '体育类型', 'en': 'Sports Type', 'jp': 'スポーツの種類' },
  'p134_t150': { 'zh-cn': '简介', 'en': 'Brief Introduction', 'jp': '概要' },
  'p134_t160': { 'zh-cn': '赞助商', 'en': 'Sponsors', 'jp': 'スポンサー' },
  'p134_t170': { 'zh-cn': '成就及获奖记录', 'en': 'Award Records', 'jp': '受賞歴' },
  'p134_t180': { 'zh-cn': '不支持行业', 'en': 'Unacceptable Categories', 'jp': '対象外の業界' },
  'p134_t190': { 'zh-cn': '收藏时间', 'en': 'Bookmarked Time', 'jp': '予定期間' },
  'p134_t200': { 'zh-cn': '暂无数据', 'en': 'Not Available', 'jp': 'データなし' },
  'p134_t210': { 'zh-cn': '取消收藏成功', 'en': 'Cancel Collection Successfully', 'jp': 'お気に入り取消しに成功しました' },
  'p135_t100': { 'zh-cn': '需求名称', 'en': 'Request Name', 'jp': '協賛リクエスト名' },
  'p135_t110': { 'zh-cn': '查看详情', 'en': 'View Details', 'jp': '詳細の表示' },
  'p135_t120': { 'zh-cn': '取消收藏', 'en': 'Cancel Favorite', 'jp': 'お気に入り取消し' },
  'p135_t130': { 'zh-cn': '内容', 'en': 'Content', 'jp': '内容' },
  'p135_t140': { 'zh-cn': '预算', 'en': 'Budget', 'jp': '予算' },
  'p135_t150': { 'zh-cn': '类型', 'en': 'Type', 'jp': '種類' },
  'p135_t160': { 'zh-cn': '体育类型', 'en': 'Sports Type', 'jp': 'スポーツの種類' },
  'p135_t170': { 'zh-cn': '收藏时间', 'en': 'Bookmarked Time', 'jp': '予定期間' },
  'p135_t180': { 'zh-cn': '暂无数据', 'en': 'Not Available', 'jp': 'データなし' },
  'p135_t190': { 'zh-cn': '取消收藏成功', 'en': 'Cancel Collection Successfully', 'jp': 'お気に入り取消しに成功しました' },
  'p136_t100': { 'zh-cn': '我的看板', 'en': 'My Dashboard', 'jp': 'マイペイジ' },
  'p137_t100': { 'zh-cn': '返回首页', 'en': 'Return To Homepage', 'jp': 'トップページに戻る' },
  'p137_t110': { 'zh-cn': '退出登录', 'en': 'Log Out', 'jp': 'ログアウト' },
  'p137_t130': { 'zh-cn': '未读取到用户信息，请重新登录', 'en': 'User information not read, please log in again', 'jp': 'ユーザー情報が読み込まれていません。再ログインしてください' },

  'p140_t100': { 'zh-cn': '注册', 'en': 'SIGN UP', 'jp': '今すぐ登録' },
  'p140_t101': { 'zh-cn': '选择币种', 'en': 'Select your currency', 'jp': 'ご希望の通貨を選択してください' },
  'p140_t110': { 'zh-cn': '登录', 'en': 'LOGIN', 'jp': 'サインイン' },
  'p140_t120': { 'zh-cn': '个人中心', 'en': 'Personal Centre', 'jp': 'マイページ' },
  'p140_t130': { 'zh-cn': '发布资源', 'en': 'List your properties', 'jp': '協賛案件を掲載' },
  'p140_t140': { 'zh-cn': '发布需求', 'en': 'Publish Your Sponsorship Requests', 'jp': '協賛リクエストを発表' },
  'p140_t150': { 'zh-cn': '修改密码', 'en': 'Change Password', 'jp': 'パスワードの変更' },
  'p140_t160': { 'zh-cn': '退出登录', 'en': 'Log Out', 'jp': 'ログアウト' },
  'p140_t161': { 'zh-cn': '跳转至日本站', 'en': 'To Japan', 'jp': '日本のサイトに飛びます' },
  'p140_t162': { 'zh-cn': '2021年度全球体育社交媒体洞察报告', 'en': '2021 Global Sports Social Media Insights Report', 'jp': '' },
  'p140_t170': { 'zh-cn': '关于我们', 'en': 'About Us', 'jp': '会社概要' },
  'p140_t180': { 'zh-cn': '浏览资源', 'en': 'Browse Property', 'jp': '協賛案件一覧' },
  'p140_t190': { 'zh-cn': '赞助商的需求', 'en': 'Request From Sponsor', 'jp': 'スポンサーからのリクエスト' },
  'p140_t200': { 'zh-cn': '话题', 'en': 'Community', 'jp': 'コミュニティ' },
  'p140_t210': { 'zh-cn': '活动', 'en': 'Event', 'jp': 'イベント情報' },
  'p140_t220': { 'zh-cn': '全球赛事日程', 'en': 'Event Calendar', 'jp': 'せかいだいかいにちてん' },
  'p141_t100': { 'zh-cn': '快速链接', 'en': 'Quick Links', 'jp': 'クイックリンク' },
  'p141_t110': { 'zh-cn': '网站信息', 'en': 'Website Information', 'jp': '出会い系サイト' },
  'p141_t120': { 'zh-cn': '联系我们', 'en': 'Contact Us', 'jp': '連絡先' },
  'p141_t130': { 'zh-cn': '品牌方使用帮助', 'en': 'Brand side usage assistance', 'jp': 'ブランド側使用ヘルプ' },
  'p141_t140': { 'zh-cn': '资源方使用帮助', 'en': 'Property side usage assistance', 'jp': '案件側使用ヘルプ' },
  'p141_t150': { 'zh-cn': '什么是体育营销', 'en': 'What is sports marketing', 'jp': 'スポーツマーケティングとは' },
  'p141_t160': { 'zh-cn': '体育营销常用术语', 'en': 'Common Terms in Sports Marketing', 'jp': 'スポーツマーケティングの一般用語' },
  'p141_t170': { 'zh-cn': '体育赞助权益', 'en': 'Sports sponsorship rights', 'jp': 'スポーツスポンサーシップ' },
  'p141_t180': { 'zh-cn': '版权', 'en': 'Copyright', 'jp': '著作権' },
  'p141_t190': { 'zh-cn': '使用条款', 'en': 'Terms of Use', 'jp': '使用条件' },
  'p141_t200': { 'zh-cn': '法律披露', 'en': 'Legal Disclosure', 'jp': '法的開示' },
  'p141_t210': { 'zh-cn': '邮箱', 'en': 'Contact email address', 'jp': 'メールアドレス' },
  'p141_t220': { 'zh-cn': '微信公众号', 'en': 'WeChat official account', 'jp': 'ウィーチャット公式アカウント' },
  'p141_t230': { 'zh-cn': '力赞体育', 'en': 'SponsorForce Sports', 'jp': 'スポーツをほめる' },
  'p141_t240': { 'zh-cn': '版权所有', 'en': 'All Rights Reserved', 'jp': '著作権所有' },
  'p142_t100': { 'zh-cn': '名称', 'en': 'Title', 'jp': '案件名' },
  'p142_t110': { 'zh-cn': '查看详情', 'en': 'View Details', 'jp': '詳細の表示' },
  'p142_t120': { 'zh-cn': '资源类型', 'en': 'Property Type', 'jp': '案件タイプ' },
  'p142_t130': { 'zh-cn': '体育类型', 'en': 'Sports Type', 'jp': 'スポーツの種類' },
  'p142_t140': { 'zh-cn': '简介', 'en': 'Brief Introduction', 'jp': '概要' },
  'p142_t150': { 'zh-cn': '赞助商', 'en': 'Sponsors', 'jp': 'スポンサー' },
  'p142_t160': { 'zh-cn': '成就及获奖记录', 'en': 'Award records', 'jp': '受賞歴' },
  'p142_t170': { 'zh-cn': '不支持行业', 'en': 'Unacceptable Categories', 'jp': '対象外の業界' },
  'p142_t180': { 'zh-cn': '收藏时间', 'en': 'Bookmarked Time', 'jp': '予定期間' },
  'p142_t190': { 'zh-cn': '暂无数据', 'en': 'Not Available', 'jp': 'データなし' },
  'p143_t100': { 'zh-cn': '需求名称', 'en': 'Request Name', 'jp': '協賛リクエスト名' },
  'p143_t110': { 'zh-cn': '查看详情', 'en': 'View Details', 'jp': '詳細の表示' },
  'p143_t120': { 'zh-cn': '内容', 'en': 'Content', 'jp': '内容' },
  'p143_t130': { 'zh-cn': '预算', 'en': 'Budget', 'jp': '予算' },
  'p143_t140': { 'zh-cn': '类型', 'en': 'Type', 'jp': '種類' },
  'p143_t150': { 'zh-cn': '体育类型', 'en': 'Sports Type', 'jp': 'スポーツの種類' },
  'p143_t160': { 'zh-cn': '状态', 'en': 'State', 'jp': 'ステータス' },
  'p143_t170': { 'zh-cn': '已经确认', 'en': 'Confirmed', 'jp': '確認済み' },
  'p143_t180': { 'zh-cn': '未确认', 'en': 'Unconfirmed', 'jp': '未確認' },
  'p143_t190': { 'zh-cn': '收藏时间', 'en': 'Bookmarked Time', 'jp': '予定期間' },
  'p143_t200': { 'zh-cn': '暂无数据', 'en': 'Not Available', 'jp': 'データなし' },
  'p144_t140': { 'zh-cn': '新增需求', 'en': 'New request', 'jp': '新規リクエスト' },
  'p144_t150': { 'zh-cn': '刷新', 'en': 'Refresh', 'jp': 'リフレッシュ' },
  'p144_t160': { 'zh-cn': '需求名称', 'en': 'Request Name', 'jp': '協賛リクエスト名' },
  'p144_t180': { 'zh-cn': '需求状态', 'en': 'Request status', 'jp': '需要ステータス' },
  'p144_t190': { 'zh-cn': '待审核', 'en': 'Pending review', 'jp': 'レビュー保留中' },
  'p144_t200': { 'zh-cn': '审核通过', 'en': 'Approved', 'jp': 'レビューパス' },
  'p144_t210': { 'zh-cn': '审核拒绝', 'en': 'Review Reject', 'jp': 'レビュー拒否' },
  'p144_t220': { 'zh-cn': '废弃', 'en': 'Abandoned', 'jp': '廃棄' },
  'p144_t230': { 'zh-cn': '结束时间', 'en': 'End Date', 'jp': '終了日' },
  'p144_t240': { 'zh-cn': '创建时间', 'en': 'Creation time', 'jp': '作成日時' },
  'p144_t250': { 'zh-cn': '操作', 'en': 'Operation', 'jp': '管理' },
  'p144_t260': { 'zh-cn': '修改', 'en': 'Modify', 'jp': '修正' },
  'p144_t270': { 'zh-cn': '确定删除该需求', 'en': 'Confirm to delete the request', 'jp': '需要の削除の決定' },
  'p144_t280': { 'zh-cn': '删除', 'en': 'Delete', 'jp': '削除' },
  'p144_t290': { 'zh-cn': '删除成功', 'en': 'Delete Successfully', 'jp': '删除成功' },
  'p145_t100': { 'zh-cn': '需求信息', 'en': 'Request Information', 'jp': '協賛リクエストの概要' },
  'p145_t110': { 'zh-cn': '名称', 'en': 'Title', 'jp': 'リクエスト表示' },
  'p145_t120': { 'zh-cn': '需求类型', 'en': 'Request Type', 'jp': '種類' },
  'p145_t130': { 'zh-cn': '请选择需求类型', 'en': 'Please select the request type', 'jp': '協賛対象の案件タイプを選択してください' },
  'p145_t140': { 'zh-cn': '品牌类别', 'en': 'Brand Category', 'jp': '業種' },
  'p145_t150': { 'zh-cn': '品牌类别', 'en': 'Brand Category', 'jp': '企業の業種を選択してください' },
  'p145_t160': { 'zh-cn': '项目预算', 'en': 'Project Budget', 'jp': 'プロジェクト予算' },
  'p145_t170': { 'zh-cn': '偏好体育项目', 'en': 'Preferred Sport(s)', 'jp': '希望種目' },
  'p145_t180': { 'zh-cn': '请选择偏好体育项目', 'en': 'Sports Type', 'jp': '希望の種目を選択してください' },
  'p145_t190': { 'zh-cn': '申请截止日期', 'en': 'Application Deadline', 'jp': '応募締切' },
  'p145_t200': { 'zh-cn': '截止日期', 'en': 'Deadline', 'jp': '締切日' },
  'p145_t210': { 'zh-cn': '赞助周期', 'en': 'Sponsor Period', 'jp': '希望の協賛期間' },
  'p145_t220': { 'zh-cn': '起始时间', 'en': 'Sponsor Period', 'jp': '開始日' },
  'p145_t230': { 'zh-cn': '结束时间', 'en': 'End Date', 'jp': '終了日' },
  'p145_t240': { 'zh-cn': '国家地区', 'en': 'Country/Region', 'jp': '希望の協賛地域/国' },
  'p145_t250': { 'zh-cn': '请选择国家地区', 'en': 'Please select a country or region', 'jp': '希望の協賛地域/国を選択してください' },
  'p145_t260': { 'zh-cn': '请选择规格', 'en': 'Specifications', 'jp': '仕様を選択' },
  'p145_t270': { 'zh-cn': '请选择规格', 'en': 'Specifications', 'jp': '仕様を選択' },
  'p145_t280': { 'zh-cn': '次', 'en': 'Second', 'jp': '次' },
  'p145_t290': { 'zh-cn': '次', 'en': 'Second', 'jp': '次' },
  'p145_t300': { 'zh-cn': '月', 'en': 'Month', 'jp': '月' },
  'p145_t310': { 'zh-cn': '月', 'en': 'Month', 'jp': '月' },
  'p145_t320': { 'zh-cn': '年', 'en': 'Year', 'jp': '年' },
  'p145_t330': { 'zh-cn': '年', 'en': 'Year', 'jp': '年' },
  'p145_t340': { 'zh-cn': '需求描述', 'en': 'Description of Request', 'jp': '協賛リクエストの詳細' },
  'p145_t350': { 'zh-cn': '请输入需求描述', 'en': 'Please enter description', 'jp': '詳細記述を入力してください' },
  'p145_t360': { 'zh-cn': '社交媒体最低粉丝数', 'en': 'Minimum Number of Followers', 'jp': '希望最低フォロワー数' },
  'p145_t370': { 'zh-cn': 'Facebook粉丝数', 'en': 'Facebook Followers', 'jp': 'Facebookフォロワー数' },
  'p145_t380': { 'zh-cn': 'Instagram粉丝数', 'en': 'Instagram fan count', 'jp': 'Instagramフォロワー数' },
  'p145_t390': { 'zh-cn': 'Twitter粉丝数', 'en': 'Number of Twitter followers', 'jp': 'Twitterフォロワー数' },
  'p145_t400': { 'zh-cn': 'Tiktok粉丝数', 'en': 'TikTok Followers', 'jp': 'Tiktokフォロワー数' },
  'p145_t410': { 'zh-cn': '微博粉丝数', 'en': 'Webio Followers', 'jp': '微博フォロワー数' },
  'p145_t420': { 'zh-cn': '抖音粉丝数', 'en': 'Douyin Followers', 'jp': '抖音フォロワー数' },
  'p145_t430': { 'zh-cn': '小红书粉丝数', 'en': 'Xiaohongshu Followers', 'jp': '小红书フォロワー' },
  'p145_t440': { 'zh-cn': '新增', 'en': 'Add', 'jp': '新規リクエストを作成' },
  'p145_t450': { 'zh-cn': '修改', 'en': 'Edit', 'jp': '更新' },
  'p145_t460': { 'zh-cn': '预算', 'en': 'Budget', 'jp': '予算' },
  'p145_t470': { 'zh-cn': '寻求赞助合作', 'en': 'brand, seeking sponsorship opportunities', 'jp': '協賛先を探す' },
  'p145_t490': { 'zh-cn': '请输入项目预算', 'en': 'Please enter the project budget', 'jp': '予算を入力してください' },
  'p145_t500': { 'zh-cn': '请选择申请截止日期', 'en': 'Please select the docking deadline', 'jp': '応募締切期日を選択' },
  'p145_t510': { 'zh-cn': '请选择需求类型', 'en': 'Please select the request type', 'jp': 'タイプを選択' },
  'p145_t520': { 'zh-cn': '请选择品牌类别', 'en': 'Please select a brand category', 'jp': '企業の業種を選択してください' },
  'p145_t530': { 'zh-cn': '请选择偏好体育项目', 'en': 'Sports Type', 'jp': 'スポーツを選択' },
  'p145_t540': { 'zh-cn': '请选择起始日期', 'en': 'Please select the sponsor period', 'jp': '開始日を選択' },

  'p145_t541': { 'zh-cn': '项目预算范围异常', 'en': 'Budget range error', 'jp': '予算範囲が異常です' },
  'p145_t542': { 'zh-cn': '项目预算不得为0', 'en': 'Budget cannot be zero', 'jp': '予算はゼロではいけません' },


  'p145_t550': { 'zh-cn': '需求新增成功', 'en': 'Add Request Successfully', 'jp': '新規の協賛リクエストが作成されました' },
  'p145_t560': { 'zh-cn': '需求修改成功', 'en': 'Modfiy Request Successfully', 'jp': '内容を修正' },
  'p145_t570': { 'zh-cn': '修改已审核需求会触发重新审核，是否继续？', 'en': 'Modifying approved requests will trigger a re audit. Do you want to continue?', 'jp': 'NODATA' },
  'p145_t580': { 'zh-cn': '确认', 'en': 'Confirmation', 'jp': '確認' },
  'p145_t590': { 'zh-cn': '确认', 'en': 'Confirm', 'jp': '確認' },
  'p145_t600': { 'zh-cn': '取消', 'en': 'Cancel', 'jp': 'キャンセル' },
  'p145_t610': { 'zh-cn': '需求修改成功', 'en': 'Request Modification Successfully', 'jp': '内容を修正' },

  'p152_t100': { 'zh-cn': '门户', 'en': 'Portal', 'jp': 'ポータル' },
  'p152_t120': { 'zh-cn': '首页', 'en': 'Home page', 'jp': 'ホームページ' },
  'p152_t140': { 'zh-cn': '浏览资源', 'en': 'Browse Property', 'jp': '協賛案件一覧' },
  'p152_t160': { 'zh-cn': '资源详情', 'en': 'Property Details', 'jp': '案件情報' },
  'p152_t161': { 'zh-cn': '洞察', 'en': 'Insight', 'jp': '洞察' },
  'p152_t180': { 'zh-cn': '关于我们', 'en': 'About Us', 'jp': '会社概要' },
  'p152_t200': { 'zh-cn': '品牌需求', 'en': 'Sponsorship Request', 'jp': '広告主ニーズ' },
  'p152_t220': { 'zh-cn': '品牌需求详情', 'en': 'Sponsorship request details', 'jp': '協賛リクエスト詳細' },
  'p152_t240': { 'zh-cn': '话题', 'en': 'Community', 'jp': 'コミュニティ' },
  'p152_t260': { 'zh-cn': '话题详情', 'en': 'Topic Details', 'jp': 'トピックの詳細' },
  'p152_t280': { 'zh-cn': '活动', 'en': 'Event', 'jp': 'イベント情報' },
  'p152_t300': { 'zh-cn': '个人信息', 'en': 'Personal Information', 'jp': 'ユーザー情報' },
  'p152_t310': { 'zh-cn': '个人信息', 'en': 'Personal Information', 'jp': 'ユーザー情報' },
  'p152_t330': { 'zh-cn': '修改密码', 'en': 'Change password', 'jp': 'パスワードの変更' },
  'p152_t340': { 'zh-cn': '修改密码', 'en': 'Change password', 'jp': 'パスワードの変更' },
  'p152_t360': { 'zh-cn': '我的资源', 'en': 'My Properties', 'jp': '「協賛案件」の管理' },
  'p152_t370': { 'zh-cn': '我的资源', 'en': 'My Properties', 'jp': '「協賛案件」の管理' },
  'p152_t390': { 'zh-cn': '资源详情', 'en': 'Property Details', 'jp': '案件情報' },
  'p152_t410': { 'zh-cn': '活动包列表', 'en': 'List of activity packages', 'jp': 'スポンサーシップレベルのリスト' },
  'p152_t430': { 'zh-cn': '活动包详情', 'en': 'Event package details', 'jp': 'スポンサーシップレベル内訳' },
  'p152_t440': { 'zh-cn': '资源详情', 'en': 'Property Details', 'jp': '案件情報' },
  'p152_t460': { 'zh-cn': '我的意向', 'en': 'My Intentions', 'jp': '「協賛リクエスト」応募歴' },
  'p152_t470': { 'zh-cn': '我的意向', 'en': 'My Intentions', 'jp': '「協賛リクエスト」応募歴' },
  'p152_t490': { 'zh-cn': '我的需求', 'en': 'My Request', 'jp': '「協賛リクエスト」の管理' },
  'p152_t500': { 'zh-cn': '我的需求', 'en': 'My Request', 'jp': '「協賛リクエスト」の管理' },
  'p152_t520': { 'zh-cn': '需求详情', 'en': 'Purchase Details', 'jp': 'リクエスト詳細' },
  'p152_t530': { 'zh-cn': '需求详情', 'en': 'Purchase Details', 'jp': 'リクエスト詳細' },
  'p152_t550': { 'zh-cn': '我的意向', 'en': 'My Intentions', 'jp': '協賛案件とのコミュニケーション' },
  'p152_t560': { 'zh-cn': '我的意向', 'en': 'My Intentions', 'jp': '協賛案件とのコミュニケーション' },
  'p152_t580': { 'zh-cn': '我的收藏', 'en': 'My Favourites', 'jp': 'お気に入りリスト' },
  'p152_t590': { 'zh-cn': '我的收藏', 'en': 'My Favourites', 'jp': 'お気に入りリスト' },
  'p152_t610': { 'zh-cn': '我的消息', 'en': 'My Messages', 'jp': 'マイメッセージ' },
  'p152_t620': { 'zh-cn': '我的消息', 'en': 'My Messages', 'jp': 'マイメッセージ' },
  'p152_t640': { 'zh-cn': '我的通知', 'en': 'My Notifications', 'jp': 'SponsorForceからの連絡' },
  'p152_t650': { 'zh-cn': '我的通知', 'en': 'My Notifications', 'jp': 'SponsorForceからの連絡' },
  'p152_t670': { 'zh-cn': '佣金协议', 'en': 'Commission Agreement', 'jp': 'コミッション契約' },
  'p152_t680': { 'zh-cn': '佣金协议', 'en': 'Commission Agreement', 'jp': 'コミッション契約' },
  'p152_t700': { 'zh-cn': '我的话题', 'en': 'My Topics', 'jp': 'コミュニティ投稿歴' },
  'p152_t710': { 'zh-cn': '我的话题', 'en': 'My Topics', 'jp': 'コミュニティ投稿歴' },
  'p152_t730': { 'zh-cn': '话题新建/编辑', 'en': 'Topic Creation/Editing', 'jp': 'トピックの新規作成/編集' },
  'p152_t740': { 'zh-cn': '我的话题', 'en': 'My Topics', 'jp': 'コミュニティ投稿歴' },
  // 'p153_t110': { 'zh-cn': '运动员', 'en': 'Athlete', 'jp': 'スポーツせんしゅ' },
  'p153_t110': { 'zh-cn': '个人', 'en': 'Individual', 'jp': '個人' },
  'p153_t120': { 'zh-cn': '协会', 'en': 'Association', 'jp': '協会' },
  'p153_t130': { 'zh-cn': '联赛', 'en': 'League', 'jp': 'リーグ' },
  'p153_t140': { 'zh-cn': '赛事', 'en': 'Event And Competition', 'jp': 'イベント・大会' },
  'p153_t150': { 'zh-cn': '俱乐部/队伍', 'en': 'Club/Team', 'jp': 'クラブ/チーム' },
  'p153_t160': { 'zh-cn': '其他', 'en': 'Others', 'jp': 'その他' },
  'p153_t161': { 'zh-cn': '协会/赛事/联赛', 'en': 'Association/League/Event And Competition', 'jp': '協会/リーグ/イベント・大会' },
  'p153_t162': { 'zh-cn': '俱乐部/其他', 'en': 'Club/Others', 'jp': 'クラブ/その他' },
  'p153_t163': { 'zh-cn': '查看更多', 'en': 'Show more', 'jp': 'もっと見る' },

  'p153_t170': { 'zh-cn': '品牌使用个人肖像进行推广的权利', 'en': 'Sponsors are granted use of Athlete\'s personal image and likeness in advertising and promotion.', 'jp': '個人の氏名、画像、音声などを広告や宣伝に使用する権利' },
  'p153_t180': { 'zh-cn': '拍摄品牌的电视或视频广告', 'en': 'Participation of TVC or video shooting', 'jp': 'テレビまたは動画広告の撮影' },
  'p153_t190': { 'zh-cn': '拍摄品牌的平面广告', 'en': 'Participation of photo shooting', 'jp': '掲載広告のための写真撮影' },
  'p153_t200': { 'zh-cn': '参加品牌线下推广活动', 'en': 'Live appearence at events', 'jp': 'オフラインプロモーション活動への参加' },
  'p153_t210': { 'zh-cn': '与运动员在社交媒体及电商等互联网平台交互推广的权利', 'en': 'The right to interact with athlete for joint-promotion through a range of digital marketing channels including Social, Ecommerce, etc.', 'jp': 'アスリートと企業SNS、eコマースなどのオンライン・プロモーション活動への参加' },
  'p153_t220': { 'zh-cn': '其他', 'en': 'Others', 'jp': 'その他' },
  'p153_t230': { 'zh-cn': '品牌使用IP元素（logo，集体肖像权，赛事名称，吉祥物，奖杯等）进行推广的权利', 'en': 'The right to use of copyrighted trademarks on all of their marketing collaterals both online and offline including logo, athletes\'group images, official designation, images of mascots, trophies, etc.', 'jp': 'スポンサー企業が知的財産（ロゴ、集団肖像権、試合名、マスコット、トロフィーなど）を用いて広告や宣伝に使用する権利' },
  'p153_t240': { 'zh-cn': '比赛及活动现场品牌曝光（场地广告，球衣广告，比赛相关设备广告等）的权利', 'en': 'Prominent sponsor\'s name / logo recognition in all live games / events including pitchside boards, team jersey , other forms of equipments and signages, etc.', 'jp': '大会や公式イベントなどにおけるスポンサー企業のロゴや企業メッセージを掲示する権利' },
  'p153_t250': { 'zh-cn': '在比赛现场进行品牌推广线下活动的权利', 'en': 'The right to conduct brand\'s onsite promotional activities during the game / event', 'jp': '試合会場などにおけるオフラインのプロモーション活動を実施する権利' },
  'p153_t260': { 'zh-cn': '与赛事在社交媒体及电商等互联网平台交互推广的权利', 'en': 'The right to interact with event for joint-promotion through a range of digital marketing channels including Social, Ecommerce, etc.', 'jp': '企業SNS、eコマース、その他オンラインプラットフォームにおけるイベントとのタイアップ' },
  'p153_t270': { 'zh-cn': '其他和比赛相关的品牌曝光的机会（新闻发布背景板，训练场地广告板，官方刊物，官网等）', 'en': 'The right to place sponsor\'s brand on event related signages and collaterails incl. press conference backdrop, training court advertising board, publications, website, etc.', 'jp': 'その他、大会や試合に関連したブランディングの機会（記者会見のバックドロップ、練習場の広告看板、公式出版物、公式ウェブサイトなど）' },
  'p153_t280': { 'zh-cn': '贵宾款待及票证的权利', 'en': 'Event hospitality and tickets', 'jp': 'ホスピタリティやチケットの権利' },
  'p153_t290': { 'zh-cn': '在赛事直播平台中优先购买广告的权利', 'en': 'Priority right to buy adverts on the live streaming platform of the event', 'jp': '大会放送メディアプラットフォーム（TV、OTT等）におけるCMスポットの優先交渉権' },
  'p153_t300': { 'zh-cn': '个性化定制赞助权益的权利', 'en': 'Customization of sponsorships\'rights package', 'jp': 'ソール＆エクスクルーシブ（その他権利をカスタマイズする）権利' },
  'p153_t310': { 'zh-cn': '其他', 'en': 'Others', 'jp': 'その他' },
  'p153_t320': { 'zh-cn': '品牌使用IP元素（logo，集体肖像权，俱乐部名称，吉祥物等）进行推广的权利', 'en': 'Sponsors are granted use of copyrighted trademarks on all of their marketing collaterals both online and offline (including team/club official logo, player group images/videos, team/club official name, images of mascots, etc.)', 'jp': 'スポンサー企業が知的財産（ロゴ、集団肖像権、試合名、マスコット、トロフィーなど）を用いて広告や宣伝に使用する権利' },
  'p153_t330': { 'zh-cn': '俱乐部比赛及训练用服装上展现品牌的权利', 'en': 'The right to place sponsor\'s logo on gameday uniforms as well as training gears.', 'jp': '試合用ユニフォームやトレーニングウェアに社名やロゴを掲示する権利' },
  'p153_t340': { 'zh-cn': '比赛现场品牌曝光（场地广告，球衣广告，比赛相关设备广告等）的权利', 'en': 'Prominent sponsor\'s name / logo recognition in all live games including onsite venue advertisment, team jersey advertisement, other forms of equipment advertisement, etc.) ', 'jp': '大会や公式イベントなどにおけるスポンサー企業のロゴや企業メッセージを展示する権利' },
  'p153_t350': { 'zh-cn': '在俱乐部主场比赛现场进行品牌推广线下活动的权利', 'en': 'The right to host offline events at club\'s home game', 'jp': '試合会場などにおけるオフラインのプロモーション活動を実施する権利' },
  'p153_t360': { 'zh-cn': '与俱乐部在社交媒体及电商等互联网平台交互推广的权利', 'en': 'The right to interact with club for joint-promotion through a range of digital marketing channels including Social, Ecommerce, etc.', 'jp': '企業がクラブのSNSやeコマースなどと、オンライン・プロモーションやタイアップする権利' },
  'p153_t370': { 'zh-cn': '邀请俱乐部运动员代表参加品牌线下活动的权利', 'en': 'Sponsors are granted player appearence at offline event', 'jp': '所属アスリートを企業イベントに招聘する権利' },
  'p153_t380': { 'zh-cn': '贵宾款待及票证的权利', 'en': 'Hospitality suite, tickets and accreditations', 'jp': 'ホスピタリティやチケットの権利' },
  'p153_t390': { 'zh-cn': '个性化定制赞助权益的权利', 'en': 'Bespoke...', 'jp': 'ソール＆エクスクルーシブ（その他権利をカスタマイズする）権利' },
  'p153_t400': { 'zh-cn': '其他', 'en': 'Others', 'jp': 'その他' },
  'p153_t410': { 'zh-cn': '品牌使用IP元素（logo，集体肖像权，协会名称，吉祥物等）进行推广的权利', 'en': 'Sponsors are granted use of copyrighted trademarks on all of their marketing collaterals both online and offline (including federation/association. logo, player group images/videos, federation/association official name, images of mascots, etc.)', 'jp': 'スポンサー企業が知的財産（ロゴ、集団肖像権、試合名、マスコット、トロフィーなど）を用いて広告や宣伝に使用する権利' },
  'p153_t420': { 'zh-cn': '协会主管赛事现场品牌曝光（场地广告，球衣广告，比赛相关设备广告等）的权利', 'en': 'Prominent sponsor\'s name / logo recognition in all live games organized by Federation/Associations including onsite venue advertisment, team jersey advertisement, other forms of equipment advertisement, etc.) ', 'jp': '協会主催大会や公式イベントなどにおけるスポンサー企業のロゴや企業メッセージを掲示する権利' },
  'p153_t430': { 'zh-cn': '在协会主管赛事现场进行品牌推广线下活动的权利', 'en': 'The right to conduct brand\'s onsite promotional activities during the game organized by federation/association.', 'jp': '協会主催大会や公式イベントにおけるオフラインのプロモーション活動を実施する権利' },
  'p153_t440': { 'zh-cn': '与协会所管赛事或运动员在社交媒体及电商等互联网平台交互推广的权利', 'en': 'The right to interact with federation/association for joint-promotion through a range of digital marketing channels including Social, Ecommerce, etc.', 'jp': '企業が協会のSNSやeコマースなどと、オンライン・プロモーションやタイアップする権利' },
  'p153_t450': { 'zh-cn': '邀请协会所属运动员代表参加品牌线下活动的权利', 'en': 'The right to invite registered athletes in appearence at sponsor\'s events', 'jp': '所属アスリートを企業イベントに招聘する権利' },
  'p153_t460': { 'zh-cn': '贵宾款待及票证的权利', 'en': 'Hospitality suite, tickets and accreditations', 'jp': 'ホスピタリティやチケットの権利' },
  'p153_t470': { 'zh-cn': '个性化定制赞助权益的权利', 'en': 'Customization of sponsorship rights package', 'jp': 'ソール＆エクスクルーシブ（その他権利をカスタマイズする）権利' },
  'p153_t480': { 'zh-cn': '其他', 'en': 'Others', 'jp': 'その他' },
  // 'p153_t490': { 'zh-cn': '日本', 'en': 'Japan', 'jp': '日本'},
  // 'p153_t500': { 'zh-cn': '中国', 'en': 'China', 'jp': '中国'},
  // 'p153_t510': { 'zh-cn': '中国香港', 'en': 'Hong Kong, China', 'jp': '中国香港'},
  // 'p153_t520': { 'zh-cn': '中国澳门', 'en': 'Macau, China', 'jp': '中国マカオ'},
  // 'p153_t530': { 'zh-cn': '中国台湾', 'en': 'Taiwan, China', 'jp': '台湾'},
  // 'p153_t540': { 'zh-cn': '韩国', 'en': 'South Korea', 'jp': '韓国'},
  // 'p153_t550': { 'zh-cn': '美国', 'en': 'United States', 'jp': 'アメリカ合衆国'},
  // 'p153_t560': { 'zh-cn': '阿富汗', 'en': 'Afghanistan', 'jp': 'アフガニスタン'},
  // 'p153_t570': { 'zh-cn': '阿尔巴尼亚', 'en': 'Albania', 'jp': 'アルバニア'},
  // 'p153_t580': { 'zh-cn': '阿尔及利亚', 'en': 'Algeria', 'jp': 'アルジェリア'},
  // 'p153_t590': { 'zh-cn': '萨摩亚', 'en': 'Samoa', 'jp': 'サモア'},
  // 'p153_t600': { 'zh-cn': '安道尔共和国', 'en': 'Republic of Andorra', 'jp': 'アンドラ共和国'},
  // 'p153_t610': { 'zh-cn': '安哥拉', 'en': 'Angola', 'jp': 'アンゴラ'},
  // 'p153_t620': { 'zh-cn': '安圭拉岛', 'en': 'Anguilla Island', 'jp': 'アンギラ島'},
  // 'p153_t630': { 'zh-cn': '南极洲', 'en': 'Antarctica', 'jp': '南極大陸'},
  // 'p153_t640': { 'zh-cn': '安提瓜和巴布达', 'en': 'Antigua and Barbuda', 'jp': 'アンティグアバーブーダ'},
  // 'p153_t650': { 'zh-cn': '阿根廷', 'en': 'Argentina', 'jp': 'アルゼンチン'},
  // 'p153_t660': { 'zh-cn': '亚美尼亚', 'en': 'Armenia', 'jp': 'アルメニア'},
  // 'p153_t670': { 'zh-cn': '阿鲁巴', 'en': 'Aruba ', 'jp': 'アルバ.'},
  // 'p153_t680': { 'zh-cn': '澳大利亚', 'en': 'Australia', 'jp': 'オーストラリア'},
  // 'p153_t690': { 'zh-cn': '奥地利', 'en': 'Austria', 'jp': 'オーストリア'},
  // 'p153_t700': { 'zh-cn': '阿塞拜疆', 'en': 'Azerbaijan', 'jp': 'アゼルバイジャン'},
  // 'p153_t710': { 'zh-cn': '巴哈马', 'en': 'Bahamas', 'jp': 'バハマ'},
  // 'p153_t720': { 'zh-cn': '巴林', 'en': 'Bahrain', 'jp': 'バーレーン'},
  // 'p153_t730': { 'zh-cn': '孟加拉国', 'en': 'Bangladesh', 'jp': 'バングラデシュ'},
  // 'p153_t740': { 'zh-cn': '巴巴多斯', 'en': 'Barbados', 'jp': 'バルバドス'},
  // 'p153_t750': { 'zh-cn': '白俄罗斯', 'en': 'Belarus', 'jp': 'ベラルーシ'},
  // 'p153_t760': { 'zh-cn': '比利时', 'en': 'Belgium', 'jp': 'ベルギー'},
  // 'p153_t770': { 'zh-cn': '伯利兹城', 'en': 'Belize City', 'jp': 'ベリーズシティ'},
  // 'p153_t780': { 'zh-cn': '贝宁', 'en': 'Benin', 'jp': 'ベナン'},
  // 'p153_t790': { 'zh-cn': '百慕大', 'en': 'Bermuda', 'jp': 'バミューダ.'},
  // 'p153_t800': { 'zh-cn': '不丹', 'en': 'Bhutan', 'jp': 'ブータン'},
  // 'p153_t810': { 'zh-cn': '玻利维亚', 'en': 'Bolivia', 'jp': 'ボリビア'},
  // 'p153_t820': { 'zh-cn': '波斯尼亚和黑塞哥维那', 'en': 'Bosnia and Herzegovina', 'jp': 'ボスニアヘルツェゴビナ'},
  // 'p153_t830': { 'zh-cn': '博茨瓦纳', 'en': 'Botswana', 'jp': 'ボツワナ'},
  // 'p153_t840': { 'zh-cn': '巴西', 'en': 'Brazil', 'jp': 'ブラジル'},
  // 'p153_t850': { 'zh-cn': '英属印度洋领地', 'en': 'British Indian Ocean Territory', 'jp': 'イギリス領インド洋領地'},
  // 'p153_t860': { 'zh-cn': '文莱达鲁萨兰国', 'en': 'Brunei Darussalam', 'jp': 'ブルネイダルサラン国'},
  // 'p153_t870': { 'zh-cn': '保加利亚', 'en': 'Bulgaria', 'jp': 'ブルガリア'},
  // 'p153_t880': { 'zh-cn': '布基纳法索', 'en': 'Burkina Faso', 'jp': 'ブルキナファソ'},
  // 'p153_t890': { 'zh-cn': '布隆迪', 'en': 'Burundi', 'jp': 'ブルンジ'},
  // 'p153_t900': { 'zh-cn': '柬埔寨', 'en': 'Cambodia', 'jp': 'カンボジア'},
  // 'p153_t910': { 'zh-cn': '喀麦隆', 'en': 'Cameroon', 'jp': 'カメルーン'},
  // 'p153_t920': { 'zh-cn': '佛得角', 'en': 'Cape Verde', 'jp': 'カーボベルデ'},
  // 'p153_t930': { 'zh-cn': '开曼群岛', 'en': 'The Cayman Islands', 'jp': 'ケイマン諸島'},
  // 'p153_t940': { 'zh-cn': '中非共和国', 'en': 'Central African Republic', 'jp': '中央アフリカ共和国'},
  // 'p153_t950': { 'zh-cn': '乍得', 'en': 'Chad', 'jp': 'チャド'},
  // 'p153_t960': { 'zh-cn': '智利', 'en': 'Chile', 'jp': 'チリ'},
  // 'p153_t970': { 'zh-cn': '圣延岛', 'en': 'Saint Yan Island', 'jp': '聖延島'},
  // 'p153_t980': { 'zh-cn': '科科斯群岛', 'en': 'Cocos Islands', 'jp': 'ココス諸島'},
  // 'p153_t990': { 'zh-cn': '哥伦比亚', 'en': 'Colombia', 'jp': 'コロンビア'},
  // 'p153_t1000': { 'zh-cn': '科摩罗', 'en': 'Comoros', 'jp': 'コモロ'},
  // 'p153_t1010': { 'zh-cn': '刚果', 'en': 'Congo', 'jp': 'コンゴ.'},
  // 'p153_t1020': { 'zh-cn': '刚果民主共和国', 'en': 'Democratic Republic of Congo', 'jp': 'コンゴ民主共和国'},
  // 'p153_t1030': { 'zh-cn': '库克群岛', 'en': 'Cook Islands', 'jp': 'クック諸島'},
  // 'p153_t1040': { 'zh-cn': '哥斯达黎加', 'en': 'Costa Rica', 'jp': 'コスタリカ'},
  // 'p153_t1050': { 'zh-cn': '科特迪瓦', 'en': 'Cote d\'Ivoire', 'jp': 'コートジボワール'},
  // 'p153_t1060': { 'zh-cn': '克罗地亚', 'en': 'Croatia', 'jp': 'クロアチア'},
  // 'p153_t1070': { 'zh-cn': '古巴', 'en': 'Cuba', 'jp': 'キューバ'},
  // 'p153_t1080': { 'zh-cn': '塞浦路斯', 'en': 'Cyprus', 'jp': 'キプロス'},
  // 'p153_t1090': { 'zh-cn': '捷克', 'en': 'Czech Republic', 'jp': 'チェコ.'},
  // 'p153_t1100': { 'zh-cn': '丹麦', 'en': 'Denmark', 'jp': 'デンマーク'},
  // 'p153_t1110': { 'zh-cn': '吉布提', 'en': 'Djibouti', 'jp': 'ジブチ'},
  // 'p153_t1120': { 'zh-cn': '多米尼克国', 'en': 'Dominica', 'jp': 'ドミニク'},
  // 'p153_t1130': { 'zh-cn': '多米尼加共和国', 'en': 'Dominican Republic', 'jp': 'ドミニカ共和国'},
  // 'p153_t1140': { 'zh-cn': '东帝汶', 'en': 'East Timor', 'jp': '東ティモール'},
  // 'p153_t1150': { 'zh-cn': '厄瓜多尔', 'en': 'Ecuador', 'jp': 'エクアドル'},
  // 'p153_t1160': { 'zh-cn': '埃及', 'en': 'Egypt', 'jp': 'エジプト'},
  // 'p153_t1170': { 'zh-cn': '萨尔瓦多', 'en': 'El Salvador', 'jp': 'エルサルバドル'},
  // 'p153_t1180': { 'zh-cn': '赤道几内亚', 'en': 'Equatorial Guinea', 'jp': '赤道ギニア'},
  // 'p153_t1190': { 'zh-cn': '爱沙尼亚', 'en': 'Estonia', 'jp': 'エストニア'},
  // 'p153_t1200': { 'zh-cn': '埃塞俄比亚', 'en': 'Ethiopia', 'jp': 'エチオピア'},
  // 'p153_t1210': { 'zh-cn': '福克兰群岛', 'en': 'Falkland Islands', 'jp': 'フォークランド諸島'},
  // 'p153_t1220': { 'zh-cn': '法罗群岛', 'en': 'Faroe Islands', 'jp': 'フェロー諸島'},
  // 'p153_t1230': { 'zh-cn': '斐济', 'en': 'Fiji', 'jp': 'フィジー'},
  // 'p153_t1240': { 'zh-cn': '芬兰', 'en': 'Finland', 'jp': 'フィンランド'},
  // 'p153_t1250': { 'zh-cn': '法国', 'en': 'France', 'jp': 'フランス'},
  // 'p153_t1260': { 'zh-cn': '法国大都会', 'en': 'Metropolitan France', 'jp': 'フランスのメトロポリタン'},
  // 'p153_t1270': { 'zh-cn': '法属圭亚那', 'en': 'French Guiana', 'jp': 'フランス領ガイアナ'},
  // 'p153_t1280': { 'zh-cn': '法属玻里尼西亚', 'en': 'French Polynesia', 'jp': 'フランス領ポリネシア'},
  // 'p153_t1290': { 'zh-cn': '加蓬', 'en': 'Gabon', 'jp': 'ガボン'},
  // 'p153_t1300': { 'zh-cn': '冈比亚', 'en': 'Gambia', 'jp': 'ガンビア'},
  // 'p153_t1310': { 'zh-cn': '格鲁吉亚', 'en': 'Georgia', 'jp': 'ジョージア'},
  // 'p153_t1320': { 'zh-cn': '德国', 'en': 'Germany', 'jp': 'ドイツ'},
  // 'p153_t1330': { 'zh-cn': '加纳', 'en': 'Ghana', 'jp': 'ガーナ'},
  // 'p153_t1340': { 'zh-cn': '直布罗陀', 'en': 'Gibraltar', 'jp': 'ジブラルタル'},
  // 'p153_t1350': { 'zh-cn': '希腊', 'en': 'Greece', 'jp': 'ギリシャ'},
  // 'p153_t1360': { 'zh-cn': '格陵兰', 'en': 'Greenland', 'jp': 'グリーンランド.'},
  // 'p153_t1370': { 'zh-cn': '格林纳达', 'en': 'Grenada', 'jp': 'グレナダ'},
  // 'p153_t1380': { 'zh-cn': '瓜德罗普岛', 'en': 'Guadeloupe Island', 'jp': 'グアドループ島'},
  // 'p153_t1390': { 'zh-cn': '关岛', 'en': 'Guam', 'jp': 'グアム島'},
  // 'p153_t1400': { 'zh-cn': '危地马拉', 'en': 'Guatemala', 'jp': 'グアテマラ'},
  // 'p153_t1410': { 'zh-cn': '几内亚', 'en': 'Guinea', 'jp': 'ギニア'},
  // 'p153_t1420': { 'zh-cn': '几内亚比绍', 'en': 'Guinea-Bissau', 'jp': 'ギニアビサウ'},
  // 'p153_t1430': { 'zh-cn': '圭亚那', 'en': 'Guyana', 'jp': 'ガイアナ'},
  // 'p153_t1440': { 'zh-cn': '海地', 'en': 'Haiti', 'jp': 'ハイチ'},
  // 'p153_t1450': { 'zh-cn': '洪都拉斯', 'en': 'Honduras', 'jp': 'ホンジュラス'},
  // 'p153_t1460': { 'zh-cn': '匈牙利', 'en': 'Hungary', 'jp': 'ハンガリー'},
  // 'p153_t1470': { 'zh-cn': '冰岛', 'en': 'Iceland', 'jp': 'アイスランド'},
  // 'p153_t1480': { 'zh-cn': '印度', 'en': 'India', 'jp': 'インド'},
  // 'p153_t1490': { 'zh-cn': '印度尼西亚', 'en': 'Indonesia', 'jp': 'インドネシア.'},
  // 'p153_t1500': { 'zh-cn': '伊朗', 'en': 'Iran', 'jp': 'イラン'},
  // 'p153_t1510': { 'zh-cn': '伊拉克', 'en': 'Iraq', 'jp': 'イラク'},
  // 'p153_t1520': { 'zh-cn': '爱尔兰', 'en': 'Ireland', 'jp': 'アイルランド'},
  // 'p153_t1530': { 'zh-cn': '以色列', 'en': 'Israel', 'jp': 'イスラエル'},
  // 'p153_t1540': { 'zh-cn': '意大利', 'en': 'Italy', 'jp': 'イタリア'},
  // 'p153_t1550': { 'zh-cn': '牙买加', 'en': 'Jamaica', 'jp': 'ジャマイカ'},
  // 'p153_t1560': { 'zh-cn': '约旦', 'en': 'Jordan', 'jp': 'ヨルダン'},
  // 'p153_t1570': { 'zh-cn': '哈萨克', 'en': 'Kazakhstan', 'jp': 'カザフ'},
  // 'p153_t1580': { 'zh-cn': '肯尼亚', 'en': 'Kenya', 'jp': 'ケニア'},
  // 'p153_t1590': { 'zh-cn': '科威特', 'en': 'Kuwait', 'jp': 'クウェート'},
  // 'p153_t1600': { 'zh-cn': '吉尔吉斯', 'en': 'Kyrgyzstan', 'jp': 'キルギス.'},
  // 'p153_t1610': { 'zh-cn': '拉脱维亚', 'en': 'Latvia', 'jp': 'ラトビア'},
  // 'p153_t1620': { 'zh-cn': '黎巴嫩', 'en': 'Lebanon', 'jp': 'レバノン'},
  // 'p153_t1630': { 'zh-cn': '莱索托', 'en': 'Lesotho', 'jp': 'レソト'},
  // 'p153_t1640': { 'zh-cn': '利比里亚', 'en': 'Liberia', 'jp': 'リベリア'},
  // 'p153_t1650': { 'zh-cn': '利比亚', 'en': 'Libya', 'jp': 'リビア'},
  // 'p153_t1660': { 'zh-cn': '列支敦士登', 'en': 'Liechtenstein', 'jp': 'リヒテンシュタイン'},
  // 'p153_t1670': { 'zh-cn': '立陶宛', 'en': 'Lithuania', 'jp': 'リトアニア'},
  // 'p153_t1680': { 'zh-cn': '卢森堡', 'en': 'Luxembourg', 'jp': 'ルクセンブルク'},
  // 'p153_t1690': { 'zh-cn': '马达加斯加', 'en': 'Madagascar', 'jp': 'マダガスカル'},
  // 'p153_t1700': { 'zh-cn': '马拉维', 'en': 'Malawi', 'jp': 'マラウイ'},
  // 'p153_t1710': { 'zh-cn': '马来西亚', 'en': 'Malaysia', 'jp': 'マレーシア'},
  // 'p153_t1720': { 'zh-cn': '马尔代夫', 'en': 'Maldives', 'jp': 'モルディブ'},
  // 'p153_t1730': { 'zh-cn': '马里', 'en': 'Mali', 'jp': 'マリ'},
  // 'p153_t1740': { 'zh-cn': '马尔他', 'en': 'Malta', 'jp': 'マルタ'},
  // 'p153_t1750': { 'zh-cn': '马提尼克岛', 'en': 'Martinique Island', 'jp': 'マルティニーク島'},
  // 'p153_t1760': { 'zh-cn': '毛里塔尼亚', 'en': 'Mauritania', 'jp': 'モーリタニア'},
  // 'p153_t1770': { 'zh-cn': '毛里求斯', 'en': 'Mauritius', 'jp': 'モーリシャス'},
  // 'p153_t1780': { 'zh-cn': '马约特', 'en': 'Mayotte', 'jp': 'マヨット.'},
  // 'p153_t1790': { 'zh-cn': '墨西哥', 'en': 'Mexico', 'jp': 'メキシコ'},
  // 'p153_t1800': { 'zh-cn': '密克罗尼西亚', 'en': 'Micronesia', 'jp': 'ミクロネシア'},
  // 'p153_t1810': { 'zh-cn': '摩尔多瓦', 'en': 'Moldova', 'jp': 'モルドバ'},
  // 'p153_t1820': { 'zh-cn': '摩纳哥', 'en': 'Monaco', 'jp': 'モナコ'},
  // 'p153_t1830': { 'zh-cn': '外蒙古', 'en': 'Outer Mongolia', 'jp': 'モンゴル'},
  // 'p153_t1840': { 'zh-cn': '黑山共和国', 'en': 'Republic of Montenegro', 'jp': 'モンテネグロ共和国'},
  // 'p153_t1850': { 'zh-cn': '蒙特塞拉特', 'en': 'Montserrat', 'jp': 'モンセラット'},
  // 'p153_t1860': { 'zh-cn': '摩洛哥', 'en': 'Morocco', 'jp': 'モロッコ'},
  // 'p153_t1870': { 'zh-cn': '莫桑比克', 'en': 'Mozambique', 'jp': 'モザンビーク'},
  // 'p153_t1880': { 'zh-cn': '缅甸', 'en': 'Myanmar', 'jp': 'ミャンマー'},
  // 'p153_t1890': { 'zh-cn': '那米比亚', 'en': 'Namibia', 'jp': 'ナミビア'},
  // 'p153_t1900': { 'zh-cn': '瑙鲁', 'en': 'Nauru', 'jp': 'ナウル'},
  // 'p153_t1910': { 'zh-cn': '尼泊尔', 'en': 'Nepal', 'jp': 'ネパール'},
  // 'p153_t1920': { 'zh-cn': '荷兰', 'en': 'Netherlands', 'jp': 'オランダ'},
  // 'p153_t1930': { 'zh-cn': '荷兰安的列斯群岛', 'en': 'Netherlands Antilles', 'jp': 'オランダ・アンティレス諸島'},
  // 'p153_t1940': { 'zh-cn': '新喀里多尼亚', 'en': 'New Caledonia ', 'jp': 'ニューカレドニア'},
  // 'p153_t1950': { 'zh-cn': '新西兰', 'en': 'New Zealand', 'jp': 'ニュージーランド'},
  // 'p153_t1960': { 'zh-cn': '尼加拉瓜', 'en': 'Nicaragua', 'jp': 'ニカラグア'},
  // 'p153_t1970': { 'zh-cn': '尼日尔', 'en': 'Niger', 'jp': 'ニジェール'},
  // 'p153_t1980': { 'zh-cn': '尼日利亚', 'en': 'Nigeria', 'jp': 'ナイジェリア'},
  // 'p153_t1990': { 'zh-cn': '诺福克岛', 'en': 'Norfolk Island', 'jp': 'ノーフォーク島'},
  // 'p153_t2000': { 'zh-cn': '朝鲜', 'en': 'North Korea', 'jp': '北朝鮮'},
  // 'p153_t2010': { 'zh-cn': '北马里亚纳群岛', 'en': 'Northern Mariana Islands', 'jp': '北マリアナ諸島'},
  // 'p153_t2020': { 'zh-cn': '挪威', 'en': 'Norway', 'jp': 'ノルウェー'},
  // 'p153_t2030': { 'zh-cn': '阿曼', 'en': 'Oman', 'jp': 'オマーン'},
  // 'p153_t2040': { 'zh-cn': '巴基斯坦', 'en': 'Pakistan', 'jp': 'パキスタン'},
  // 'p153_t2050': { 'zh-cn': '帛琉', 'en': 'palau ', 'jp': '絹と琉'},
  // 'p153_t2060': { 'zh-cn': '巴勒斯坦', 'en': 'Palestine', 'jp': 'パレスチナ'},
  // 'p153_t2070': { 'zh-cn': '巴拿马', 'en': 'Panama', 'jp': 'パナマ'},
  // 'p153_t2080': { 'zh-cn': '巴布亚新几内亚', 'en': 'Papua New Guinea', 'jp': 'パプアニューギニア'},
  // 'p153_t2090': { 'zh-cn': '巴拉圭', 'en': 'Paraguay', 'jp': 'パラグアイ'},
  // 'p153_t2100': { 'zh-cn': '秘鲁', 'en': 'Peru', 'jp': 'ペルー'},
  // 'p153_t2110': { 'zh-cn': '菲律宾共和国', 'en': 'Republic of the Philippines', 'jp': 'フィリピン共和国'},
  // 'p153_t2120': { 'zh-cn': '皮特凯恩岛', 'en': 'Pitcairn Island', 'jp': 'ピトケイン島'},
  // 'p153_t2130': { 'zh-cn': '波兰', 'en': 'Poland', 'jp': 'ポーランド'},
  // 'p153_t2140': { 'zh-cn': '葡萄牙', 'en': 'Portugal', 'jp': 'ポルトガル'},
  // 'p153_t2150': { 'zh-cn': '波多黎各', 'en': 'Puerto Rico', 'jp': 'プエルトリコ.'},
  // 'p153_t2160': { 'zh-cn': '卡塔尔', 'en': 'Qatar', 'jp': 'カタール'},
  // 'p153_t2170': { 'zh-cn': '留尼汪岛', 'en': 'Reunion Island', 'jp': 'レユニオン島'},
  // 'p153_t2180': { 'zh-cn': '罗马尼亚', 'en': 'Romania', 'jp': 'ルーマニア'},
  // 'p153_t2190': { 'zh-cn': '俄罗斯联邦', 'en': 'Russian federation', 'jp': 'ロシア連邦'},
  // 'p153_t2200': { 'zh-cn': '卢旺达', 'en': 'Rwanda', 'jp': 'ルワンダ'},
  // 'p153_t2210': { 'zh-cn': '美属萨摩亚', 'en': 'American Samoa ', 'jp': 'アメリカ領サモア'},
  // 'p153_t2220': { 'zh-cn': '圣马力诺共和国', 'en': 'Republic of San Marino ', 'jp': 'サンマリノ共和国'},
  // 'p153_t2230': { 'zh-cn': '沙特阿拉伯', 'en': 'Saudi Arabia', 'jp': 'サウジアラビア'},
  // 'p153_t2240': { 'zh-cn': '塞内加尔', 'en': 'Senegal', 'jp': 'セネガル'},
  // 'p153_t2250': { 'zh-cn': '塞尔维亚共和国', 'en': 'Republic of Serbia', 'jp': 'セルビア共和国'},
  // 'p153_t2260': { 'zh-cn': '塞舌尔', 'en': 'Seychelles', 'jp': 'セーシェル'},
  // 'p153_t2270': { 'zh-cn': '塞拉利昂', 'en': 'Sierra Leone', 'jp': 'シエラレオネ'},
  // 'p153_t2280': { 'zh-cn': '新加坡', 'en': 'Singapore', 'jp': 'シンガポール'},
  // 'p153_t2290': { 'zh-cn': '斯洛伐克', 'en': 'Slovakia', 'jp': 'スロバキア'},
  // 'p153_t2300': { 'zh-cn': '斯洛文尼亚', 'en': 'Slovenia', 'jp': 'スロベニア'},
  // 'p153_t2310': { 'zh-cn': '索罗门群岛', 'en': 'Solomon Islands', 'jp': 'ソロモン諸島'},
  // 'p153_t2320': { 'zh-cn': '索马里', 'en': 'Somalia', 'jp': 'ソマリア'},
  // 'p153_t2330': { 'zh-cn': '南非', 'en': 'South Africa', 'jp': '南アフリカ'},
  // 'p153_t2340': { 'zh-cn': '西班牙', 'en': 'Spain', 'jp': 'スペイン'},
  // 'p153_t2350': { 'zh-cn': '斯里兰卡', 'en': 'Sri Lanka', 'jp': 'スリランカ'},
  // 'p153_t2360': { 'zh-cn': '苏丹', 'en': 'Sudan', 'jp': 'スーダン'},
  // 'p153_t2370': { 'zh-cn': '苏里南', 'en': 'Suriname', 'jp': 'スリナム'},
  // 'p153_t2380': { 'zh-cn': '斯威士兰', 'en': 'Eswatini', 'jp': 'エスワティニ'},
  // 'p153_t2390': { 'zh-cn': '瑞典', 'en': 'Sweden', 'jp': 'スウェーデン'},
  // 'p153_t2400': { 'zh-cn': '瑞士', 'en': 'Switzerland', 'jp': 'スイス'},
  // 'p153_t2410': { 'zh-cn': '叙利亚', 'en': 'Syria', 'jp': 'シリア'},
  // 'p153_t2420': { 'zh-cn': '塔吉克', 'en': 'Tajikistan ', 'jp': 'タジク.'},
  // 'p153_t2430': { 'zh-cn': '坦桑尼亚', 'en': 'Tanzania', 'jp': 'タンザニア'},
  // 'p153_t2440': { 'zh-cn': '泰国', 'en': 'Thailand', 'jp': 'タイ'},
  // 'p153_t2450': { 'zh-cn': '多哥', 'en': 'Togo', 'jp': 'トーゴ'},
  // 'p153_t2460': { 'zh-cn': '汤加', 'en': 'Tonga', 'jp': 'トンガ'},
  // 'p153_t2470': { 'zh-cn': '特立尼达和多巴哥', 'en': 'Trinidad and Tobago', 'jp': 'トリニダードトバゴ'},
  // 'p153_t2480': { 'zh-cn': '突尼斯', 'en': 'Tunisia', 'jp': 'チュニジア'},
  // 'p153_t2490': { 'zh-cn': '土耳其', 'en': 'Turkey', 'jp': 'トルコ'},
  // 'p153_t2500': { 'zh-cn': '土库曼', 'en': 'Turkmenistan', 'jp': 'トルクメン'},
  // 'p153_t2510': { 'zh-cn': '土克斯及开科斯群岛', 'en': 'Turks and Caicos ', 'jp': 'トルクスおよびカイコス諸島'},
  // 'p153_t2520': { 'zh-cn': '乌干达', 'en': 'Uganda', 'jp': 'ウガンダ'},
  // 'p153_t2530': { 'zh-cn': '乌克兰', 'en': 'Ukraine', 'jp': 'ウクライナ'},
  // 'p153_t2540': { 'zh-cn': '阿拉伯联合酋长国', 'en': 'United Arab Emirates', 'jp': 'アラブ首長国連邦'},
  // 'p153_t2550': { 'zh-cn': '英国', 'en': 'United Kingdom', 'jp': 'イギリス'},
  // 'p153_t2560': { 'zh-cn': '乌拉圭', 'en': 'Uruguay', 'jp': 'ウルグアイ'},
  // 'p153_t2570': { 'zh-cn': '乌兹别克斯坦', 'en': 'Uzbekistan', 'jp': 'ウズベキスタン'},
  // 'p153_t2580': { 'zh-cn': '瓦努阿图', 'en': 'Vanuatu', 'jp': 'バヌアツ'},
  // 'p153_t2590': { 'zh-cn': '梵蒂冈', 'en': 'Vatican City', 'jp': 'バチカン市国'},
  // 'p153_t2600': { 'zh-cn': '委内瑞拉', 'en': 'Venezuela', 'jp': 'ベネズエラ'},
  // 'p153_t2610': { 'zh-cn': '越南', 'en': 'Vietnam', 'jp': 'ベトナム'},
  // 'p153_t2620': { 'zh-cn': '维尔京群岛', 'en': 'Virgin Islands', 'jp': 'バージン諸島'},
  // 'p153_t2640': { 'zh-cn': '西撒哈拉', 'en': 'Western Sahara', 'jp': '西サハラ'},
  // 'p153_t2650': { 'zh-cn': '也门', 'en': 'Yemen', 'jp': 'イエメン'},
  // 'p153_t2660': { 'zh-cn': '南斯拉夫', 'en': 'Yugoslavia', 'jp': 'ユーゴスラビア.'},
  // 'p153_t2670': { 'zh-cn': '赞比亚', 'en': 'Zambia', 'jp': 'ザンビア'},
  // 'p153_t2680': { 'zh-cn': '津巴布韦', 'en': 'Zimbabwe', 'jp': 'ジンバブエ'},
  // 'p153_t2690': { 'zh-cn': '阿布哈兹', 'en': 'Abkhazia', 'jp': 'アブハジア'},
  // 'p153_t2700': { 'zh-cn': '南奥赛梯', 'en': 'South Ossetia ', 'jp': '南オセチア'},
  // 'p153_t2710': { 'zh-cn': '泽西岛', 'en': 'Jersey Island', 'jp': '沢西島'},
  // 'p153_t2720': { 'zh-cn': '老挝', 'en': 'Laos', 'jp': 'ラオス'},
  // 'p153_t2730': { 'zh-cn': '马其顿', 'en': 'Macedonia', 'jp': 'マケドニア.'},
  // 'p153_t2740': { 'zh-cn': '圣基茨和尼维斯', 'en': 'Saint Kitts and Nevis', 'jp': 'セントクリストファーネイビス'},
  // 'p153_t2750': { 'zh-cn': '圣卢西亚岛', 'en': 'Saint Lucia Island', 'jp': 'セントルシア島'},
  // 'p153_t2760': { 'zh-cn': '圣文森特和格林纳丁斯', 'en': 'Saint Vincent and the Grenadines', 'jp': 'セントビンセントおよびグレナディーン諸島'},
  // 'p153_t2770': { 'zh-cn': '圣多美和普林西比', 'en': 'Sao Tome and Principe', 'jp': 'サントメプリンシペ'},
  // 'p153_t2780': { 'zh-cn': '南苏丹共和国', 'en': 'Republic of South Sudan', 'jp': '南スーダン共和国'},


  'p153_t13000': { 'zh-cn': '阿富汗', 'en': 'Afghanistan', 'jp': 'アフガニスタン' },
  'p153_t13001': { 'zh-cn': '阿尔巴尼亚', 'en': 'Albania', 'jp': 'アルバニア' },
  'p153_t13002': { 'zh-cn': '阿尔及利亚', 'en': 'Algeria', 'jp': 'アルジェリア' },
  'p153_t13003': { 'zh-cn': '安道尔', 'en': 'Andorra', 'jp': 'アンドラ' },
  'p153_t13004': { 'zh-cn': '安哥拉', 'en': 'Angola', 'jp': 'アンゴラ' },
  'p153_t13005': { 'zh-cn': '安提瓜和巴布达', 'en': 'Antigua and Barbuda', 'jp': 'アンティグア・バーブーダ' },
  'p153_t13006': { 'zh-cn': '阿根廷', 'en': 'Argentina', 'jp': 'アルゼンチン' },
  'p153_t13007': { 'zh-cn': '亚美尼亚', 'en': 'Armenia', 'jp': 'アルメニア' },
  'p153_t13008': { 'zh-cn': '澳大利亚', 'en': 'Australia', 'jp': 'オーストラリア' },
  'p153_t13009': { 'zh-cn': '奥地利', 'en': 'Austria', 'jp': 'オーストリア' },
  'p153_t13010': { 'zh-cn': '阿塞拜疆', 'en': 'Azerbaijan', 'jp': 'アゼルバイジャン' },
  'p153_t13011': { 'zh-cn': '巴哈马', 'en': 'Bahamas', 'jp': 'バハマ' },
  'p153_t13012': { 'zh-cn': '巴林', 'en': 'Bahrain', 'jp': 'バーレーン' },
  'p153_t13013': { 'zh-cn': '孟加拉国', 'en': 'Bangladesh', 'jp': 'バングラデシュ' },
  'p153_t13014': { 'zh-cn': '巴巴多斯', 'en': 'Barbados', 'jp': 'バルバドス' },
  'p153_t13015': { 'zh-cn': '白俄罗斯', 'en': 'Belarus', 'jp': 'ベラルーシ' },
  'p153_t13016': { 'zh-cn': '比利时', 'en': 'Belgium', 'jp': 'ベルギー' },
  'p153_t13017': { 'zh-cn': '伯利兹', 'en': 'Belize', 'jp': 'ベリーズ' },
  'p153_t13018': { 'zh-cn': '贝宁', 'en': 'Benin', 'jp': 'ベナン' },
  'p153_t13019': { 'zh-cn': '不丹', 'en': 'Bhutan', 'jp': 'ブータン' },
  'p153_t13020': { 'zh-cn': '玻利维亚', 'en': 'Bolivia', 'jp': 'ボリビア' },
  'p153_t13021': { 'zh-cn': '波斯尼亚和黑塞哥维那', 'en': 'Bosnia and Herzegovina', 'jp': 'ボスニア・ヘルツェゴビナ' },
  'p153_t13022': { 'zh-cn': '博茨瓦纳', 'en': 'Botswana', 'jp': 'ボツワナ' },
  'p153_t13023': { 'zh-cn': '巴西', 'en': 'Brazil', 'jp': 'ブラジル' },
  'p153_t13024': { 'zh-cn': '文莱', 'en': 'Brunei', 'jp': 'ブルネイ' },
  'p153_t13025': { 'zh-cn': '保加利亚', 'en': 'Bulgaria', 'jp': 'ブルガリア' },
  'p153_t13026': { 'zh-cn': '布基纳法索', 'en': 'Burkina Faso', 'jp': 'ブルキナファソ' },
  'p153_t13027': { 'zh-cn': '布隆迪', 'en': 'Burundi', 'jp': 'ブルンジ' },
  'p153_t13028': { 'zh-cn': '柬埔寨', 'en': 'Cambodia', 'jp': 'カンボジア' },
  'p153_t13029': { 'zh-cn': '喀麦隆', 'en': 'Cameroon', 'jp': 'カメルーン' },
  'p153_t13030': { 'zh-cn': '加拿大', 'en': 'Canada', 'jp': 'カナダ' },
  'p153_t13031': { 'zh-cn': '佛得角', 'en': 'Cape Verde', 'jp': 'カーボベルデ' },
  'p153_t13032': { 'zh-cn': '中非共和国', 'en': 'Central African Republic', 'jp': '中央アフリカ共和国' },
  'p153_t13033': { 'zh-cn': '乍得', 'en': 'Chad', 'jp': 'チャド' },
  'p153_t13034': { 'zh-cn': '智利', 'en': 'Chile', 'jp': 'チリ' },
  'p153_t13035': { 'zh-cn': '中国', 'en': 'China', 'jp': '中国' },
  'p153_t13036': { 'zh-cn': '哥伦比亚', 'en': 'Colombia', 'jp': 'コロンビア' },
  'p153_t13037': { 'zh-cn': '科摩罗', 'en': 'Comoros', 'jp': 'コモロ' },
  'p153_t13038': { 'zh-cn': '刚果（金）', 'en': 'Democratic Republic of the Congo', 'jp': 'コンゴ民主共和国' },
  'p153_t13039': { 'zh-cn': '刚果（布）', 'en': 'Republic of the Congo', 'jp': 'コンゴ共和国' },
  'p153_t13040': { 'zh-cn': '哥斯达黎加', 'en': 'Costa Rica', 'jp': 'コスタリカ' },
  'p153_t13041': { 'zh-cn': '科特迪瓦', 'en': 'Cote d\'Ivoire', 'jp': 'コートジボワール' },
  'p153_t13042': { 'zh-cn': '克罗地亚', 'en': 'Croatia', 'jp': 'クロアチア' },
  'p153_t13043': { 'zh-cn': '古巴', 'en': 'Cuba', 'jp': 'キューバ' },
  'p153_t13044': { 'zh-cn': '塞浦路斯', 'en': 'Cyprus', 'jp': 'キプロス' },
  'p153_t13045': { 'zh-cn': '捷克共和国', 'en': 'Czech Republic', 'jp': 'チェコ共和国' },
  'p153_t13046': { 'zh-cn': '丹麦', 'en': 'Denmark', 'jp': 'デンマーク' },
  'p153_t13047': { 'zh-cn': '吉布提', 'en': 'Djibouti', 'jp': 'ジブチ' },
  'p153_t13048': { 'zh-cn': '多米尼加', 'en': 'Dominica', 'jp': 'ドミニカ国' },
  'p153_t13049': { 'zh-cn': '多米尼加共和国', 'en': 'Dominican Republic', 'jp': 'ドミニカ共和国' },
  'p153_t13050': { 'zh-cn': '东帝汶', 'en': 'East Timor', 'jp': '東ティモール' },
  'p153_t13051': { 'zh-cn': '厄瓜多尔', 'en': 'Ecuador', 'jp': 'エクアドル' },
  'p153_t13052': { 'zh-cn': '埃及', 'en': 'Egypt', 'jp': 'エジプト' },
  'p153_t13053': { 'zh-cn': '萨尔瓦多', 'en': 'El Salvador', 'jp': 'エルサルバドル' },
  'p153_t13054': { 'zh-cn': '赤道几内亚', 'en': 'Equatorial Guinea', 'jp': '赤道ギニア' },
  'p153_t13055': { 'zh-cn': '厄立特里亚', 'en': 'Eritrea', 'jp': 'エリトリア' },
  'p153_t13056': { 'zh-cn': '爱沙尼亚', 'en': 'Estonia', 'jp': 'エストニア' },
  'p153_t13057': { 'zh-cn': '斯威士兰', 'en': 'Eswatini', 'jp': 'エスワティニ' },
  'p153_t13058': { 'zh-cn': '埃塞俄比亚', 'en': 'Ethiopia', 'jp': 'エチオピア' },
  'p153_t13059': { 'zh-cn': '斐济', 'en': 'Fiji', 'jp': 'フィジー' },
  'p153_t13060': { 'zh-cn': '芬兰', 'en': 'Finland', 'jp': 'フィンランド' },
  'p153_t13061': { 'zh-cn': '法国', 'en': 'France', 'jp': 'フランス' },
  'p153_t13062': { 'zh-cn': '加蓬', 'en': 'Gabon', 'jp': 'ガボン' },
  'p153_t13063': { 'zh-cn': '冈比亚', 'en': 'Gambia', 'jp': 'ガンビア' },
  'p153_t13064': { 'zh-cn': '格鲁吉亚', 'en': 'Georgia', 'jp': 'ジョージア' },
  'p153_t13065': { 'zh-cn': '德国', 'en': 'Germany', 'jp': 'ドイツ' },
  'p153_t13066': { 'zh-cn': '加纳', 'en': 'Ghana', 'jp': 'ガーナ' },
  'p153_t13067': { 'zh-cn': '希腊', 'en': 'Greece', 'jp': 'ギリシャ' },
  'p153_t13068': { 'zh-cn': '格林纳达', 'en': 'Grenada', 'jp': 'グレナダ' },
  'p153_t13069': { 'zh-cn': '危地马拉', 'en': 'Guatemala', 'jp': 'グアテマラ' },
  'p153_t13070': { 'zh-cn': '几内亚', 'en': 'Guinea', 'jp': 'ギニア' },
  'p153_t13071': { 'zh-cn': '几内亚比绍', 'en': 'Guinea-Bissau', 'jp': 'ギニアビサウ' },
  'p153_t13072': { 'zh-cn': '圭亚那', 'en': 'Guyana', 'jp': 'ガイアナ' },
  'p153_t13073': { 'zh-cn': '海地', 'en': 'Haiti', 'jp': 'ハイチ' },
  'p153_t13074': { 'zh-cn': '洪都拉斯', 'en': 'Honduras', 'jp': 'ホンジュラス' },
  'p153_t13075': { 'zh-cn': '匈牙利', 'en': 'Hungary', 'jp': 'ハンガリー' },
  'p153_t13076': { 'zh-cn': '冰岛', 'en': 'Iceland', 'jp': 'アイスランド' },
  'p153_t13077': { 'zh-cn': '印度', 'en': 'India', 'jp': 'インド' },
  'p153_t13078': { 'zh-cn': '印尼', 'en': 'Indonesia', 'jp': 'インドネシア' },
  'p153_t13079': { 'zh-cn': '伊朗', 'en': 'Iran', 'jp': 'イラン' },
  'p153_t13080': { 'zh-cn': '伊拉克', 'en': 'Iraq', 'jp': 'イラク' },
  'p153_t13081': { 'zh-cn': '爱尔兰', 'en': 'Ireland', 'jp': 'アイルランド' },
  'p153_t13082': { 'zh-cn': '以色列', 'en': 'Israel', 'jp': 'イスラエル' },
  'p153_t13083': { 'zh-cn': '意大利', 'en': 'Italy', 'jp': 'イタリア' },
  'p153_t13084': { 'zh-cn': '牙买加', 'en': 'Jamaica', 'jp': 'ジャマイカ' },
  'p153_t13085': { 'zh-cn': '日本', 'en': 'Japan', 'jp': '日本' },
  'p153_t13086': { 'zh-cn': '约旦', 'en': 'Jordan', 'jp': 'ヨルダン' },
  'p153_t13087': { 'zh-cn': '哈萨克斯坦', 'en': 'Kazakhstan', 'jp': 'カザフスタン' },
  'p153_t13088': { 'zh-cn': '肯尼亚', 'en': 'Kenya', 'jp': 'ケニア' },
  'p153_t13089': { 'zh-cn': '基里巴斯', 'en': 'Kiribati', 'jp': 'キリバス' },
  'p153_t13090': { 'zh-cn': '朝鲜', 'en': 'North Korea', 'jp': '北朝鮮' },
  'p153_t13091': { 'zh-cn': '韩国', 'en': 'South Korea', 'jp': '韓国' },
  'p153_t13092': { 'zh-cn': '科索沃', 'en': 'Kosovo', 'jp': 'コソボ' },
  'p153_t13093': { 'zh-cn': '科威特', 'en': 'Kuwait', 'jp': 'クウェート' },
  'p153_t13094': { 'zh-cn': '吉尔吉斯斯坦', 'en': 'Kyrgyzstan', 'jp': 'キルギス' },
  'p153_t13095': { 'zh-cn': '老挝', 'en': 'Laos', 'jp': 'ラオス' },
  'p153_t13096': { 'zh-cn': '拉脱维亚', 'en': 'Latvia', 'jp': 'ラトビア' },
  'p153_t13097': { 'zh-cn': '黎巴嫩', 'en': 'Lebanon', 'jp': 'レバノン' },
  'p153_t13098': { 'zh-cn': '莱索托', 'en': 'Lesotho', 'jp': 'レソト' },
  'p153_t13099': { 'zh-cn': '利比里亚', 'en': 'Liberia', 'jp': 'リベリア' },
  'p153_t13100': { 'zh-cn': '利比亚', 'en': 'Libya', 'jp': 'リビア' },
  'p153_t13101': { 'zh-cn': '列支敦士登', 'en': 'Liechtenstein', 'jp': 'リヒテンシュタイン' },
  'p153_t13102': { 'zh-cn': '立陶宛', 'en': 'Lithuania', 'jp': 'リトアニア' },
  'p153_t13103': { 'zh-cn': '卢森堡', 'en': 'Luxembourg', 'jp': 'ルクセンブルク' },
  'p153_t13104': { 'zh-cn': '马达加斯加', 'en': 'Madagascar', 'jp': 'マダガスカル' },
  'p153_t13105': { 'zh-cn': '马拉维', 'en': 'Malawi', 'jp': 'マラウイ' },
  'p153_t13106': { 'zh-cn': '马来西亚', 'en': 'Malaysia', 'jp': 'マレーシア' },
  'p153_t13107': { 'zh-cn': '马尔代夫', 'en': 'Maldives', 'jp': 'モルディブ' },
  'p153_t13108': { 'zh-cn': '马里', 'en': 'Mali', 'jp': 'マリ' },
  'p153_t13109': { 'zh-cn': '马耳他', 'en': 'Malta', 'jp': 'マルタ' },
  'p153_t13110': { 'zh-cn': '马绍尔群岛', 'en': 'Marshall Islands', 'jp': 'マーシャル諸島' },
  'p153_t13111': { 'zh-cn': '毛里塔尼亚', 'en': 'Mauritania', 'jp': 'モーリタニア' },
  'p153_t13112': { 'zh-cn': '毛里求斯', 'en': 'Mauritius', 'jp': 'モーリシャス' },
  'p153_t13113': { 'zh-cn': '墨西哥', 'en': 'Mexico', 'jp': 'メキシコ' },
  'p153_t13114': { 'zh-cn': '密克罗尼西亚', 'en': 'Micronesia', 'jp': 'ミクロネシア' },
  'p153_t13115': { 'zh-cn': '摩尔多瓦', 'en': 'Moldova', 'jp': 'モルドバ' },
  'p153_t13116': { 'zh-cn': '摩纳哥', 'en': 'Monaco', 'jp': 'モナコ' },
  'p153_t13117': { 'zh-cn': '蒙古', 'en': 'Mongolia', 'jp': 'モンゴル' },
  'p153_t13118': { 'zh-cn': '黑山', 'en': 'Montenegro', 'jp': 'モンテネグロ' },
  'p153_t13119': { 'zh-cn': '摩洛哥', 'en': 'Morocco', 'jp': 'モロッコ' },
  'p153_t13120': { 'zh-cn': '莫桑比克', 'en': 'Mozambique', 'jp': 'モザンビーク' },
  'p153_t13121': { 'zh-cn': '缅甸', 'en': 'Myanmar', 'jp': 'ミャンマー' },
  'p153_t13122': { 'zh-cn': '纳米比亚', 'en': 'Namibia', 'jp': 'ナミビア' },
  'p153_t13123': { 'zh-cn': '瑙鲁', 'en': 'Nauru', 'jp': 'ナウル' },
  'p153_t13124': { 'zh-cn': '尼泊尔', 'en': 'Nepal', 'jp': 'ネパール' },
  'p153_t13125': { 'zh-cn': '荷兰', 'en': 'Netherlands', 'jp': 'オランダ' },
  'p153_t13126': { 'zh-cn': '新西兰', 'en': 'New Zealand', 'jp': 'ニュージーランド' },
  'p153_t13127': { 'zh-cn': '尼加拉瓜', 'en': 'Nicaragua', 'jp': 'ニカラグア' },
  'p153_t13128': { 'zh-cn': '尼日尔', 'en': 'Niger', 'jp': 'ニジェール' },
  'p153_t13129': { 'zh-cn': '尼日利亚', 'en': 'Nigeria', 'jp': 'ナイジェリア' },
  'p153_t13130': { 'zh-cn': '北马其顿', 'en': 'North Macedonia', 'jp': '北マケドニア' },
  'p153_t13131': { 'zh-cn': '挪威', 'en': 'Norway', 'jp': 'ノルウェー' },
  'p153_t13132': { 'zh-cn': '阿曼', 'en': 'Oman', 'jp': 'オマーン' },
  'p153_t13133': { 'zh-cn': '巴基斯坦', 'en': 'Pakistan', 'jp': 'パキスタン' },
  'p153_t13134': { 'zh-cn': '帕劳', 'en': 'Palau', 'jp': 'パラオ' },
  'p153_t13135': { 'zh-cn': '巴勒斯坦', 'en': 'Palestine', 'jp': 'パレスチナ' },
  'p153_t13136': { 'zh-cn': '巴拿马', 'en': 'Panama', 'jp': 'パナマ' },
  'p153_t13137': { 'zh-cn': '巴布亚新几内亚', 'en': 'Papua New Guinea', 'jp': 'パプアニューギニア' },
  'p153_t13138': { 'zh-cn': '巴拉圭', 'en': 'Paraguay', 'jp': 'パラグアイ' },
  'p153_t13139': { 'zh-cn': '秘鲁', 'en': 'Peru', 'jp': 'ペルー' },
  'p153_t13140': { 'zh-cn': '菲律宾', 'en': 'Philippines', 'jp': 'フィリピン' },
  'p153_t13141': { 'zh-cn': '波兰', 'en': 'Poland', 'jp': 'ポーランド' },
  'p153_t13142': { 'zh-cn': '葡萄牙', 'en': 'Portugal', 'jp': 'ポルトガル' },
  'p153_t13143': { 'zh-cn': '卡塔尔', 'en': 'Qatar', 'jp': 'カタール' },
  'p153_t13144': { 'zh-cn': '罗马尼亚', 'en': 'Romania', 'jp': 'ルーマニア' },
  'p153_t13145': { 'zh-cn': '俄罗斯', 'en': 'Russia', 'jp': 'ロシア' },
  'p153_t13146': { 'zh-cn': '卢旺达', 'en': 'Rwanda', 'jp': 'ルワンダ' },
  'p153_t13147': { 'zh-cn': '圣基茨和尼维斯', 'en': 'Saint Kitts and Nevis', 'jp': 'セントクリストファー・ネイビス' },
  'p153_t13148': { 'zh-cn': '圣卢西亚', 'en': 'Saint Lucia', 'jp': 'セントルシア' },
  'p153_t13149': { 'zh-cn': '圣文森特和格林纳丁斯', 'en': 'Saint Vincent and the Grenadines', 'jp': 'セントビンセントおよびグレナディーン諸島' },
  'p153_t13150': { 'zh-cn': '萨摩亚', 'en': 'Samoa', 'jp': 'サモア' },
  'p153_t13151': { 'zh-cn': '圣马力诺', 'en': 'San Marino', 'jp': 'サンマリノ' },
  'p153_t13152': { 'zh-cn': '圣多美和普林西比', 'en': 'Sao Tome and Principe', 'jp': 'サントメ・プリンシペ' },
  'p153_t13153': { 'zh-cn': '沙特阿拉伯', 'en': 'Saudi Arabia', 'jp': 'サウジアラビア' },
  'p153_t13154': { 'zh-cn': '塞内加尔', 'en': 'Senegal', 'jp': 'セネガル' },
  'p153_t13155': { 'zh-cn': '塞尔维亚', 'en': 'Serbia', 'jp': 'セルビア' },
  'p153_t13156': { 'zh-cn': '塞舌尔', 'en': 'Seychelles', 'jp': 'セーシェル' },
  'p153_t13157': { 'zh-cn': '塞拉利昂', 'en': 'Sierra Leone', 'jp': 'シエラレオネ' },
  'p153_t13158': { 'zh-cn': '新加坡', 'en': 'Singapore', 'jp': 'シンガポール' },
  'p153_t13159': { 'zh-cn': '斯洛伐克', 'en': 'Slovakia', 'jp': 'スロバキア' },
  'p153_t13160': { 'zh-cn': '斯洛文尼亚', 'en': 'Slovenia', 'jp': 'スロベニア' },
  'p153_t13161': { 'zh-cn': '所罗门群岛', 'en': 'Solomon Islands', 'jp': 'ソロモン諸島' },
  'p153_t13162': { 'zh-cn': '索马里', 'en': 'Somalia', 'jp': 'ソマリア' },
  'p153_t13163': { 'zh-cn': '南非', 'en': 'South Africa', 'jp': '南アフリカ' },
  'p153_t13164': { 'zh-cn': '南苏丹', 'en': 'South Sudan', 'jp': '南スーダン' },
  'p153_t13165': { 'zh-cn': '西班牙', 'en': 'Spain', 'jp': 'スペイン' },
  'p153_t13166': { 'zh-cn': '斯里兰卡', 'en': 'Sri Lanka', 'jp': 'スリランカ' },
  'p153_t13167': { 'zh-cn': '苏丹', 'en': 'Sudan', 'jp': 'スーダン' },
  'p153_t13168': { 'zh-cn': '苏里南', 'en': 'Suriname', 'jp': 'スリナム' },
  'p153_t13169': { 'zh-cn': '瑞典', 'en': 'Sweden', 'jp': 'スウェーデン' },
  'p153_t13170': { 'zh-cn': '瑞士', 'en': 'Switzerland', 'jp': 'スイス' },
  'p153_t13171': { 'zh-cn': '叙利亚', 'en': 'Syria', 'jp': 'シリア' },
  'p153_t13172': { 'zh-cn': '中国台湾', 'en': 'Taiwan', 'jp': '台湾' },
  'p153_t13173': { 'zh-cn': '塔吉克斯坦', 'en': 'Tajikistan', 'jp': 'タジキスタン' },
  'p153_t13174': { 'zh-cn': '坦桑尼亚', 'en': 'Tanzania', 'jp': 'タンザニア' },
  'p153_t13175': { 'zh-cn': '泰国', 'en': 'Thailand', 'jp': 'タイ' },
  'p153_t13176': { 'zh-cn': '多哥', 'en': 'Togo', 'jp': 'トーゴ' },
  'p153_t13177': { 'zh-cn': '汤加', 'en': 'Tonga', 'jp': 'トンガ' },
  'p153_t13178': { 'zh-cn': '特立尼达和多巴哥', 'en': 'Trinidad and Tobago', 'jp': 'トリニダード・トバゴ' },
  'p153_t13179': { 'zh-cn': '突尼斯', 'en': 'Tunisia', 'jp': 'チュニジア' },
  'p153_t13180': { 'zh-cn': '土耳其', 'en': 'Turkey', 'jp': 'トルコ' },
  'p153_t13181': { 'zh-cn': '土库曼斯坦', 'en': 'Turkmenistan', 'jp': 'トルクメニスタン' },
  'p153_t13182': { 'zh-cn': '图瓦卢', 'en': 'Tuvalu', 'jp': 'ツバル' },
  'p153_t13183': { 'zh-cn': '乌干达', 'en': 'Uganda', 'jp': 'ウガンダ' },
  'p153_t13184': { 'zh-cn': '乌克兰', 'en': 'Ukraine', 'jp': 'ウクライナ' },
  'p153_t13185': { 'zh-cn': '阿拉伯联合酋长国', 'en': 'United Arab Emirates', 'jp': 'アラブ首長国連邦' },
  'p153_t13186': { 'zh-cn': '英国', 'en': 'United Kingdom', 'jp': 'イギリス' },
  'p153_t13187': { 'zh-cn': '美国', 'en': 'United States', 'jp': 'アメリカ合衆国' },
  'p153_t13188': { 'zh-cn': '乌拉圭', 'en': 'Uruguay', 'jp': 'ウルグアイ' },
  'p153_t13189': { 'zh-cn': '乌兹别克斯坦', 'en': 'Uzbekistan', 'jp': 'ウズベキスタン' },
  'p153_t13190': { 'zh-cn': '瓦努阿图', 'en': 'Vanuatu', 'jp': 'バヌアツ' },
  'p153_t13191': { 'zh-cn': '梵蒂冈', 'en': 'Vatican City', 'jp': 'バチカン市国' },
  'p153_t13192': { 'zh-cn': '委内瑞拉', 'en': 'Venezuela', 'jp': 'ベネズエラ' },
  'p153_t13193': { 'zh-cn': '越南', 'en': 'Vietnam', 'jp': 'ベトナム' },
  'p153_t13194': { 'zh-cn': '也门', 'en': 'Yemen', 'jp': 'イエメン' },
  'p153_t13195': { 'zh-cn': '赞比亚', 'en': 'Zambia', 'jp': 'ザンビア' },
  'p153_t13196': { 'zh-cn': '津巴布韦', 'en': 'Zimbabwe', 'jp': 'ジンバブエ' },
  'p153_t13197': { 'zh-cn': '中国澳门', 'en': 'Macau,China', 'jp': '中国・マカオ' },
  'p153_t13198': { 'zh-cn': '中国香港', 'en': 'Hong Kong,China', 'jp': '中国・香港' },


  // 'p153_t2810': { 'zh-cn': '橄榄球（美式）', 'en': 'Rugby (American style)', 'jp': 'ラグビー（アメリカン）'},
  // 'p153_t2820': { 'zh-cn': '射箭', 'en': 'Archery', 'jp': 'アーチェリー '},
  // 'p153_t2830': { 'zh-cn': '田径', 'en': 'Athletics', 'jp': '陸上競技 '},
  // 'p153_t2840': { 'zh-cn': '赛车/摩托车', 'en': 'Racing/Motorcycle', 'jp': 'レーシングカー/バイク'},
  // 'p153_t2850': { 'zh-cn': '羽毛球', 'en': 'Badminton', 'jp': 'バドミントン '},
  // 'p153_t2860': { 'zh-cn': '棒/垒球', 'en': 'Baseball/Softball', 'jp': 'スティック/ソフトボール'},
  // 'p153_t2870': { 'zh-cn': '篮球', 'en': 'Basketball', 'jp': 'バスケットボール '},
  // 'p153_t2880': { 'zh-cn': '冬季两项', 'en': 'Biathlon in winter', 'jp': '冬の2種目'},
  // 'p153_t2890': { 'zh-cn': '斯诺克/台球', 'en': 'Snooker/Billiards', 'jp': 'スヌーカー/ビリヤード '},
  // 'p153_t2900': { 'zh-cn': '雪车/钢架雪车', 'en': 'Snowmobile/Steel Frame Snowmobile', 'jp': 'スノーカー/スチールラックスノーカー'},
  // 'p153_t2910': { 'zh-cn': '拳击', 'en': 'Boxing', 'jp': 'ボクシング '},
  // 'p153_t2920': { 'zh-cn': '皮划艇', 'en': 'Canoeing/Kayaking', 'jp': 'カヌー/カヤック '},
  // 'p153_t2930': { 'zh-cn': '登山', 'en': 'Mountaineering', 'jp': '山に登る'},
  // 'p153_t2940': { 'zh-cn': '板球', 'en': 'Cricket', 'jp': 'クリケット '},
  // 'p153_t2950': { 'zh-cn': '冰壶', 'en': 'Curling', 'jp': 'カーリング'},
  // 'p153_t2960': { 'zh-cn': '小人车', 'en': 'Minion car', 'jp': '小人車'},
  // 'p153_t2970': { 'zh-cn': '自行车', 'en': 'Bicycles', 'jp': '自転車'},
  // 'p153_t2980': { 'zh-cn': '霹雳舞', 'en': 'Breakdancing', 'jp': 'ブレイクダンス'},
  // 'p153_t2990': { 'zh-cn': '飞镖', 'en': 'Darts', 'jp': 'ダーツ'},
  // 'p153_t3000': { 'zh-cn': '马术', 'en': 'Equestrian', 'jp': '馬術 '},
  // 'p153_t3010': { 'zh-cn': '击剑', 'en': 'fencing', 'jp': 'フェンシングを'},
  // 'p153_t3020': { 'zh-cn': '飞盘', 'en': 'Frisbee', 'jp': 'フライホイール'},
  // 'p153_t3030': { 'zh-cn': '足球', 'en': 'Football/Soccer', 'jp': 'サッカー '},
  // 'p153_t3040': { 'zh-cn': '高尔夫', 'en': 'Golf', 'jp': 'ゴルフ '},
  // 'p153_t3050': { 'zh-cn': '体操', 'en': 'Gymnastics', 'jp': '体操 '},
  // 'p153_t3060': { 'zh-cn': '手球', 'en': 'Handball', 'jp': 'ハンドボール'},
  // 'p153_t3070': { 'zh-cn': '曲棍球', 'en': 'Field Hockey', 'jp': 'フィールドホッケー'},
  // 'p153_t3080': { 'zh-cn': '柔道', 'en': 'Judo', 'jp': '柔道 '},
  // 'p153_t3090': { 'zh-cn': '空手道', 'en': 'Karate', 'jp': '空手 '},
  // 'p153_t3100': { 'zh-cn': '棍网球', 'en': 'Lacrosse ', 'jp': 'こん棒テニス'},
  // 'p153_t3110': { 'zh-cn': '雪橇', 'en': 'sled', 'jp': 'そり台'},
  // 'p153_t3120': { 'zh-cn': '现代五项', 'en': 'Modern pentathlon', 'jp': '近代五項'},
  // 'p153_t3130': { 'zh-cn': '智力运动（棋牌类）', 'en': 'Intellectual Sports (Chess and Card)', 'jp': '知的スポーツ（将棋類）'},
  // 'p153_t3140': { 'zh-cn': '定向运动', 'en': 'Orienteering', 'jp': 'しこううんどう'},
  // 'p153_t3150': { 'zh-cn': '马球', 'en': 'polo', 'jp': 'ポロ'},
  // 'p153_t3160': { 'zh-cn': '滑板', 'en': 'skateboard', 'jp': 'スライド台'},
  // 'p153_t3170': { 'zh-cn': '赛艇', 'en': 'Rowing', 'jp': 'ボート隻'},
  // 'p153_t3180': { 'zh-cn': '橄榄球（英式）', 'en': 'Rugby (English)', 'jp': 'ラグビーボール（英国式）'},
  // 'p153_t3190': { 'zh-cn': '帆船/帆板', 'en': 'Sailing/windsurfing', 'jp': 'ヨット/ウインドブレーカー'},
  // 'p153_t3200': { 'zh-cn': '射击', 'en': 'Shooting', 'jp': '射撃 '},
  // 'p153_t3210': { 'zh-cn': '滑冰', 'en': 'Figure Skating', 'jp': 'フィギュアスケート '},
  // 'p153_t3220': { 'zh-cn': '滑雪', 'en': 'Skiing', 'jp': 'スキー '},
  // 'p153_t3230': { 'zh-cn': '运动攀岩', 'en': 'sport climbing ', 'jp': 'スポーツクライミング'},
  // 'p153_t3240': { 'zh-cn': '相扑', 'en': 'Sumo wrestling', 'jp': '相撲'},
  // 'p153_t3250': { 'zh-cn': '冲浪', 'en': 'Surfing', 'jp': 'サーフィン '},
  // 'p153_t3260': { 'zh-cn': '游泳', 'en': 'Swimming', 'jp': '水泳 '},
  // 'p153_t3270': { 'zh-cn': '跳水', 'en': 'Diving', 'jp': '飛び込み '},
  // 'p153_t3280': { 'zh-cn': '乒乓球', 'en': 'Table Tennis', 'jp': '卓球 '},
  // 'p153_t3290': { 'zh-cn': '跆拳道', 'en': 'Taekwondo', 'jp': 'テコンドー '},
  // 'p153_t3300': { 'zh-cn': '网球', 'en': 'Tennis', 'jp': 'テニス '},
  // 'p153_t3310': { 'zh-cn': '铁人三项', 'en': 'Ironman Triathlon', 'jp': 'アイアンマントライアスロン '},
  // 'p153_t3320': { 'zh-cn': '排球', 'en': 'Volleyball', 'jp': 'バレーボール '},
  // 'p153_t3330': { 'zh-cn': '举重', 'en': 'Weightlifting', 'jp': 'ウエイトリフティング '},
  // 'p153_t3340': { 'zh-cn': '摔跤', 'en': 'Wrestling', 'jp': 'レスリング '},
  // 'p153_t3350': { 'zh-cn': '冰球', 'en': 'Ice Hockey', 'jp': 'アイスホッケー '},
  // 'p153_t3360': { 'zh-cn': '电子竞技', 'en': 'E-sports', 'jp': '電子競技'},
  // 'p153_t3370': { 'zh-cn': '极限运动', 'en': 'Extreme sports', 'jp': 'きょくげんうんどう'},
  // 'p153_t3380': { 'zh-cn': '障碍跑', 'en': 'Obstacle running', 'jp': '障害走'},
  // 'p153_t3390': { 'zh-cn': '综合格斗', 'en': 'Comprehensive Fighting', 'jp': '総合格闘技'},
  // 'p153_t3400': { 'zh-cn': '其他', 'en': 'Others', 'jp': 'その他'},

  'p153_t2810': { 'zh-cn': '足球', 'en': 'Football/Soccer', 'jp': 'サッカー ' },
  'p153_t2820': { 'zh-cn': '篮球', 'en': 'Basketball', 'jp': 'バスケットボール ' },
  'p153_t2830': { 'zh-cn': '田径', 'en': 'Athletics', 'jp': '陸上競技 ' },
  'p153_t2840': { 'zh-cn': '游泳', 'en': 'Swimming', 'jp': '水泳 ' },
  'p153_t2850': { 'zh-cn': '网球', 'en': 'Tennis', 'jp': 'テニス ' },
  'p153_t2860': { 'zh-cn': '乒乓球', 'en': 'Table Tennis', 'jp': '卓球 ' },
  'p153_t2870': { 'zh-cn': '排球', 'en': 'Volleyball', 'jp': 'バレーボール ' },
  'p153_t2880': { 'zh-cn': '橄榄球', 'en': 'Rugby', 'jp': 'ラグビー ' },
  'p153_t2890': { 'zh-cn': '美式橄榄球', 'en': 'American Football', 'jp': 'アメリカン・フットボール' },
  'p153_t2900': { 'zh-cn': '棒球', 'en': 'Baseball', 'jp': '野球 ' },
  'p153_t2910': { 'zh-cn': '垒球', 'en': 'Softball', 'jp': 'ソフトボール' },
  'p153_t2920': { 'zh-cn': '板球', 'en': 'Cricket', 'jp': 'クリケット ' },
  'p153_t2930': { 'zh-cn': '高尔夫', 'en': 'Golf', 'jp': 'ゴルフ ' },
  'p153_t2940': { 'zh-cn': '滑冰', 'en': 'Figure Skating', 'jp': 'フィギュアスケート ' },
  'p153_t2950': { 'zh-cn': '体操', 'en': 'Gymnastics', 'jp': '体操 ' },
  'p153_t2960': { 'zh-cn': '自行车赛', 'en': 'Cycling', 'jp': '自転車競技 ' },
  'p153_t2970': { 'zh-cn': '摩托车赛', 'en': 'MotoGP', 'jp': 'モータースポーツ ' },
  'p153_t2980': { 'zh-cn': 'F1赛车', 'en': 'Formula 1', 'jp': 'フォーミュラ1 ' },
  'p153_t2990': { 'zh-cn': '拳击', 'en': 'Boxing', 'jp': 'ボクシング ' },
  'p153_t3000': { 'zh-cn': '柔道', 'en': 'Judo', 'jp': '柔道 ' },
  'p153_t3010': { 'zh-cn': '跆拳道', 'en': 'Taekwondo', 'jp': 'テコンドー ' },
  'p153_t3020': { 'zh-cn': '摔跤', 'en': 'Wrestling', 'jp': 'レスリング ' },
  'p153_t3030': { 'zh-cn': '举重', 'en': 'Weightlifting', 'jp': 'ウエイトリフティング ' },
  'p153_t3040': { 'zh-cn': '射箭', 'en': 'Archery', 'jp': 'アーチェリー ' },
  'p153_t3050': { 'zh-cn': '射击', 'en': 'Shooting', 'jp': '射撃 ' },
  'p153_t3060': { 'zh-cn': '帆船', 'en': 'Sailing', 'jp': 'セーリング ' },
  'p153_t3070': { 'zh-cn': '划船', 'en': 'Rowing', 'jp': 'ボート競技 ' },
  'p153_t3080': { 'zh-cn': '皮划艇', 'en': 'Canoeing/Kayaking', 'jp': 'カヌー/カヤック ' },
  'p153_t3090': { 'zh-cn': '冲浪', 'en': 'Surfing', 'jp': 'サーフィン ' },
  'p153_t3100': { 'zh-cn': '滑雪', 'en': 'Skiing', 'jp': 'スキー ' },
  'p153_t3110': { 'zh-cn': '雪板', 'en': 'Snowboarding', 'jp': 'スノーボード ' },
  'p153_t3120': { 'zh-cn': '冰球', 'en': 'Ice Hockey', 'jp': 'アイスホッケー ' },
  'p153_t3130': { 'zh-cn': '橄榄球联盟', 'en': 'Rugby League', 'jp': 'ラグビーリーグ ' },
  'p153_t3140': { 'zh-cn': '橄榄球联合会', 'en': 'Rugby Union', 'jp': 'ラグビーユニオン ' },
  'p153_t3150': { 'zh-cn': '澳式足球', 'en': 'Australian Rules Football', 'jp': 'オーストラリアンフットボール ' },
  'p153_t3160': { 'zh-cn': '羽毛球', 'en': 'Badminton', 'jp': 'バドミントン ' },
  'p153_t3170': { 'zh-cn': '斯诺克/台球', 'en': 'Snooker/Billiards', 'jp': 'スヌーカー/ビリヤード ' },
  'p153_t3180': { 'zh-cn': '赛马', 'en': 'Horse Racing', 'jp': '競馬 ' },
  'p153_t3190': { 'zh-cn': '赛车', 'en': 'Car Racing', 'jp': 'カーレーシング ' },
  'p153_t3200': { 'zh-cn': '越野跑', 'en': 'Cross Country Running', 'jp': 'クロスカントリー ' },
  'p153_t3210': { 'zh-cn': '马拉松', 'en': 'Marathon', 'jp': 'マラソン ' },
  'p153_t3220': { 'zh-cn': '三项全能', 'en': 'Triathlon', 'jp': 'トライアスロン ' },
  'p153_t3230': { 'zh-cn': '铁人三项', 'en': 'Ironman Triathlon', 'jp': 'アイアンマントライアスロン ' },
  'p153_t3240': { 'zh-cn': '骑马', 'en': 'Equestrian', 'jp': '馬術 ' },
  'p153_t3250': { 'zh-cn': '跳水', 'en': 'Diving', 'jp': '飛び込み ' },
  'p153_t3260': { 'zh-cn': '水球', 'en': 'Water Polo', 'jp': 'ウォーターポロ ' },
  'p153_t3270': { 'zh-cn': '空手道', 'en': 'Karate', 'jp': '空手 ' },
  'p153_t3280': { 'zh-cn': '门球', 'en': 'Croquet', 'jp': 'クローケー ' },
  'p153_t3290': { 'zh-cn': '相扑', 'en': 'Sumo', 'jp': '相撲' },
  'p153_t3300': { 'zh-cn': '高山滑雪', 'en': 'Alpine Skiing', 'jp': 'アルペンスキー ' },
  'p153_t3310': { 'zh-cn': '电竞', 'en': 'E-Sports', 'jp': 'Eスポーツ' },
  'p153_t3320': { 'zh-cn': '霹雳舞/街舞', 'en': 'Breaking', 'jp': 'ブレイキング・' },
  'p153_t3330': { 'zh-cn': '手球', 'en': 'Handball', 'jp': 'ハンドボール' },
  'p153_t3340': { 'zh-cn': '曲棍球', 'en': 'Field Hockey', 'jp': 'フィールドホッケー' },
  'p153_t3350': { 'zh-cn': '冰壶', 'en': 'Curling', 'jp': 'カーリング' },
  'p153_t3360': { 'zh-cn': '沙滩排球', 'en': 'Beach Volleyball', 'jp': 'ビーチバレーボール' },
  'p153_t3370': { 'zh-cn': '沙滩足球', 'en': 'Beach Soccer', 'jp': 'ビーチサッカー' },
  'p153_t3380': { 'zh-cn': '智力体育', 'en': 'Mind Sports', 'jp': 'マインドスポーツ' },
  'p153_t3390': { 'zh-cn': '越野滑雪', 'en': 'Cross-country Skiing', 'jp': 'クロスカントリースキー' },
  'p153_t3400': { 'zh-cn': '潜水', 'en': 'Diving', 'jp': 'ダイビング' },
  'p153_t3401': { 'zh-cn': '花样游泳', 'en': 'Synchronized Swimming', 'jp': 'シンクロナイズドスイミング' },
  'p153_t3402': { 'zh-cn': '马术', 'en': 'Equestrian', 'jp': '馬術 ' },
  'p153_t3403': { 'zh-cn': '龙舟', 'en': 'Dragon Boat', 'jp': 'ドラゴンボート' },
  'p153_t3404': { 'zh-cn': '水上摩托', 'en': 'Jet Ski', 'jp': 'ジェットスキー' },
  'p153_t3405': { 'zh-cn': '综合格斗/自由搏击', 'en': 'MMA/Kickboxing', 'jp': '総合格闘技/キックボックシング' },
  'p153_t3406': { 'zh-cn': '沙滩手球', 'en': 'Beach Handball', 'jp': 'ビーチハンドボール' },
  'p153_t3407': { 'zh-cn': '极限运动', 'en': 'Extreme Sports', 'jp': 'エクストリームスポーツ' },
  'p153_t3409': { 'zh-cn': '其他', 'en': 'Others', 'jp': 'その他' },


  // 'p153_t3410': { 'zh-cn': '音乐', 'en': 'Music', 'jp': '音楽'},
  // 'p153_t3420': { 'zh-cn': '旅行与体验当地文化', 'en': 'Travel and Culture Experience', 'jp': '旅行と地域文化の体験'},
  // 'p153_t3430': { 'zh-cn': '艺术与设计', 'en': 'Art & Design', 'jp': 'アート＆デザイン'},
  // 'p153_t3440': { 'zh-cn': '时尚与美妆', 'en': 'Fashion & beauty', 'jp': 'ファッション＆ビューティー'},
  // 'p153_t3450': { 'zh-cn': '年轻力与街头文化', 'en': 'Youth & Street Culture', 'jp': '若者とストリートカルチャー'},
  // 'p153_t3460': { 'zh-cn': '阅读书籍', 'en': 'Reading', 'jp': '本を読む'},
  // 'p153_t3470': { 'zh-cn': '游戏与科技', 'en': 'Games & Technology', 'jp': 'ゲーム＆テクノロジー'},
  // 'p153_t3480': { 'zh-cn': '营养与健康', 'en': 'Nutrition and Health Living', 'jp': '栄養と健康'},
  // 'p153_t3490': { 'zh-cn': '户外生活', 'en': 'Outdoor Activity', 'jp': 'アウトドアライフ'},
  // 'p153_t3500': { 'zh-cn': '宠物爱好者', 'en': 'Pet Lover', 'jp': 'ペット愛好家'},
  // 'p153_t3510': { 'zh-cn': '性别平等和种族平等', 'en': 'Gender Equality and Racial Harmony', 'jp': 'ジェンダーと人種平等'},
  // 'p153_t3520': { 'zh-cn': '妇女赋权', 'en': 'Women\'s empowerment', 'jp': '女性のエンパワーメント'},
  // 'p153_t3530': { 'zh-cn': '社会责任与社区关爱', 'en': 'Social Responsibility and Care for Community', 'jp': '社会的責任と地域ケア'},
  // 'p153_t3540': { 'zh-cn': '环保与可持续发展', 'en': 'Environment and Sustainability', 'jp': '環境保護と持続可能な開発'},
  // 'p153_t3550': { 'zh-cn': '青年文化', 'en': 'Youth Culture', 'jp': '若者文化'},

  'p153_t5000': { 'zh-cn': '音乐', 'en': 'Music', 'jp': '音楽' },
  'p153_t5010': { 'zh-cn': '旅游', 'en': 'Travel', 'jp': '旅行' },
  'p153_t5020': { 'zh-cn': '艺术', 'en': 'Arts', 'jp': '美術・芸術' },
  'p153_t5030': { 'zh-cn': '时尚', 'en': 'Fashion', 'jp': 'ファッション' },
  'p153_t5040': { 'zh-cn': '书籍', 'en': 'Reading', 'jp': '読書' },
  'p153_t5050': { 'zh-cn': '游戏', 'en': 'Games', 'jp': 'ゲーム' },
  'p153_t5060': { 'zh-cn': '科技/互联网', 'en': 'Tech / Internet', 'jp': 'IT・インターネット' },
  'p153_t5070': { 'zh-cn': '健康/美容', 'en': 'Health / Beauty', 'jp': '美容・健康' },
  'p153_t5080': { 'zh-cn': '户外活动', 'en': 'Outdoor Activities', 'jp': 'アウトドア' },
  'p153_t5090': { 'zh-cn': '宠物/动物', 'en': 'Pets / Animals', 'jp': 'ペット・動物' },
  'p153_t5100': { 'zh-cn': '电影/剧院', 'en': 'Movies / Theatres', 'jp': '映画・舞台鑑賞' },
  'p153_t5110': { 'zh-cn': '烹饪', 'en': 'Cooking', 'jp': '料理' },
  'p153_t5120': { 'zh-cn': '餐饮', 'en': 'Dining', 'jp': 'グルメ' },
  'p153_t5130': { 'zh-cn': '饮酒', 'en': 'Alcohol', 'jp': 'お酒' },
  'p153_t5140': { 'zh-cn': '体育观赏', 'en': 'Sports Viewing', 'jp': 'スポーツ観戦' },
  'p153_t5150': { 'zh-cn': '体育活动', 'en': 'Sports Activities', 'jp': 'スポーツ' },
  'p153_t5160': { 'zh-cn': '舞蹈', 'en': 'Dancing', 'jp': 'ダンス' },
  'p153_t5170': { 'zh-cn': '瑜伽/普拉提', 'en': 'Yoga / Pilates', 'jp': 'ヨガ・ピラティス' },
  'p153_t5180': { 'zh-cn': '温泉/桑拿', 'en': 'Hot Springs / Sauna', 'jp': '温泉・サウナ' },
  'p153_t5190': { 'zh-cn': '摄影', 'en': 'Photography', 'jp': 'カメラ・写真' },
  'p153_t5200': { 'zh-cn': '钓鱼', 'en': 'Fishing', 'jp': '釣り' },
  'p153_t5210': { 'zh-cn': '自行车', 'en': 'Bicycles', 'jp': '自転車' },
  'p153_t5220': { 'zh-cn': '汽车/摩托车', 'en': 'Cars / Motorbikes', 'jp': '車・バイク' },
  'p153_t5230': { 'zh-cn': '股票/投资', 'en': 'Stocks / Investment', 'jp': '投資・株' },
  'p153_t5240': { 'zh-cn': '购物', 'en': 'Shopping', 'jp': 'ショッピング' },
  'p153_t5250': { 'zh-cn': '园艺', 'en': 'Gardening', 'jp': '園芸' },
  'p153_t5260': { 'zh-cn': '电视/视频', 'en': 'TV / Videos', 'jp': 'テレビ・動画鑑賞' },
  'p153_t5270': { 'zh-cn': '语言', 'en': 'Languages', 'jp': '語学' },
  'p153_t5280': { 'zh-cn': '房地产', 'en': 'Real Estate', 'jp': '不動産' },
  'p153_t5290': { 'zh-cn': '博彩/赌博', 'en': 'Betting / Gambling', 'jp': 'ギャンブル' },
  'p153_t5300': { 'zh-cn': '青年赋权', 'en': 'Youth Empowerment', 'jp': '若者支援' },
  'p153_t5310': { 'zh-cn': '性别平等', 'en': 'Gender Equality', 'jp': 'ジェンダー平等' },
  'p153_t5320': { 'zh-cn': '反种族主义', 'en': 'Anti-racism', 'jp': '反人種差別' },
  'p153_t5330': { 'zh-cn': '社会贡献', 'en': 'Social Contribution', 'jp': '社会貢献' },
  'p153_t5340': { 'zh-cn': '环境可持续性', 'en': 'Environmental Sustainability', 'jp': '環境保護' },
  'p153_t3560': { 'zh-cn': '其他', 'en': 'Others', 'jp': 'その他' },



  'p153_tp5500':{ 'zh-cn': '音响设备', 'en': 'Audio Equipment And Hardware', 'jp': 'オーディオ機器' },
  'p153_tp5510':{ 'zh-cn': '摄影摄像器材', 'en': 'Cameras And Audio-Visual Recording Equipment', 'jp': 'カメラおよびオーディオ・ビジュアル・レコーディング機器' },
  'p153_tp5520':{ 'zh-cn': '电视机和家用投影', 'en': 'Television Sets and Home Projectors', 'jp': 'テレビとホームプロジェクター' },
  'p153_tp5530':{ 'zh-cn': '电子游戏和虚拟现实设备', 'en': 'Games Consoles and Virtual Reality Headsets and Equipment', 'jp': 'ゲーム機、バーチャルリアリティヘッドセットおよび機器' },
  'p153_tp5540':{ 'zh-cn': '电脑和配件', 'en': 'Desktop/Laptop Computers and Computer', 'jp': 'コンピューター' },
  'p153_tp5550':{ 'zh-cn': '云计算', 'en': 'Cloud Computing Services', 'jp': 'クラウド・コンピューティング・サービス' },
  'p153_tp5560':{ 'zh-cn': '软件和IT服务', 'en': 'Software and IT Services', 'jp': 'ソフトウェア及びITサービス' },
  'p153_tp5570':{ 'zh-cn': '移动通信终端', 'en': 'Mobile Telephone Handsets', 'jp': '携帯電話端末' },
  'p153_tp5580':{ 'zh-cn': '移动通信网络', 'en': 'Mobile Network Operators', 'jp': 'モバイル通信ネットワーク事業者（キャリア）' },
  'p153_tp5590':{ 'zh-cn': '白家电，小家电', 'en': 'White Goods And Home Appliances', 'jp': '白物家電、ホームアプライアンス' },
  'p153_tp5600':{ 'zh-cn': '自行车', 'en': 'Bicycles', 'jp': '自転車' },
  'p153_tp5610':{ 'zh-cn': '摩托车、燃油踏板车和其他两轮燃油车辆', 'en': 'Motorcycles, Fuel-Engine Scooters and other Two-wheeled Fuel-Engine Vehicles', 'jp': '燃料エンジン二輪車・スクーター' },
  'p153_tp5620':{ 'zh-cn': '电动滑板车平衡车', 'en': 'Electric, Pedal and Self-Balancing Scooters (e.g. Segway)', 'jp': '電動スクーター、ペダルスクーター、セルフ・バランス・スクーター（セグウェイなど）' },
  'p153_tp5630':{ 'zh-cn': '轿车、面包车、长途客车和巴士制造商', 'en': 'Cars, Vans, Coaches and Bus Manufacturers', 'jp': '自動車（乗用車、バス、バンなどを含む）' },
  'p153_tp5640':{ 'zh-cn': '二手车销售服务', 'en': 'Used Car Sales Services', 'jp': '中古車販売サービス' },
  'p153_tp5650':{ 'zh-cn': '轮胎', 'en': 'Tyres', 'jp': 'タイヤ' },
  'p153_tp5660':{ 'zh-cn': '润滑油', 'en': 'Lubricants', 'jp': '潤滑油' },
  'p153_tp5670':{ 'zh-cn': '清洁产品和服务', 'en': 'Cleaning Products And Services', 'jp': 'クリーニング製品およびサービス' },
  'p153_tp5680':{ 'zh-cn': '化妆品和服务', 'en': 'Cosmetics and Beauty Products and Services', 'jp': '化粧品' },
  'p153_tp5690':{ 'zh-cn': '保健/个人护理产品和服务', 'en': 'Health and Personal Care Products and Services', 'jp': '健康/パーソナルケア製品・サービス' },
  'p153_tp5700':{ 'zh-cn': '眼镜和光学仪器', 'en': 'Optical Eyewear Products and Opticians', 'jp': '眼鏡製品と眼鏡店' },
  'p153_tp5710':{ 'zh-cn': '文具和办公用品', 'en': 'Stationary and Office Supplies and Retailers', 'jp': '文具・事務用品' },
  'p153_tp5720':{ 'zh-cn': '儿童玩具', 'en': 'Children\'s Toys', 'jp': '子供用玩具' },
  'p153_tp5730':{ 'zh-cn': '报社及新闻机构', 'en': 'Newspaper, News Agency', 'jp': '新聞・ニュースエージェンシー' },
  'p153_tp5740':{ 'zh-cn': '教育机构', 'en': 'Educational Services and Courses', 'jp': '教育サービス' },
  'p153_tp5750':{ 'zh-cn': '人力资源服务', 'en': 'Employment/Recruitment Agencies and Services', 'jp': '雇用/人材紹介会社とサービス' },
  'p153_tp5760':{ 'zh-cn': '管理咨询服务', 'en': 'Management Consultancy Services', 'jp': '経営コンサルタント・サービス' },
  'p153_tp5770':{ 'zh-cn': '旅游服务', 'en': 'Destination Tourism Boards', 'jp': '観光局・観光協会' },
  'p153_tp5780':{ 'zh-cn': '航空公司和航空旅行', 'en': 'Airlines and Air Travel', 'jp': '航空会社と航空旅行' },
  'p153_tp5790':{ 'zh-cn': '地面交通运输服务', 'en': 'Ground Transportation Services', 'jp': '地上交通サービス' },
  'p153_tp5800':{ 'zh-cn': '仓储物流服务', 'en': 'Warehousing and Logistics Services', 'jp': '倉庫・物流サービス' },
  'p153_tp5810':{ 'zh-cn': '园艺/地面维护产品和服务', 'en': 'Gardening/Grounds Maintenance Products And Services', 'jp': 'ガーデニング製品・サービス' },
  'p153_tp5820':{ 'zh-cn': '物业管理', 'en': 'Property Management', 'jp': '不動産管理' },
  'p153_tp5830':{ 'zh-cn': '房地产开发商', 'en': 'Real Estate Development', 'jp': '不動産開発業' },
  'p153_tp5840':{ 'zh-cn': '建筑材料', 'en': 'Building/Construction Raw Materials', 'jp': '建築資材' },
  'p153_tp5850':{ 'zh-cn': '家装材料和服务', 'en': 'Home Improvement and "Do-It-Yourself" (DIY) Retailers, Products and Services', 'jp': 'ホームセンター、DIY小売店、製品、サービス' },
  'p153_tp5860':{ 'zh-cn': '家具和家居用品', 'en': 'Furniture and Homeware', 'jp': '家具・インテリア' },
  'p153_tp5870':{ 'zh-cn': '油漆涂料', 'en': 'Paint and Coating Manufacturers', 'jp': '塗料メーカー' },
  'p153_tp5880':{ 'zh-cn': '化学工业', 'en': 'Industrial Chemicals', 'jp': '工業用化学品' },
  'p153_tp5890':{ 'zh-cn': '工业机械设备', 'en': 'Industrial Machinery And Equipment', 'jp': '産業機械・設備' },
  'p153_tp5900':{ 'zh-cn': '农用机械和服务', 'en': 'Farming and Agriculture Products and Services', 'jp': '農業および農業関連製品・サービス' },
  'p153_tp5910':{ 'zh-cn': '零售商业银行', 'en': 'Retail Banking', 'jp': '銀行' },
  'p153_tp5920':{ 'zh-cn': '保险', 'en': 'Insurance and Pensions', 'jp': '保険' },
  'p153_tp5930':{ 'zh-cn': '国际汇兑', 'en': 'Foreign Exchange Services', 'jp': '外国為替サービス' },
  'p153_tp5940':{ 'zh-cn': '支付服务和技术', 'en': 'Payment Services And Technology', 'jp': '決済サービス' },
  'p153_tp5950':{ 'zh-cn': '预包装加工膳食产品（如冷冻膳食、冷冻披萨等）', 'en': 'Pre-packaged, Processed Meal Products (e.g. frozen meals, frozen pizzas, un-cooked pasta)', 'jp': '冷凍食品・加工食品' },
  'p153_tp5960':{ 'zh-cn': '零食', 'en': 'Snacks', 'jp': 'スナック菓子' },
  'p153_tp5970':{ 'zh-cn': '食品（以上适用名单包含的类别除外）', 'en': 'Foodstuffs (Excluding Categories Included In The Above List)', 'jp': '食品（冷凍食品・加工食品とスナック菓子を除く）' },
  'p153_tp5980':{ 'zh-cn': '纯净水，矿泉水', 'en': 'Pure Water, Mineral Water', 'jp': '純水・ミネラルウォーター' },
  'p153_tp5990':{ 'zh-cn': '软饮料（不包括功能饮料和运动饮料）', 'en': 'Soft Drinks (Excluding Functional And Sports Drinks)', 'jp': 'ソフトドリンク（機能性飲料・スポーツドリンクを除く）' },
  'p153_tp6000':{ 'zh-cn': '功能性饮料和运动饮料', 'en': 'Functional and Sports Drink', 'jp': '機能性飲料・スポーツドリンク' },
  'p153_tp6010':{ 'zh-cn': '牛奶和其他乳制品', 'en': 'Milk and Other Dairy Products', 'jp': '牛乳及びその他乳製品' },
  'p153_tp6020':{ 'zh-cn': '啤酒', 'en': 'Beer', 'jp': 'ビール' },
  'p153_tp6030':{ 'zh-cn': '葡萄酒、香槟和气泡酒', 'en': 'Wine, Champagne And Sparkling Wine', 'jp': 'ワイン・シャンパン・スパークリングワイン' },
  'p153_tp6040':{ 'zh-cn': '烈性酒/蒸馏酒/日本清酒', 'en': 'Hard Liquor/Distilled Spirits/Japanese Sake', 'jp': 'ハード・リカー／蒸留酒／日本酒' },
  'p153_tp6050':{ 'zh-cn': '男式商务装', 'en': 'Formal Male Business Attire', 'jp': '紳士服' },
  'p153_tp6060':{ 'zh-cn': '服装/服饰（男性商务装和运动服饰除外)', 'en': 'Clothing/Apparel (excluding Formal Male Business Attire and Sportswear)', 'jp': '衣料品（紳士ビジネスウェア、スポーツウェアを除く）' },
  'p153_tp6070':{ 'zh-cn': '运动服饰和运动鞋', 'en': 'Sportswear and Shoes', 'jp': 'スポーツウェア＆シューズ' },
  'p153_tp6080':{ 'zh-cn': '体育装备和配件', 'en': 'Sports Equipment and Accessories', 'jp': 'スポーツ用品とアクセサリー' },
  'p153_tp6090':{ 'zh-cn': '手表', 'en': 'Watches', 'jp': '時計' },
  'p153_tp6100':{ 'zh-cn': '可再生能源', 'en': 'Renewable Energy', 'jp': '再生可能エネルギー' },
  'p153_tp6110':{ 'zh-cn': '石油天然气和燃料', 'en': 'Petroleum, Gas and Fuels of any kind', 'jp': '石油・ガス・燃料' },
  'p153_tp6120':{ 'zh-cn': '固体矿产勘察、开采和生产', 'en': 'Solid Mineral Exploration, Extraction and Production', 'jp': '固体鉱物の探査、抽出、生産' },
  'p153_tp6130':{ 'zh-cn': '商场', 'en': 'Shopping Malls', 'jp': 'ショッピングモール' },
  'p153_tp6140':{ 'zh-cn': '量贩店、超市和大卖场', 'en': 'Mass Merchandisers, Superstores and Hypermarkets', 'jp': '量販店・スーパー・ハイパーマーケット' },
  'p153_tp6150':{ 'zh-cn': '杂货店，便利店', 'en': 'Kiosk, Convenience Store', 'jp': 'キオスク・コンビニエンスストア' },
  'p153_tp6160':{ 'zh-cn': '电商平台', 'en': 'E-Commerce Platform', 'jp': 'Eコマース' },
  'p153_tp6170':{ 'zh-cn': '餐厅（含快餐）', 'en': 'Restaurants (Including Fast Food)', 'jp': 'レストラン（ファーストフードを含む）' },
  'p153_tp6180':{ 'zh-cn': '酒店', 'en': 'Hotels', 'jp': 'ホテル' },
  'p153_tp6190':{ 'zh-cn': '电影院', 'en': 'Cinema', 'jp': '映画館' },
  'p153_tp6200':{ 'zh-cn': '主题公园', 'en': 'Theme parks', 'jp': 'テーマパーク' },
  'p153_tp6210':{ 'zh-cn': '健身房、健康俱乐部等健康服务', 'en': 'Fitness Clubs, Spas and “wellness” Retail Operations', 'jp': 'ジム・ヘルスクラブ・スパなど' },
  'p153_tp6220':{ 'zh-cn': '非处方药', 'en': 'Pharmaceutical Products (Non-prescription "Over-The-Counter" Products)', 'jp': '一般医薬品（処方箋が必要とされない薬品）' },
  'p153_tp6230':{ 'zh-cn': '医院和诊所', 'en': 'Hospitals/Clinics', 'jp': '病院・診療所' },
  'p153_tp6240':{ 'zh-cn': '体育博彩', 'en': 'Sports Betting', 'jp': 'スポーツ・ベッティング' },
  'p153_tp6250':{ 'zh-cn': '烟草', 'en': 'Cigarettes', 'jp': 'タバコ' },
  'p153_tp6260':{ 'zh-cn': '药房', 'en': 'Pharmacies', 'jp': '薬局' },
  'p153_tp6270':{ 'zh-cn': '虚拟货币，NFT', 'en': 'Crypto Currency/NFT', 'jp': '仮想通貨・NFT' },
  'p153_tp6280':{ 'zh-cn': '其他', 'en': 'Others', 'jp': 'その他' },


  'p153_t6010': { 'zh-cn': '年框合作', 'en': 'One-year brand ambassador deal involving image rights, social media, appearance and more.', 'jp': '年度契約' },
  'p153_t6020': { 'zh-cn': '单次发布合作', 'en': '1 post with the athlete promoting your product.', 'jp': '一回の協力につき' },
  'p153_t6030': { 'zh-cn': '运动员出席品牌方活动', 'en': 'Athlete’s live appearance at the event.', 'jp': 'スポンサー企業のイベントに出席' },
  'p153_t6040': { 'zh-cn': '运动员参与赞助商平面及视频拍摄', 'en': 'Have an athlete in participating photo and video shooting.', 'jp': 'スポンサーの広告撮影に参加' },
  'p153_t6050': { 'zh-cn': '肖像授权合作', 'en': 'Authorization and consent to use athlete’s image.', 'jp': '肖像権のみを使用する場合' },
  'p153_t6060': { 'zh-cn': '运动员以嘉宾身份出席品牌方直播', 'en': 'Have an athlete in participating live-streaming eCommerce event as guest.', 'jp': 'ゲストとしてライブ・コマースに参加' },
  'p153_t6070': { 'zh-cn': '接受实物赞助', 'en': 'Open to product/service only deal.', 'jp': '実物協賛が可能' },


  'p153_t7009': { 'zh-cn':'全球', 'en': 'Global','jp': 'グローバル' },
  'p153_t7010': { 'zh-cn':'欧洲', 'en': 'Europe','jp': 'ヨーロッパ' },
  'p153_t7020': { 'zh-cn':'芬兰', 'en': 'Finland','jp': 'フィンランド' },
  'p153_t7030': { 'zh-cn':'瑞典', 'en': 'Sweden','jp': 'スウェーデン' },
  'p153_t7040': { 'zh-cn':'挪威', 'en': 'Norway','jp': 'ノルウェー' },
  'p153_t7050': { 'zh-cn':'冰岛', 'en': 'Iceland','jp': 'アイスランド' },
  'p153_t7060': { 'zh-cn':'丹麦', 'en': 'Denmark','jp': 'デンマーク' },
  'p153_t7070': { 'zh-cn':'爱沙尼亚', 'en': 'Estonia','jp': 'エストニア' },
  'p153_t7080': { 'zh-cn':'拉脱维亚', 'en': 'Latvia','jp': 'ラトビア' },
  'p153_t7090': { 'zh-cn':'立陶宛', 'en': 'Lithuania','jp': 'リトアニア' },
  'p153_t7100': { 'zh-cn':'白俄罗斯', 'en': 'Belarus','jp': 'ベラルーシ' },
  'p153_t7110': { 'zh-cn':'俄罗斯', 'en': 'Russia','jp': 'ロシア' },
  'p153_t7120': { 'zh-cn':'乌克兰', 'en': 'Ukraine','jp': 'ウクライナ' },
  'p153_t7130': { 'zh-cn':'摩尔多瓦', 'en': 'Moldova','jp': 'モルドバ' },
  'p153_t7140': { 'zh-cn':'波兰', 'en': 'poland','jp': 'ポーランド' },
  'p153_t7150': { 'zh-cn':'捷克', 'en': 'Czech Republic','jp': 'チェコ' },
  'p153_t7160': { 'zh-cn':'斯洛伐克', 'en': 'Slovakia','jp': 'スロバキア' },
  'p153_t7170': { 'zh-cn':'匈牙利', 'en': 'Hungary','jp': 'ハンガリー' },
  'p153_t7180': { 'zh-cn':'德国', 'en': 'Germany','jp': 'ドイツ' },
  'p153_t7190': { 'zh-cn':'奥地利', 'en': 'Austria','jp': 'オーストリア' },
  'p153_t7200': { 'zh-cn':'瑞士', 'en': 'Switzerland','jp': 'スイス' },
  'p153_t7210': { 'zh-cn':'列支敦士登', 'en': 'Liechtenstein','jp': 'リヒテンシュタイン' },
  'p153_t7220': { 'zh-cn':'英国', 'en': 'Britain','jp': '英国' },
  'p153_t7230': { 'zh-cn':'爱尔兰', 'en': 'Ireland','jp': 'アイルランド' },
  'p153_t7240': { 'zh-cn':'荷兰', 'en': 'Netherlands','jp': 'オランダ' },
  'p153_t7250': { 'zh-cn':'比利时', 'en': 'Belgium','jp': 'ベルギー' },
  'p153_t7260': { 'zh-cn':'卢森堡', 'en': 'Luxembourg','jp': 'ルクセンブルク' },
  'p153_t7270': { 'zh-cn':'法国', 'en': 'France','jp': 'フランス' },
  'p153_t7280': { 'zh-cn':'摩纳哥', 'en': 'Monaco','jp': 'モナコ' },
  'p153_t7290': { 'zh-cn':'罗马尼亚', 'en': 'Romania','jp': 'ルーマニア' },
  'p153_t7300': { 'zh-cn':'保加利亚', 'en': 'Bulgaria','jp': 'ブルガリア' },
  'p153_t7310': { 'zh-cn':'塞尔维亚', 'en': 'Serbia','jp': 'セルビア' },
  'p153_t7320': { 'zh-cn':'北马其顿', 'en': 'North Macedonia','jp': '北マケドニア' },
  'p153_t7330': { 'zh-cn':'斯洛文尼亚', 'en': 'Slovenia','jp': 'スロベニア' },
  'p153_t7340': { 'zh-cn':'克罗地亚', 'en': 'Croatia','jp': 'クロアチア' },
  'p153_t7350': { 'zh-cn':'黑山', 'en': 'Montenegro ','jp': '黒山' },
  'p153_t7360': { 'zh-cn':'波斯尼亚和黑塞哥维那', 'en': 'Bosnia and Herzegovina','jp': 'ボスニア・ヘルツェゴビナ' },
  'p153_t7370': { 'zh-cn':'阿尔巴尼亚', 'en': 'Albania','jp': 'アルバニア' },
  'p153_t7380': { 'zh-cn':'希腊', 'en': 'Greece','jp': 'ギリシャ' },
  'p153_t7390': { 'zh-cn':'意大利', 'en': 'Italy','jp': 'イタリア' },
  'p153_t7400': { 'zh-cn':'马耳他', 'en': 'Malta','jp': 'マルタ' },
  'p153_t7410': { 'zh-cn':'梵蒂冈', 'en': 'Vatican','jp': 'バチカン' },
  'p153_t7420': { 'zh-cn':'圣马力诺', 'en': 'San Marino','jp': 'サンマリノ' },
  'p153_t7430': { 'zh-cn':'西班牙', 'en': 'Spain','jp': 'スペイン' },
  'p153_t7440': { 'zh-cn':'葡萄牙', 'en': 'Portugal','jp': 'ポルトガル' },
  'p153_t7450': { 'zh-cn':'安道尔', 'en': 'Andorra','jp': 'アンドラ' },
  'p153_t7460': { 'zh-cn':'其他', 'en': 'Others','jp': 'その他' },
  'p153_t7470': { 'zh-cn':'亚洲', 'en': 'Asia','jp': 'アジア' },
  'p153_t7480': { 'zh-cn':'中国', 'en': 'China','jp': '中国' },
  'p153_t7490': { 'zh-cn':'蒙古', 'en': 'Mongolia','jp': 'モンゴル' },
  'p153_t7500': { 'zh-cn':'朝鲜', 'en': 'North Korea','jp': '北朝鮮' },
  'p153_t7510': { 'zh-cn':'韩国', 'en': 'Korea','jp': '韓国' },
  'p153_t7520': { 'zh-cn':'日本', 'en': 'Japan','jp': '日本' },
  'p153_t7530': { 'zh-cn':'菲律宾', 'en': 'the Philippines','jp': 'フィリピン' },
  'p153_t7540': { 'zh-cn':'越南', 'en': 'Vietnam','jp': 'ベトナム' },
  'p153_t7550': { 'zh-cn':'老挝', 'en': 'Laos','jp': 'ラオス' },
  'p153_t7560': { 'zh-cn':'柬埔寨', 'en': 'Cambodia','jp': 'カンボジア' },
  'p153_t7570': { 'zh-cn':'缅甸', 'en': 'Myanmar','jp': 'ミャンマー' },
  'p153_t7580': { 'zh-cn':'泰国', 'en': 'Thailand','jp': 'タイ' },
  'p153_t7590': { 'zh-cn':'马来西亚', 'en': 'Malaysia','jp': 'マレーシア' },
  'p153_t7600': { 'zh-cn':'文莱', 'en': 'Brunei','jp': 'ブルネイ' },
  'p153_t7610': { 'zh-cn':'新加坡', 'en': 'Singapore','jp': 'シンガポール' },
  'p153_t7620': { 'zh-cn':'印度尼西亚', 'en': 'Indonesia','jp': 'インドネシア' },
  'p153_t7630': { 'zh-cn':'东帝汶', 'en': 'Timor-Leste','jp': '東ティモール' },
  'p153_t7640': { 'zh-cn':'尼泊尔', 'en': 'Nepal','jp': 'ネパール' },
  'p153_t7650': { 'zh-cn':'不丹', 'en': 'Bhutan','jp': 'ブータン' },
  'p153_t7660': { 'zh-cn':'孟加拉国', 'en': 'Bangladesh','jp': 'バングラデシュ' },
  'p153_t7670': { 'zh-cn':'印度', 'en': 'India','jp': 'インド' },
  'p153_t7680': { 'zh-cn':'巴基斯坦', 'en': 'Pakistan','jp': 'パキスタン' },
  'p153_t7690': { 'zh-cn':'斯里兰卡', 'en': 'Sri Lanka','jp': 'スリランカ' },
  'p153_t7700': { 'zh-cn':'马尔代夫', 'en': 'Maldives','jp': 'モルディブ' },
  'p153_t7710': { 'zh-cn':'哈萨克斯坦', 'en': 'Kazakhstan','jp': 'カザフスタン' },
  'p153_t7720': { 'zh-cn':'吉尔吉斯斯坦', 'en': 'Kyrgyzstan','jp': 'キルギス' },
  'p153_t7730': { 'zh-cn':'塔吉克斯坦', 'en': 'Tajikistan','jp': 'タジキスタン' },
  'p153_t7740': { 'zh-cn':'乌兹别克斯坦', 'en': 'Uzbekistan','jp': 'ウズベキスタン' },
  'p153_t7750': { 'zh-cn':'土库曼斯坦', 'en': 'Turkmenistan','jp': 'トルクメニスタン' },
  'p153_t7760': { 'zh-cn':'阿富汗', 'en': 'Afghanistan','jp': 'アフガニスタン' },
  'p153_t7770': { 'zh-cn':'伊拉克', 'en': 'Iraq','jp': 'イラク' },
  'p153_t7780': { 'zh-cn':'伊朗', 'en': 'Iran','jp': 'イラン' },
  'p153_t7790': { 'zh-cn':'叙利亚', 'en': 'Syria','jp': 'シリア' },
  'p153_t7800': { 'zh-cn':'约旦', 'en': 'Jordan','jp': 'ヨルダン' },
  'p153_t7810': { 'zh-cn':'黎巴嫩', 'en': 'Lebanon','jp': 'レバノン' },
  'p153_t7820': { 'zh-cn':'以色列', 'en': 'Israel','jp': 'イスラエル' },
  'p153_t7830': { 'zh-cn':'巴勒斯坦', 'en': 'Palestine','jp': 'パレスチナ' },
  'p153_t7840': { 'zh-cn':'沙特阿拉伯', 'en': 'Saudi Arabia','jp': 'サウジアラビア' },
  'p153_t7850': { 'zh-cn':'巴林', 'en': 'Bahrain','jp': 'バーレーン' },
  'p153_t7860': { 'zh-cn':'卡塔尔', 'en': 'Qatar','jp': 'カタール' },
  'p153_t7870': { 'zh-cn':'科威特', 'en': 'Kuwait','jp': 'クウェート' },
  'p153_t7880': { 'zh-cn':'阿拉伯联合酋长国', 'en': 'The United Arab Emirates','jp': 'アラブ首長国連邦' },
  'p153_t7890': { 'zh-cn':'阿曼', 'en': 'Oman','jp': 'オマーン' },
  'p153_t7900': { 'zh-cn':'也门', 'en': 'Yemen','jp': 'イエメン' },
  'p153_t7910': { 'zh-cn':'格鲁吉亚', 'en': 'Georgia','jp': 'グルジア' },
  'p153_t7920': { 'zh-cn':'亚美尼亚', 'en': 'Armenia','jp': 'アルメニア' },
  'p153_t7930': { 'zh-cn':'阿塞拜疆', 'en': 'Azerbaijan','jp': 'アゼルバイジャン' },
  'p153_t7940': { 'zh-cn':'土耳其', 'en': 'Türkiye','jp': 'トルコ' },
  'p153_t7950': { 'zh-cn':'塞浦路斯', 'en': 'Cyprus','jp': 'キプロス' },
  'p153_t7960': { 'zh-cn':'其他', 'en': 'Others','jp': 'その他' },
  'p153_t7970': { 'zh-cn':'南美洲', 'en': 'South America','jp': '南米' },
  'p153_t7980': { 'zh-cn':'哥伦比亚', 'en': 'Colombia','jp': 'コロンビア' },
  'p153_t7990': { 'zh-cn':'委内瑞拉', 'en': 'Venezuela','jp': 'ベネズエラ' },
  'p153_t8000': { 'zh-cn':'圭亚那', 'en': 'Guyana','jp': 'ガイアナ' },
  'p153_t8010': { 'zh-cn':'苏里南', 'en': 'Suriname','jp': 'スリナム' },
  'p153_t8020': { 'zh-cn':'厄瓜多尔', 'en': 'Ecuador','jp': 'エクアドル' },
  'p153_t8030': { 'zh-cn':'秘鲁', 'en': 'Peru','jp': 'ペルー' },
  'p153_t8040': { 'zh-cn':'巴西', 'en': 'Brazil','jp': 'ブラジル' },
  'p153_t8050': { 'zh-cn':'玻利维亚', 'en': 'Bolivia','jp': 'ボリビア' },
  'p153_t8060': { 'zh-cn':'智利', 'en': 'Chile','jp': 'チリ' },
  'p153_t8070': { 'zh-cn':'巴拉圭', 'en': 'Paraguay','jp': 'パラグアイ' },
  'p153_t8080': { 'zh-cn':'乌拉圭', 'en': 'Uruguay','jp': 'ウルグアイ' },
  'p153_t8090': { 'zh-cn':'阿根廷', 'en': 'Argentina','jp': 'アルゼンチン' },
  'p153_t8100': { 'zh-cn':'法属圭亚那', 'en': 'French Guiana','jp': 'フランス領ガイアナ' },
  'p153_t8110': { 'zh-cn':'其他', 'en': 'Others','jp': 'その他' },
  'p153_t8120': { 'zh-cn':'北美洲', 'en': 'North America','jp': '北アメリカ' },
  'p153_t8130': { 'zh-cn':'美国', 'en': 'United States','jp': 'アメリカ' },
  'p153_t8140': { 'zh-cn':'加拿大', 'en': 'Canada','jp': 'カナダ' },
  'p153_t8150': { 'zh-cn':'墨西哥', 'en': 'Mexico','jp': 'メキシコ' },
  'p153_t8160': { 'zh-cn':'危地马拉', 'en': 'Guatemala','jp': 'グアテマラ' },
  'p153_t8170': { 'zh-cn':'伯利兹', 'en': 'Belize ','jp': 'ベリーズ' },
  'p153_t8180': { 'zh-cn':'萨尔瓦多', 'en': 'El Salvador','jp': 'エルサルバドル' },
  'p153_t8190': { 'zh-cn':'洪都拉斯', 'en': 'Honduras','jp': 'ホンジュラス' },
  'p153_t8200': { 'zh-cn':'尼加拉瓜', 'en': 'Nicaragua','jp': 'ニカラグア' },
  'p153_t8210': { 'zh-cn':'哥斯达黎加', 'en': 'Costa Rica','jp': 'コスタリカ' },
  'p153_t8220': { 'zh-cn':'巴拿马', 'en': 'Panama','jp': 'パナマ' },
  'p153_t8230': { 'zh-cn':'巴哈马', 'en': 'Bahamas','jp': 'バハマ' },
  'p153_t8240': { 'zh-cn':'古巴', 'en': 'Cuba','jp': 'キューバ' },
  'p153_t8250': { 'zh-cn':'牙买加', 'en': 'Jamaica','jp': 'ジャマイカ' },
  'p153_t8260': { 'zh-cn':'海地', 'en': 'Haiti','jp': 'ハイチ' },
  'p153_t8270': { 'zh-cn':'多米尼加共和国', 'en': 'Dominican Republic','jp': 'ドミニカ共和国' },
  'p153_t8280': { 'zh-cn':'圣基茨和尼维斯', 'en': 'Saint Kitts and Nevis','jp': 'サンキッツとネビス' },
  'p153_t8290': { 'zh-cn':'安提瓜和巴布达', 'en': 'Antigua and Barbuda ','jp': 'アンティグアとバブダ' },
  'p153_t8300': { 'zh-cn':'多米尼克', 'en': 'Dominica','jp': 'ドミニク' },
  'p153_t8310': { 'zh-cn':'圣卢西亚', 'en': 'Saint Lucia ','jp': 'セントルシア' },
  'p153_t8320': { 'zh-cn':'圣文森特和格林纳丁斯', 'en': 'Saint Vincent and the Grenadines','jp': 'セントビンセント・グレナディーン' },
  'p153_t8330': { 'zh-cn':'巴巴多斯', 'en': 'Barbados','jp': 'バルバドス' },
  'p153_t8340': { 'zh-cn':'格林纳达', 'en': 'Grenada','jp': 'グレナダ' },
  'p153_t8350': { 'zh-cn':'特立尼达和多巴哥', 'en': 'Trinidad and Tobago ','jp': 'トリニダード・トバゴ' },
  'p153_t8360': { 'zh-cn':'其他', 'en': 'Others','jp': 'その他' },
  'p153_t8370': { 'zh-cn':'非洲', 'en': 'Africa','jp': 'アフリカ' },
  'p153_t8380': { 'zh-cn':'埃及', 'en': 'Egypt','jp': 'エジプト' },
  'p153_t8390': { 'zh-cn':'苏丹', 'en': 'Sudan','jp': 'スーダン' },
  'p153_t8400': { 'zh-cn':'南苏丹', 'en': 'South Sudan','jp': '南スーダン' },
  'p153_t8410': { 'zh-cn':'利比亚', 'en': 'Libya','jp': 'リビア' },
  'p153_t8420': { 'zh-cn':'突尼斯', 'en': 'Tunisia','jp': 'チュニジア' },
  'p153_t8430': { 'zh-cn':'阿尔及利亚', 'en': 'Algeria','jp': 'アルジェリア' },
  'p153_t8440': { 'zh-cn':'摩洛哥', 'en': 'Morocco','jp': 'モロッコ' },
  'p153_t8450': { 'zh-cn':'毛里塔尼亚', 'en': 'Mauritania','jp': 'モーリタニア' },
  'p153_t8460': { 'zh-cn':'塞内加尔', 'en': 'Senegal','jp': 'セネガル' },
  'p153_t8470': { 'zh-cn':'冈比亚', 'en': 'Gambia','jp': 'ガンビア' },
  'p153_t8480': { 'zh-cn':'马里', 'en': 'Mali','jp': 'マリ' },
  'p153_t8490': { 'zh-cn':'布基纳法索', 'en': 'Burkina faso ','jp': 'ブルキナファソ' },
  'p153_t8500': { 'zh-cn':'几内亚', 'en': 'Guinea','jp': 'ギニア' },
  'p153_t8510': { 'zh-cn':'几内亚比绍', 'en': 'Guinea-Bissau','jp': 'ギニアビサウ' },
  'p153_t8520': { 'zh-cn':'佛得角', 'en': 'Cape Verde','jp': 'カーボベルデ角' },
  'p153_t8530': { 'zh-cn':'塞拉利昂', 'en': 'sierra leone','jp': 'シエラレオネ' },
  'p153_t8540': { 'zh-cn':'利比里亚', 'en': 'Liberia','jp': 'リベリア' },
  'p153_t8550': { 'zh-cn':'科特迪瓦', 'en': 'Cote d\'Ivoire','jp': 'コートジボワール' },
  'p153_t8560': { 'zh-cn':'加纳', 'en': 'Ghana','jp': 'ガーナ' },
  'p153_t8570': { 'zh-cn':'多哥', 'en': 'Togo','jp': 'トーゴ' },
  'p153_t8580': { 'zh-cn':'贝宁', 'en': 'Benin','jp': 'ベニン' },
  'p153_t8590': { 'zh-cn':'尼日尔', 'en': 'Niger','jp': 'ニジェール' },
  'p153_t8600': { 'zh-cn':'尼日利亚', 'en': 'Nigeria','jp': 'ナイジェリア' },
  'p153_t8610': { 'zh-cn':'乍得', 'en': 'Chad','jp': 'チャド' },
  'p153_t8620': { 'zh-cn':'中非共和国', 'en': 'Central African Republic','jp': '中央アフリカ共和国' },
  'p153_t8630': { 'zh-cn':'喀麦隆', 'en': 'Cameroon','jp': 'カメルーン' },
  'p153_t8640': { 'zh-cn':'赤道几内亚', 'en': 'Equatorial Guinea','jp': '赤道ギニア' },
  'p153_t8650': { 'zh-cn':'加蓬', 'en': 'Gabon','jp': 'ガボン' },
  'p153_t8660': { 'zh-cn':'刚果（布）', 'en': 'Congo (Brazzaville)','jp': 'コンゴ（布）' },
  'p153_t8670': { 'zh-cn':'刚果（金）', 'en': 'Democratic Republic of Congo','jp': 'コンゴ（金）' },
  'p153_t8680': { 'zh-cn':'圣多美和普林西比', 'en': 'Sao Tome and Principe','jp': 'サントメプリンシペ' },
  'p153_t8690': { 'zh-cn':'安哥拉', 'en': 'Angola','jp': 'アンゴラ' },
  'p153_t8700': { 'zh-cn':'赞比亚', 'en': 'Zambia','jp': 'ザンビア' },
  'p153_t8710': { 'zh-cn':'马拉维', 'en': 'Malawi','jp': 'マラウイ' },
  'p153_t8720': { 'zh-cn':'莫桑比克', 'en': 'Mozambique','jp': 'モザンビーク' },
  'p153_t8730': { 'zh-cn':'博茨瓦纳', 'en': 'Botswana','jp': 'ボツワナ' },
  'p153_t8740': { 'zh-cn':'纳米比亚', 'en': 'Namibia','jp': 'ナミビア' },
  'p153_t8750': { 'zh-cn':'南非共和国', 'en': 'Republic of South Africa','jp': '南アフリカ共和国' },
  'p153_t8760': { 'zh-cn':'斯威士兰', 'en': 'Eswatini','jp': 'スワジランド' },
  'p153_t8770': { 'zh-cn':'莱索托', 'en': 'Lesotho','jp': 'レソト' },
  'p153_t8780': { 'zh-cn':'马达加斯加', 'en': 'Madagascar','jp': 'マダガスカル' },
  'p153_t8790': { 'zh-cn':'科摩罗', 'en': 'Comoros','jp': 'コモロ' },
  'p153_t8800': { 'zh-cn':'毛里求斯', 'en': 'Mauritius','jp': 'モーリシャス' },
  'p153_t8810': { 'zh-cn':'埃塞俄比亚', 'en': 'Ethiopia','jp': 'エチオピア' },
  'p153_t8820': { 'zh-cn':'厄立特里亚', 'en': 'Eritrea','jp': 'エリトリア' },
  'p153_t8830': { 'zh-cn':'索马里', 'en': 'Somalia','jp': 'ソマリア' },
  'p153_t8840': { 'zh-cn':'吉布提', 'en': 'Djibouti','jp': 'ジブチ' },
  'p153_t8850': { 'zh-cn':'肯尼亚', 'en': 'Kenya','jp': 'ケニア' },
  'p153_t8860': { 'zh-cn':'坦桑尼亚', 'en': 'Tanzania','jp': 'タンザニア' },
  'p153_t8870': { 'zh-cn':'乌干达', 'en': 'Uganda','jp': 'ウガンダ' },
  'p153_t8880': { 'zh-cn':'卢旺达', 'en': 'Rwanda','jp': 'ルワンダ' },
  'p153_t8890': { 'zh-cn':'布隆迪', 'en': 'Burundi','jp': 'ブルンジ' },
  'p153_t8900': { 'zh-cn':'塞舌尔', 'en': 'Seychelles','jp': 'セーシェル' },
  'p153_t8910': { 'zh-cn':'圣赫勒拿岛', 'en': 'Saint Helena Island','jp': 'セントヘレナ島' },
  'p153_t8920': { 'zh-cn':'其他', 'en': 'Others','jp': 'その他' },
  'p153_t8930': { 'zh-cn':'大洋洲', 'en': 'Oceania','jp': 'オセアニア' },
  'p153_t8940': { 'zh-cn':'澳大利亚', 'en': 'Australia','jp': 'オーストラリア' },
  'p153_t8950': { 'zh-cn':'新西兰', 'en': 'New Zealand','jp': 'ニュージーランド' },
  'p153_t8960': { 'zh-cn':'巴布亚新几内亚', 'en': 'papua new guinea','jp': 'パプアニューギニア' },
  'p153_t8970': { 'zh-cn':'所罗门群岛', 'en': 'Solomon Islands','jp': 'ソロモン諸島' },
  'p153_t8980': { 'zh-cn':'瓦努阿图', 'en': 'Vanuatu','jp': 'バヌアツ' },
  'p153_t8990': { 'zh-cn':'帕劳', 'en': 'Palau','jp': 'パラオ' },
  'p153_t9000': { 'zh-cn':'斐济', 'en': 'Fiji','jp': 'フィジー' },
  'p153_t9010': { 'zh-cn':'密克罗尼西亚联邦', 'en': 'Federated States of Micronesia','jp': 'ミクロネシア連邦' },
  'p153_t9020': { 'zh-cn':'马绍尔群岛', 'en': 'Marshall Islands','jp': 'マーシャル諸島' },
  'p153_t9030': { 'zh-cn':'基里巴斯', 'en': 'Kiribati','jp': 'キリバス' },
  'p153_t9040': { 'zh-cn':'瑙鲁', 'en': 'Nauru','jp': 'ナウル' },
  'p153_t9050': { 'zh-cn':'萨摩亚', 'en': 'Samoa','jp': 'サモア' },
  'p153_t9060': { 'zh-cn':'汤加', 'en': 'Tonga','jp': 'トンガ' },
  'p153_t9070': { 'zh-cn':'图瓦卢', 'en': 'Tuvalu','jp': 'ツバル' },
  'p153_t9080': { 'zh-cn':'库克群岛', 'en': 'Cook Islands','jp': 'クック諸島' },
  'p153_t9090': { 'zh-cn':'纽埃', 'en': 'Niue','jp': 'ニウエ' },
  'p153_t9100': { 'zh-cn':'皮特凯恩群岛', 'en': 'Pitcairn Islands','jp': 'ピトケイン諸島' },
  'p153_t9110': { 'zh-cn':'托克劳', 'en': 'Tokelau ','jp': 'トクロ' },
  'p153_t9120': { 'zh-cn':'其他', 'en': 'Others','jp': 'その他' },



  'p154_t1010': { 'zh-cn':'采访', 'en': 'Interview ','jp': 'インタビュー' },
  'p154_t1011': { 'zh-cn': '请先登录', 'en': 'Please Login First', 'jp': 'まずログインしてください' },

  'p154_t1012': { 'zh-cn': '向优秀领导学习。从成功的活动中获得见解和策略，帮助您在赞助领域保持领先地位。', 'en': 'Learn from top leaders. Gain insights and strategies from successful campaigns, helping you stay ahead of the curve in the world of sponsorship.', 'jp': 'スポンサーシップのトップリーダーから学び、成功事例から得た洞察と戦略を活かして、スポンサーシップの世界で一歩先を進む。' },
  'p154_t1013': { 'zh-cn': '文章 | 采访', 'en': 'Articles & Interviews', 'jp': '記事・インタビュー' },


  'p154_t1020': { 'zh-cn':'文章', 'en': 'Article','jp': '文章' },
  'p154_t1030': { 'zh-cn': '联系平台', 'en': 'Contact Us', 'jp': '連絡先' },
  'p154_t1031': { 'zh-cn': '联络我们', 'en': 'Get in touch', 'jp': 'Get in touch' },
  'p154_t1032': { 'zh-cn': '如果您有意与受访者进行业务洽谈，请随时留言。我们随时准备为您提供帮助，促成有意义的对话。', 'en': 'If you are interested in establishing a connection with the interviewee for business discussions, please don’t hesitate to leave a message. We are ready to assist with any inquiries or requests to facilitate meaningful conversations.', 'jp': 'インタビュー対象者とのビジネスに関するご相談をご希望の場合は、ぜひメッセージをお送りください。円滑な対話を促進するために、積極的にお問い合わせに対応いたします。' },
  'p154_t1033': { 'zh-cn': '点击留言', 'en': 'Leave a Message', 'jp': 'メッセージを送る' },
  'p154_t1034': { 'zh-cn': '让我们开始对话吧！', 'en': 'Let’s Get the Conversation Started!', 'jp': 'Let’s Get the Conversation Started!' },
  'p154_t1035': { 'zh-cn': '输入您的姓名、电子邮件并写下您的留言。', 'en': 'Enter your name, email and message.', 'jp': 'お名前、メールアドレス、及びメッセージをご記入した上でご送信ください' },
  'p154_t1036': { 'zh-cn': '发送消息', 'en': 'Send Message', 'jp': 'メッセージを送る' },
  'p154_t1037': { 'zh-cn': '感谢您的留言！我们将尽快与您联系。', 'en': 'Thank you for your message! We will contact you shortly.', 'jp': 'メッセージをお送りいただきありがとうございます。追ってプラットフォームよりご連絡いたします。' },
  

  'p154_t1040': { 'zh-cn': '消息', 'en': 'Message', 'jp': 'メッセージ' },

  'p154_t1090': { 'zh-cn': '采访', 'en': 'Interview', 'jp': '取材' },
  'p154_t1091': { 'zh-cn': '新闻', 'en': 'News', 'jp': 'ニュース' },
  'p154_t1092': { 'zh-cn': '观点', 'en': 'Viewpoint', 'jp': '視点' },
  'p154_t1099': { 'zh-cn': '其他', 'en': 'Others', 'jp': 'その他' },




}