<template>
  <div>
    <!-- 最外层 -->
    <el-menu
      v-if="menuData.length && menuData[0].root"
      class="el-menu-demo"
      :default-active="defaultActive"
      :background-color="backgroundColor"
      :text-color="textColor"
      :collapse="collapse"
      :active-text-color="activeTextColor"
    >
      <template v-for="item in menuData">
        <template
          v-if="
            item.children &&
            item.children.length &&
            item.children.length !== 1 &&
            !item.meta.hidden
          "
        >
          <el-sub-menu :key="item.id" :index="item.path" :popper-class="popperClass">
            <template #title>
              <el-icon>
                <component :is="item.meta.icon" />
              </el-icon>
              <span class="menu-title">{{ item.meta.title }}</span>
            </template>
            <menu-render :menu-data="item.children" />
          </el-sub-menu>
        </template>
        <template v-else>
          <router-link
            v-if="!item.meta.hidden"
            :key="item.id"
            :to="item.redirect || item.path"
          >
            <el-menu-item :index="item.redirect || item.path">
              <el-icon>
                <component :is="item.meta.icon" />
              </el-icon>
              <template #title>
                <span class="menu-title">{{ item.meta.title }}</span>
                <el-badge
                  v-if="item.name === 'myNotification' || item.name === 'myMessage'"
                  is-dot
                  :value="count[item.name]"
                  :max="99"
                  :hidden="count[item.name] === 0"
                >
                  <div></div>
                </el-badge>
              </template>
            </el-menu-item>
          </router-link>
        </template>
      </template>
    </el-menu>
    <!-- 内层 -->
    <template v-for="item in menuData" v-else>
      <template
        v-if="item.children && item.children.length && item.children.length !== 1"
      >
        <el-sub-menu :key="item.id" :index="item.path" :popper-class="popperClass">
          <template #title>
            <el-icon :size="20">
              <el-icon>
                <component :is="item.meta.icon" />
              </el-icon>
            </el-icon>
            <span class="menu-title">{{ item.meta.title }}</span>
          </template>
          <menu-render :menu-data="item.children" />
        </el-sub-menu>
      </template>
      <template v-else>
        <router-link
          v-if="!item.meta.hidden"
          :key="item.id"
          :to="item.redirect || item.path"
        >
          <el-menu-item :index="item.redirect || item.path">
            <el-icon>
              <component :is="item.meta.icon" />
            </el-icon>
            <template #title
              ><span class="menu-title">{{ item.meta.title }}</span></template
            >
          </el-menu-item>
        </router-link>
      </template>
    </template>
  </div>
</template>

<script>
// import { getNotifyUnreadCount, queryUnreadMessageCount } from '@/api/portal'
import { portalApi } from '@/api/all'
import { ref, onMounted, onUnmounted, watchEffect } from 'vue'
import { useUserStore } from '@/store/user'

export default {
  name: 'MenuRender',
  props: {
    menuData: {
      default: () => {
        return []
      },
      type: Array,
    },
    backgroundColor: {
      default: '#fff',
      type: String,
    },
    textColor: {
      default: '#303133',
      type: String,
    },
    activeTextColor: {
      default: '#409EFF',
      type: String,
    },
    collapse: {
      default: false,
      type: Boolean,
    },
    defaultActive: {
      default: '1',
      type: String,
    },
    popperClass: {
      default: 'sidebar-popup',
      type: String,
    },
  },
  setup () {
    const userStore = useUserStore()

    const count = ref({
      myNotification: 0,
      myMessage: 0,
    })
    let timer = -1

    // 获取通知未读数
    async function getUnreadNotitication () {
      let res = await portalApi.getNotifyUnreadCount()
      // console.log('getUnreadNotitication', res)
      count.value.myNotification = res.result || 0
    }

    // 获取消息未读数
    function getUnreadMessage () {
      portalApi.queryUnreadMessageCount().then((res) => {
        console.log('getUnreadMessage', res)

        count.value.myMessage = res.result || 0
      })
    }

    // 开始轮询获取未读数, 1分钟一次
    function startLoop () {
      timer = setInterval(() => {
        getUnreadMessage()
        getUnreadNotitication()
      }, 60_000)
    }

    function stopLoop () {
      console.log('stop')
      clearInterval(timer)
    }

    onMounted(() => {
      getUnreadMessage()
      getUnreadNotitication()
      startLoop()
    })

    onUnmounted(() => {
      clearInterval(timer)
    })

    watchEffect(() => {
      // console.debug('@sss = ', userStore.isTokenTimeout, userStore)
      if (userStore.isTokenTimeout) {
        stopLoop()
      }
    })

    return {
      count,
    }
  },
}
</script>

<style lang="scss" scoped>
:deep(.el-badge) {
  margin-left: 20px;
}

a {
  text-decoration: none;
}
</style>
