import { createRouter, createWebHashHistory } from 'vue-router'
import menus from './menus'
import PortalLayout from '@/views/layout/portalLayout/index.vue'

const Login = () => import('@/views/user/login.vue')
const Register = () => import('@/views/user/register.vue')
const FindPassword = () => import('@/views/user/findPassword.vue')
const Active = () => import('@/views/user/active.vue')
const UCPW = () => import('@/views/user/userChangePassByCode.vue')

const routes = [
  {
    path: '/',
    name: 'root',
    redirect: '/portal',
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
  },
  {
    path: '/findPassword',
    name: 'findPassword',
    component: FindPassword,
  },
  {
    path: '/active',
    name: 'active',
    component: Active,
  }, 
  {
    path: '/findpass',
    name: 'findpass',
    component: UCPW,
  },
  // {
  //   path: '/file',
  //   name: 'file',
  //   component: PortalLayout,
  //   children: [
  //     {
  //       path: 'file1',
  //       component: () => import('@/views/something/file1.vue'),
  //     }
  //   ]
  // },
  ...menus
]

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes,
  scrollBehavior: () => ({ left: 0, top: 0 })
})

export default router
