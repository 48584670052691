// 注册pinia及它的持久化存储插件

import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

export default {
  install (app) {
    app.use(pinia)
  }
}
