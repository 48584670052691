<template>
  <section class="app-header">
    <section class="header-left">
      <div class="menu-control" @click="changeMenuStatus">
        <el-icon size="20">
          <component :is="'i-' + (menuSwitch ? 'expand' : 'fold')" />
        </el-icon>
      </div>
      <div class="menu-breadcrumb">
        <breadcrumb />
      </div>
    </section>
    <section class="header-center"></section>
    <section class="header-right">
      <div class="right-item i1" @click="backIndex">
        <el-button link type="primary">{{ $f("p137_t100", "返回首页") }}</el-button>
      </div>
      <div class="right-item i1">
        <el-avatar shape="square" size="small" :src="avatarUrl" class="avatar"></el-avatar>
        <el-dropdown @command="userActionClick">
          <span class="btn username">{{ userStore.userInfo.nickname || '-' }}</span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="changePsw">{{
                $f("p140_t150", "修改密码")
              }}</el-dropdown-item>
              <el-dropdown-item command="logout">{{
                $f("p140_t160", "退出登录")
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>

      <div class="lang-warp dfc">
        <el-dropdown @command="changeLang">
          <img src="@/assets/images/home/lang.png" class="btn earth-btn" />

          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                v-for="item in langs"
                :key="item.key"
                :command="item.key"
                class="ignore"
              >
                {{ item.name }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </section>
  </section>
</template>

<script setup>
import breadcrumb from '../../components/common/breadcrumb.vue'
import { useMenuSwitchInject } from './hooks/menuSwitch'
import { ref } from 'vue'
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { currencyType,langs } from '@/utils/common'

// import router from '../../router'
// import { getLocalStorage } from '@/utils/index'
// import { getAssetsImge } from '@/utils/common'
import { ElMessage } from 'element-plus'
import { useUserStore } from '@/store/user'
import avatarImg from '../../assets/images/common/avatar.png'
import { baseImgUrl, getSmallPicUrl } from '@/utils/index'
// import { useI18n } from 'vue-i18n'

const userStore = useUserStore()
const router = useRouter()
// const { t } = useI18n()
import { useFanyi } from '@/plugin/fanyi'
const { f, locale } = useFanyi()
// name: 'AppHeader',
// components: {
//   breadcrumb
// },

const userName = ref(null)
const avatarUrl = ref(null)
const userType = ref(null)
// const hasLogin = ref(
//   getLocalStorage('token') !== '' && getLocalStorage('token') !== null
// )

// 菜单折叠操作
const { menuSwitch, setMenuSwitch } = useMenuSwitchInject()
const changeMenuStatus = () => {
  setMenuSwitch(!menuSwitch.value)
}
const backIndex = () => {
  router.push('/')
}


function userActionClick (cmd) {
  if (cmd === 'logout') {
    userStore.logout()
    if(router.currentRoute.value.fullPath=='/portal/home')
    {
      window.location.reload()
    }else{
      router.push({ path: '/' })
    }    
  } else if (cmd === 'me') {
    router.push({ path: '/user' })
  } else if (cmd === 'changePsw') {
    router.push({ path: '/changePassword' })
  } else if (cmd === 'resource') {
    router.push({ path: '/resourceInfo' })
  } else if (cmd === 'demand') {
    router.push({ path: '/demandInfo' })
  }
}


// const langs = [
//   {
//     name: '日本語',
//     key: 'jp',
//   },
//   {
//     name: 'English',
//     key: 'en',
//   },
//   {
//     name: '简体中文',
//     key: 'zh-cn',
//   },
// ]

/**
 * 切换语言
 * @param {String} lang zh-cn | en | jp
 */
// eslint-disable-next-line no-unused-vars
function changeLang (lang) {
  locale.value = lang

  localStorage.setItem('lang', lang)

  window.location.reload()
}
onMounted(() => {
  // const token = useUserStore().token
  // console.log(token)
  // console.log(useUserStore().userInfo)
  // let userInfo = getLocalStorage('userInfo')
  let userInfo = userStore.userInfo
  if (!userInfo.userId) {
    ElMessage.error(f('p137_t130', '未读取到用户信息，请重新登录'))
    return
  }
  // console.log(userInfo.userId)

  let nickname = userInfo.nickname
  let ut = userInfo.userType
  userName.value = nickname
  userType.value = ut
  avatarUrl.value =
    JSON.parse(userInfo.info).headurl !== ''
      ? baseImgUrl + getSmallPicUrl(JSON.parse(userInfo.info).headurl)
      : avatarImg
})
</script>

<style lang="scss" scoped>
.app-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  line-height: 60px;
  padding: 0 20px;
  background-color: #fff;
  color: #666;
  box-shadow: 1px 2px 8px 3px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  .header-left {
    display: flex;
    ::v-deep(.el-breadcrumb) {
      margin-left: 20px;
      padding: 0;
      height: 60px;
      line-height: 60px;
    }
    .menu-control {
      display: flex;
      align-items: center;
      font-size: 20px;
      cursor: pointer;
    }
  }
  .header-right {
    display: flex;
    .right-item {
      display: inline-block;
      margin-right: 15px;
      font-size: 20px;
      cursor: pointer;
      color: #666;

      &.i1 {
        display: flex;
        align-items: center;
      }

      .user-name {
        margin-left: 4px;
      }
    }
  }
}
img {
  width: 20px;
  height: 20px;
}
.lang-warp {
  .earth-btn {
    &:focus {
      outline: none;
    }
  }
}

.username {
  cursor: pointer;

  &:focus {
    outline: none;
  }
}
.avatar{
  display: inline;
  outline: none;
  margin: 0 5px;
  // :focus {
  //     outline: none;
  //   }
}
:deep(.el-breadcrumb__inner){
  font-weight: bold !important
}
</style>
