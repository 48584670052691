import { ref } from 'vue'

let fanyiInstance = null

class Fanyi {
  locale = ref(null)
  message = ref(null)

  constructor ({ locale, message }) {
    this.locale.value = locale
    this.message.value = message
  }

  f (key, textcn) {
    // console.debug('@this = ', this, key, textcn)
    const lang = this.message.value[key]
    if (!lang || !lang[this.locale.value] || lang['zh-cn'] !== textcn) {

      return '[' + textcn + ']'
    }

    return lang[this.locale.value]
  }
}

/**
 * 创建翻译实例
 * 
 * @param { string } options.locale 语言: zh-cn | en | jp
 * @param { object } options.message 语言包 格式如下
 * 
 * {
 *   key1: { 'zh-cn': '中文', 'en': 'English', jp: '日本語' },
 *   key2: { 'zh-cn': '中文2', 'en': 'English2', jp: '日本語2' },
 * }
 */
export function createFanyi (options) {
  console.debug('@fanyi options = ', options)
  console.debug('@fanyi navigator.language = ', navigator.language)

  if(!options.locale)
  {
    if(navigator.language.startsWith('zh')) {
      options.locale='zh-cn'
    } else if(navigator.language.startsWith('ja')) {
      options.locale='jp'
    } else{
      options.locale='en'
    }
  }

  if (fanyiInstance) return fanyiInstance

  const instance = new Fanyi(options)

  // createFanyi 上挂载install方法
  instance.install = function (app) {
    app.config.globalProperties.$f = instance.f.bind(instance)
  }

  fanyiInstance = instance

  return instance
}

export function useFanyi () {
  return {
    f: fanyiInstance.f.bind(fanyiInstance),
    locale: fanyiInstance.locale
  }
}