import { post } from '@/utils/request.js'

export const commonApi={
  upload:(data) => {
    return post('/common/upload', data)
  },

  translate:(data) => {
    return post('/common/translate', data)
  },
  translateActivityNames:(data) => {
    return post('/common/translateActivityNames', data)
  },

  getLang:(data) => {
    return post('/common/getLang', data)
  },
}

export const portalApi = {
  queryResourceByTypes: (data) => {
    return post('/resource/queryResourceByTypes', data)
  },

  queryResourceAndPackageEntities: (data) => {
    return post(
      '/resource/queryResourceAndPackageEntities',
      data
    )
  },

  queryResourcePackageEs: (data = {}) => {
    return post(
      '/resource/queryResourcePackageEs',
      data
    )
  },


  queryResourceEs: (data = {}) => {
    return post(
      '/resource/queryResourceEs',
      data
    )
  },

  recommendResource: (data = {}) => {
    return post(
      '/resource/recommendResource',
      data
    )
  },

  queryResourcePackageActivityByResourceId: (data) => {
    return post(
      '/resource/queryResourcePackageActivityByResourceId',
      data
    )
  },
  querySeekingEs: (data) => {
    return post(
      '/seeking/querySeekingEs',
      data
    )
  },
  sendIntention: (data) => {
    return post(
      '/intention/sendIntention',
      data
    )
  },

  queryMyOwnIntention: (data) => {
    return post(
      '/intention/queryMyOwnIntention',
      data
    )
  },

  addToMyFavoriteResource: (data) => {
    return post(
      '/resourceCollection/addToMyFavorite',
      data
    )
  },

  deleteMyFavoriteResource: (data) => {
    return post(
      '/resourceCollection/delete',
      data
    )
  },

  checkIsExistedResource: (data) => {
    return post(
      '/resourceCollection/checkIsExisted',
      data
    )
  },

  queryMyFavoriteResource: (data) => {
    return post(
      '/resourceCollection/queryMyResourceCollection',
      data
    )
  },

  addToMyFavoriteSeeking: (data) => {
    return post(
      '/seekingCollection/addToMyFavorite',
      data
    )
  },

  deleteMyFavoriteSeeking: (data) => {
    return post(
      '/seekingCollection/delete',
      data
    )
  },

  checkIsExistedSeeking: (data) => {
    return post(
      '/seekingCollection/checkIsExisted',
      data
    )
  },

  queryMyFavoriteSeeking: (data) => {
    return post(
      '/seekingCollection/queryMySeekingCollection',
      data
    )
  },

  querySentMessage: (data) => {
    return post(
      '/userMessage/querySent',
      data
    )
  },

  queryReceivedMessage: (data) => {
    return post(
      '/userMessage/queryReceived',
      data
    )
  },

  queryMyNotification: (data) => {
    return post(
      '/notify/queryMyNotification',
      data
    )
  },

  setMyNotificationRead: (data) => {
    return post(
      '/notify/setMyNotificationRead',
      data
    )
  },

  deleteMyNotification: (data) => {
    return post(
      '/notify/deleteMyNotification',
      data
    )
  },

  // 获取通知未读数
  getNotifyUnreadCount (data) {
    return post(
      '/notify/myUnreadCount',
      data
    )
  },

  // 消息最近联系人
  queryLatestContact: (data) => {
    return post(
      '/userMessage/queryLatestContact',
      data
    )
  },

  // 消息历史记录
  queryMessageHistory: (data) => {
    return post(
      '/userMessage/queryMessageHistory',
      data
    )
  },
  // 消息接收历史记录
  queryMyReceivedMessage: (data) => {
    return post(
      '/userMessage/queryMyReceivedMessage',
      data
    )
  }, 
  
  // 消息发送历史记录
  queryMySentMessage: (data) => {
    return post(
      '/userMessage/queryMySentMessage',
      data
    )
  },
  // 消息未读数
  queryUnreadMessage (data) {
    return post(
      '/userMessage/queryLatestUnreadMessage',
      data
    )
  },

  // 消息未读数 角标
  queryUnreadMessageCount (data) {
    return post(
      '/userMessage/queryUnreadMessageCount',
      data
    )
  },

  // 发送消息
  sendMessage (data) {
    return post(
      '/userMessage/sendMessage',
      data
    )
  },

  // 消息设置已读
  setMessageHasRead (data) {
    return post(
      '/userMessage/setMessageHasRead',
      data
    )
  },

  // 查询用户id
  queryChatUserIdByid (data) {
    return post(
      '/intention/queryChatUserIdByid',
      data
    )
  },

}

export const userApi = {
  login: (data) => {
    return post('/user/login', data)
  },

  loginOut: (data) => {
    return post('/userInfo/logout', data)
  },

  // 获取验证码
  verifyCode: (data) => {
    return post(
      '/userInfo/getCaptcha', data
    )
  },
  // 注册
  checkUniqueUserID: (data) => {
    return post('/user/checkUniqueUserID', data)
  },
  // 注册
  checkUniqueEmail: (data) => {
    return post('/user/checkUniqueEmail', data)
  },
  // 注册
  checkUniqueNickName: (data) => {
    return post('/user/checkUniqueNickName', data)
  },
  // 注册
  register: (data) => {
    return post('/user/register', data)
  },
  getMyOwnUserInfo: (data) => {
    return post('/user/getMyOwnUserInfo', data)
  },
  // 用户信息修改
  updateUserInfo: (data) => {
    return post('/user/update', data)
  },
  changePassword: (data) => {
    return post('/user/changePassword', data)
  },

  //申请审核公司
  applyAuditCompany: (data) => {
    return post('/user/applyAuditCompany', data)
  },
  //签约协议
  signAgreement: (data) => {
    return post('/user/signAgreement', data)
  },

  //是否已经签约
  checkHasSignAgreement: (data) => {
    return post('/user/checkHasSignAgreement', data)
  },

  //找回密码
  findPassword: (data) => {
    return post('/user/findPassword', data)
  },
  //激活用户
  activeUser: (data) => {
    return post('/user/activeUser', data)
  },

  //根据code改密码
  changePasswordByCode: (data) => {
    return post('/user/changePasswordByCode', data)
  },


  checkTokenValid: (data) => {
    return post('/user/checkTokenValid', data)
  },

  queryUserResourceOrDemand: (data) => {
    return post('/user/queryUserResourceOrDemand', data)
  }

}

export const resourceApi = {

  addResourceAndPackage:  (data) => {
    return post('/resource/addResourceAndPackage',
      data)
  },
  
  updateResourceAndPackage:  (data) => {
    return post('/resource/updateResourceAndPackage',
      data)
  },

  //发布资源
  publishResource:  (data) => {
    return post('/resource/publishResource',
      data)
  },

  //撤销发布资源
  undoPublishResource:  (data) => {
    return post('/resource/undoPublishResource',
      data)
  },

  // 资源增删改查
  addResource: (data) => {
    return post('/resource/addResource',
      data)
  },
  deleteResource: (data) => {
    return post('/resource/deleteResource',
      data)
  },
  changeToUnaudited: (data) => {
    return post('/resource/changeToUnaudited',
      data)
  },
  updateResource: (data) => {
    return post('/resource/updateResource',
      data)
  },
  queryResourceAndPackageEntities: (data = {}) => {
    return post('/resource/queryResourceAndPackageEntities',
      data)
  },


  queryMyResourceAndPackage: (data) => {
    return post(
      '/resource/queryMyResourceAndPackage',
      data
    )
  },

  queryMyResource: (data) => {
    return post('/resource/queryMyResource',
      data)
  },

  // 活动包增删改查
  // addPackageActivity:(data)=> {
  //   return $post({
  //     url: '/resource/addResourcePackageActivity',
  //     data
  //   })
  // },

  savePackageActivity: (data) => {
    return post('/resource/saveResourcePackageActivity',
      data)
  },

  deleteResourcePackage: (data) => {
    return post('/resource/deleteResourcePackage',
      data)
  },
  updateResourcePackage: (data) => {
    return post('/resource/updateResourcePackage',
      data)
  },
  queryResourcePackage: (data) => {
    return post('/resource/queryResourcePackageActivityByResourceId',
      data)
  },
  // 活动增删改
  // addActivity:(data)=> {
  //   return $post({
  //     url: '/resource/addActivity',
  //     data
  //   })
  // },
  // deleteActivity:(data)=> {
  //   return $post({
  //     url: '/resource/deleteActivity',
  //     data
  //   })
  // },
  // updateActivity:(data)=> {
  //   return $post({
  //     url: '/resource/updateActivity',
  //     data
  //   })
  // },
  queryResourcePackageActivityByPackageId: (data) => {
    return post('/resource/queryResourcePackageActivityByPackageId',
      data)
  },

}

export const demandApi = {
  // 需求增删改查
  addSeeking: (data) => {
    return post('/seeking/addSeeking',
      data
    )
  },
  deleteSeeking: (data) => {
    return post('/seeking/delete',
      data
    )
  },
  changeToUnaudited: (data) => {
    return post('/seeking/changeToUnaudited',
      data
    )
  },
  updateSeeking: (data) => {
    return post('/seeking/update',
      data
    )
  },
  queryMySeeking: (data) => {
    return post('/seeking/queryMySeeking',
      data
    )
  },

  publishSeeking: (data) => {
    return post('/seeking/publishSeeking',
      data
    )
  },

  undoPublishSeeking: (data) => {
    return post('/seeking/undoPublishSeeking',
      data
    )
  },
}

// 话题
export const postingApi = {
  addPosting: (data) => {
    return post('/posting/addPosting', data)
  },
  addDraft: (data) => {
    return post('/posting/addDraft', data)
  },
  draftSubmit: (data) => {
    return post('/posting/submit', data)
  },
  updatePosting: (data) => {
    return post('/posting/updatePosting', data)
  },
  getPostingList: (data) => {
    return post('/posting/queryPostingList', data)
  },
  getPostingDetailList: (data) => {
    return post('/posting/queryPostingDetailList', data)
  },
  queryPostingDetail: (data) => {
    return post('/posting/queryPostingDetail', data)
  },
  addComment: (data) => {
    return post('/postingComment/addComment', data)
  },
  queryComment: (data) => {
    return post('/postingComment/query', data)
  },
  queryMineList: (data) => {
    return post('/posting/queryMine', data)
  },
  deleteMine: (data) => {
    return post('/posting/deleteMine', data)
  },
  // 点赞
  recommend: (data) => {
    return post('/posting/recommend', data)
  },
  disrecommend: (data) => {
    return post('/posting/disrecommend', data)
  },
  //解析url
  analyzeUrl:(data) => {
    return post('/posting/analyzeUrl', data)
  }
}


// 话题
export const articleApi = {
  query: (data) => {
    return post('/article/query', data)
  },

  queryDetailByArticleId: (data) => {
    return post('/article/queryDetailByArticleId', data)
  },

  recommendArticle: (data) => {
    return post('/article/recommendArticle', data)
  },

  disrecommendArticle: (data) => {
    return post('/article/disrecommendArticle', data)
  },
  addComment: (data) => {
    return post('/articleComment/addComment', data)
  },
  articleCommentQuery: (data) => {
    return post('/articleComment/query', data)
  },
  contactPlatform: (data) => {
    return post('/articleComment/contactPlatform', data)
  },
}


export const commissionAgreementApi = {
  add: (data) => {
    return post('/commissionAgreement/add', data)
  },
  query: (data) => {
    return post('/commissionAgreement/query', data)
  },
  checkHasSigned: (data) => {
    return post('/commissionAgreement/checkHasSigned', data)
  },
}
