<template>
  <el-config-provider :locale="elLocale">
    <router-view />
  </el-config-provider>
</template>

<script setup>
import { ref,onMounted } from 'vue'
import { useFanyi } from '@/plugin/fanyi'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import en from 'element-plus/dist/locale/en.mjs'
import ja from 'element-plus/dist/locale/ja.mjs'



const langMap = {
  'zh-cn': zhCn,
  'en': en,
  'jp': ja
}

const { locale } = useFanyi()

const elLocale = ref(langMap[locale.value] || ja)

document.body.className = locale.value


// onMounted(() => {
//   console.log('App start')
//   useMeta({
//     title: 'sdsdsdsdsdsds',
//     meta: [
//       { property: 'og:title', content: 'sdsdsdsdsdsds' },
//       { property: 'og:description', content: 'sdsdsdsdsdsds' },
//       { property: 'og:image', content: 'sdsdsdsdsdsds' },
//     ],
//   })
// })
</script>
