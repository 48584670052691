<template>
  <footer class="footer">
    <div class="div1">
      <div
          style="
          width:500px;
          cursor: pointer;
          margin-top: 50px;
          display: flex;
          justify-content: space-between;
        "
      >
        <span @click="gotoAboutus">{{ $f("p048_t100", "关于我们") }}</span>
        <!-- <span>{{ $f('p048_t110', '常见问题') }}</span>
        <span>{{ $f('p048_t120', '商业合作') }}</span>
        <span>{{ $f('p048_t140', '用户协议') }}</span> -->

        <span @click="gotoUseragreement">{{ $f("p048_t101", "用户协议") }}</span>
        <!-- <span>{{ $f("p048_t102", "免责事项") }}</span> -->
        <span  @click="gotoPrivacy">{{ $f("p048_t103", "隐私政策") }}</span>
        <!-- <span>{{ $f("p048_t104", "广告合作") }}</span> -->
        <span @click="gotoFAQ">{{ $f("p048_t110", "常见问题") }}</span>
      </div>

      <div style="width: 850px; cursor: pointer; margin-top: 50px">
        <span style="width: 850px"
        >{{ $f("p048_t106", "联系我们") }}：service@sponsorforce.net</span
        >
      </div>
      <div style="width: 90%; cursor: pointer; margin-top: 50px">
        <img
            src="@/assets/images/home/logo-t-white.png"
            alt="logo"
            style="width: 250px"
        />
      </div>

      <!-- <div style="width: 100%;text-align: center;margin-top:-40px;" >© 2024 力赞体育 版权所有 沪ICP备20004503号-2</div> -->
      <div style="position: absolute; right: 50px; top: 40px">
        <el-popover placement="bottom" trigger="click" width="auto">
          <template #reference>
            <img
                style="width: 50px; margin: 5px;cursor:pointer;"
                src="@/assets/images/home/weixin.png"
            />
          </template>
          <div style="width: 100%;text-align: center;"> <img style="width: 100px;" src="@/assets/images/home/qrcode.jpg" /></div>
        </el-popover>

        <a href="https://www.linkedin.com/company/sponsorforce"
           target="_blank"
        ><img style="width: 50px; margin: 5px"
              src="@/assets/images/home/linkin.png"
        /></a>
      </div>
      <!--<div style="position:absolute;right:50px;bottom:20px;" ><img style="height: 60px;" src="@/assets/images/home/mz.png" /></div> -->
    </div>
  </footer>
</template>

<script setup>
import { useRouter } from 'vue-router'

const router = useRouter()


function gotoAboutus () {
  router.push({ path:'/portal/about' } )
}

function gotoUseragreement () {
  window.open(window.location.origin + '/#/portal/useragreement')
}
function gotoPrivacy () {
  window.open(window.location.origin + '/#/portal/privacy')
}

function gotoFAQ () {
  window.open(window.location.origin + '/#/portal/FAQ')
}
</script>

<style lang="scss" scoped>
.footer {
  height: 300px;
  background-color: $primaryColor;
  display: flex;
  justify-content: center;
  font-size: 18px;
  // align-items: center;
  color: #fff;

  .div1 {
      // background-color: aqua;
      width: 85%;
      position: relative;
  
      span {
        // width: 20%;
        display: inline-block;
        // padding: 10px;
        // background-color: #f2f2f2;
        font-size: 16px;
        margin-right: 15px;
      }
    }
}
</style>
