<template>
  <section class="app-main">
    <app-sidebar />
    <el-container direction="vertical" class="app-content">
      <app-header />
      <el-main class="app-center">
        <router-view></router-view>
      </el-main>
      <div />
    </el-container>
  </section>
</template>

<script>
import AppHeader from './appHeader.vue'
import AppSidebar from './appSidebar.vue'
import { useMenuSwitchProvide } from './hooks/menuSwitch'
export default {
  name: 'AppContent',
  components: {
    AppHeader,
    AppSidebar,
  },
  setup () {
    useMenuSwitchProvide() // 菜单hooks
  },
}
</script>

<style lang="scss" scoped>
.app-main {
  display: flex;
  width: 100%;
  // height: 100%;
  min-height: 100%;
  padding: 0;
  /*background: linear-gradient(270deg, #04316a, #02142d);*/
  .app-center{
    // height: auto;
    overflow-x:hidden
  }
}
</style>
