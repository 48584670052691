/*  basic function  */
import globalConfig from '@/config'
import { storage } from '@/utils/storage'
// 个位数转化为'0x'形式
export const singleAddZero = (num) => {
  return num < 10 ? '0' + num : num
}
// 生成随机字符串
export const randomString = (len = 32) => {
  const $chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz123456789'
  const maxPos = $chars.length
  let pwd = ''
  for (let i = 0; i < len; i++) {
    pwd += $chars.charAt(Math.floor(Math.random() * maxPos))
  }
  return pwd
}
// sessionStorage封装
export const setSessionStorage = (key, data) => {
  let storage = window.sessionStorage
  let newKey = globalConfig.appName + key
  let myData = data
  if (typeof myData === 'object' || typeof myData === 'function') {
    storage.setItem(newKey, JSON.stringify(myData))
  } else {
    storage.setItem(newKey, myData)
  }
  return myData
}

// localStorage封装
export const getLocalStorage = (key) => {
  let storage = window.localStorage
  let newKey = globalConfig.appName + '_' + key
  return storage.getItem(newKey)
}
// localStorage封装
export const setLocalStorage = (key, data) => {
  let storage = window.localStorage
  let newKey = globalConfig.appName + '_' + key
  let myData = data
  if (typeof myData === 'object' || typeof myData === 'function') {
    storage.setItem(newKey, JSON.stringify(myData))
  } else {
    storage.setItem(newKey, myData)
  }
  return myData
}

// 全局formatter方法(常量转换)
export const constFormatter = (constant, val) => {
  let res = ''
  constant.forEach((item) => {
    if (item.value === val) {
      res = item.label
    }
  })
  return res
}

// 获取用户信息
export const getUserInfo = () => {
  return getLocalStorage('userInfo')
    ? JSON.parse(getLocalStorage('userInfo'))
    : {}
}

// 将el-icon的组件名称AbbbCddd转化为i-abbb-cddd形式
// 例：Switch转换为i-switch，ArrowDownBold转换为i-arrow-down-bold
export function transElIconName (iconName) {
  return 'i' + iconName.replace(/[A-Z]/g, (match) => '-' + match.toLowerCase())
}

/**
 * 获取传入时间与现在的相对时长
 * @param {Object} stringTime yyyy-MM-dd hh:mm:ss
 */
export function getDateBeforeNow (stringTime, suffix = '前') {
  // 统一单位换算
  const second = 1000
  const minute = second * 60
  const hour = minute * 60
  const day = hour * 24
  const week = day * 7
  const month = day * 30
  const year = month * 12

  const now = new Date().getTime() //当前的时间戳

  // 对时间进行毫秒单位转换
  const beforeTime = new Date(stringTime).getTime() //指定时间的时间戳

  const time = now - beforeTime

  let result = null
  if (time < 0) {
    // 传入的时间是比当前时间还大的情况
    result = stringTime
  } else if (time / year >= 1) {
    result = parseInt(time / year) + '年' + suffix
  } else if (time / month >= 1) {
    result = parseInt(time / month) + '月' + suffix
  } else if (time / week >= 1) {
    result = parseInt(time / week) + '周' + suffix
  } else if (time / day >= 1) {
    result = parseInt(time / day) + '天' + suffix
  } else if (time / hour >= 1) {
    result = parseInt(time / hour) + '小时' + suffix
  } else if (time / minute >= 1) {
    result = parseInt(time / minute) + '分钟' + suffix
  } else if (time / second >= 1) {
    result = parseInt(time / second) + '秒' + suffix
  } else {
    result = '刚刚'
  }

  return result
}

export const Base = {
  storage
}

export const baseImgUrl = 'https://sponsor-store.s3.ap-northeast-1.amazonaws.com/'

// 防抖函数
export function debounce (fn, wait = 500) {
  let timer = null
  return function () {
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      fn.apply(this, arguments)
    }, wait)
  }
}

export const getSmallPicUrl = (url) => {
  console.log(url)
  return url

  if (typeof (url) != 'string') {
    return ''
  }

  let array = url.split('.')
  if (array.length == 2) {
    console.log(array[0] + '_small.' + array[1])
    return array[0] + '_small.' + array[1]
  }
  else {
    return url
  }
}

/**
 * 自动滚动元素
 * @param {HTMLElement} ele 元素
 * @param {Number} step 步进的像素，默认3px
 * @param {Number} ms 轮询的时间，单位ms，默认100ms
 * @returns setInterval的timer
 */
export function intervalElement (ele, step = 3, ms = 100) {
  // 保证第二次滚动的时候从本来位置开始
  let scrollLeftNum = ele.scrollLeft || 0

  // 拿到元素后，对元素进行定时增加距离顶部距离，实现滚动效果(此配置为每100毫秒移动1像素)
  return setInterval(() => {
    scrollLeftNum += step
    ele.scrollLeft = scrollLeftNum

    // 判断元素是否滚动到底部(可视高度+距离顶部=整个高度)
    if (Math.ceil(ele.clientWidth + ele.scrollLeft) + 1 >= ele.scrollWidth - 1) {
      // 重置table距离顶部距离
      scrollLeftNum = 0
      ele.scrollLeft = 0
    }
  }, ms)
}