<template>
  <section class="ui-breadcrumb">
    <el-breadcrumb :separator="separator">
      <template v-for="(item, index) in breadList">
        <template v-if="hasTitle(item.meta)">
          <el-breadcrumb-item :key="index" :to="{ path: item.path }">【{{
            item.meta.title
          }}】</el-breadcrumb-item>
        </template>
      </template>
    </el-breadcrumb>
  </section>
</template>
<script>
import { onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
export default {
  name: 'Breadcrumb',
  props: {
    separator: {
      type: String,
      default: '/',
    },
  },
  setup () {
    const route = useRoute()
    let breadList = ref([])
    let fullPath = ref('')
    watch(route, (to) => {
      breadList.value = to.matched
      fullPath.value = to.fullPath
    })
    onMounted(() => {
      breadList.value = route.matched
      fullPath.value = route.fullPath
    })
    return {
      breadList,
      fullPath,
    }
  },
  methods: {
    hasTitle (item) {
      return !!item.title
    },
  },
}
</script>
<style lang="scss" scoped>

</style>
