import { ref, provide, inject, readonly } from 'vue'

export const useMenuSwitchProvide = () => {
  let menuSwitch = ref(false)
  const setMenuSwitch = (val) => {
    menuSwitch.value = val
  }
  provide('menuSwitch', readonly(menuSwitch))
  provide('setMenuSwitch', setMenuSwitch)
}

export const useMenuSwitchInject = () => {
  const menuSwitch = inject('menuSwitch')
  const setMenuSwitch = inject('setMenuSwitch')
  return {
    menuSwitch,
    setMenuSwitch
  }
}
