import { useFanyi } from '@/plugin/fanyi'

export const language_for_java = [
  { 'zh-cn': '添加信息错误', 'en': ' Adding information error', 'jp': '情報の保存に失敗しました' },
  { 'zh-cn': '发送通知异常', 'en': ' Sending notification exception', 'jp': 'メッセージ送信に失敗しました' },
  { 'zh-cn': '异常的状态值', 'en': ' Abnormal state value', 'jp': '値のフォーマットが間違っています' },
  { 'zh-cn': '禁止查看', 'en': ' Prohibit viewing', 'jp': '詳細を閲覧することができません' },
  { 'zh-cn': '当前资源不存在', 'en': ' The current resource does not exist', 'jp': 'この案件は存在していません' },
  { 'zh-cn': '请先去用户中心完善公司信息（公司名称和营业执照）', 'en': ' Please go to the user center first to complete the company information (company name and business license)', 'jp': 'まずユーザーセンターに行って会社情報(会社名と営業許可書)を整備してもらいます。' },
  { 'zh-cn': '已经发送过意向，无需重复发送', 'en': ' The intention has already been sent, there is no need to send it again', 'jp': '利用規約に同意済みです' },
  { 'zh-cn': '请先去用户中心完成签约', 'en': ' Please go to the user center first to complete the signing process', 'jp': 'マイページから利用規約に同意してください' },
  { 'zh-cn': '需求信息不存在', 'en': ' Request information does not exist', 'jp': '協賛リクエストの情報がありません' },
  { 'zh-cn': '资源信息不存在', 'en': ' Resource information does not exist', 'jp': '案件情報が存在しません' },
  { 'zh-cn': '发送意向异常', 'en': ' Abnormal sending intention', 'jp': '問い合わせの送信異常' },
  { 'zh-cn': '添加评论异常', 'en': ' Adding comment exception', 'jp': 'コメント追加エラー' },
  { 'zh-cn': '一次最多显示200个', 'en': ' Display up to 200 at a time', 'jp': '一度に２００件以上のコメントは送信できません' },
  { 'zh-cn': '用户信息不存在', 'en': ' User information does not exist', 'jp': 'ユーザーが存在しません' },
  { 'zh-cn': '请不要频繁点赞', 'en': ' Please do not like frequently', 'jp': '"いいね"を頻繁にクリックしないでください' },
  { 'zh-cn': '该资源已经收藏过，无需重复收藏', 'en': ' This resource has already been bookmarked, there is no need to bookmark it again', 'jp': 'この案件はすでにお気に入りに登録済みです' },
  { 'zh-cn': '最多收藏1000个资源信息，请整理收藏夹后再试', 'en': ' Collect up to 1000 resource information. Please organize your favorites and try again', 'jp': 'お気に入り案件が登録上限数（1000件）に達しました' },
  { 'zh-cn': '收藏发生异常', 'en': ' Collection exception', 'jp': 'お気に入りに保存できませんでした' },
  { 'zh-cn': '创建资源异常', 'en': ' Create resource exception', 'jp': '案件登録エラー' },
  { 'zh-cn': '资源ID为空', 'en': ' Resource ID is empty', 'jp': '案件のIDが空白です' },
  { 'zh-cn': 'package信息错误', 'en': ' Package information error', 'jp': 'パッケージ情報が正しくありません' },
  { 'zh-cn': '发布需求异常', 'en': ' Release request exception', 'jp': 'クリエストの投稿が失敗しました' },
  { 'zh-cn': '当前需求不存在', 'en': ' The current request does not exist', 'jp': '該当リクエストが存在しません' },
  { 'zh-cn': '用户信息错误', 'en': ' User information error', 'jp': 'ユーザー情報エラー' },
  { 'zh-cn': '用户id必填', 'en': ' User ID is mandatory', 'jp': 'ユーザーIDが必要です' },
  { 'zh-cn': '密码8-32位，必须包含数字和字母，不能包含空格', 'en': ' Password 8-32 bits, must contain numbers and letters, cannot contain spaces', 'jp': 'パスワードは8～32桁、数字とアルファベットの組み合わせが必要です。' },
  { 'zh-cn': '该用户id已经存在，请更换', 'en': ' The user ID already exists, please replace it', 'jp': 'このユーザーIDは登録済みです' },
  { 'zh-cn': '该邮箱已经存在，请更换', 'en': ' The email already exists, please replace it', 'jp': 'このメールアドレスは登録済みです' },
  { 'zh-cn': '该用户名已经存在，请更换', 'en': ' The username already exists, please replace it', 'jp': '既に利用されているユーザー名です' },
  { 'zh-cn': '个人信息保存异常', 'en': ' Personal information saving exception', 'jp': '個人情報の保存ができませんでした' },
  { 'zh-cn': '公司信息保存异常', 'en': ' Abnormal company information storage', 'jp': '会社情報の保存ができませんでした' },
  { 'zh-cn': '用户不存在', 'en': ' user does not exist', 'jp': 'ユーザーが存在しません' },
  { 'zh-cn': '请输入用户流水id', 'en': ' Please enter the user tracking ID', 'jp': 'ユーザーIDを入力してください' },
  { 'zh-cn': '报文信息异常', 'en': ' Abnormal message information', 'jp': 'メッセージ情報エラー' },
  { 'zh-cn': '公司信息更新异常', 'en': ' Abnormal update of company information', 'jp': '会社情報の更新ができませんでした' },
  { 'zh-cn': '发送消息异常', 'en': ' Sending message exception', 'jp': 'メッセージ送信エラー' },
  { 'zh-cn': '必须输入聊天对象', 'en': ' Chat object must be entered', 'jp': '内容を入力してください' },
  { 'zh-cn': '未找到对应的枚举值', 'en': ' No corresponding enumeration value found', 'jp': '列挙値が見つかりません' },
  { 'zh-cn': '禁止访问非法数据', 'en': ' Prohibit access to illegal data', 'jp': 'この情報へはアクセスいただけません' },
  { 'zh-cn': '用户未登录或者登陆信息已过期', 'en': ' User not logged in or login information has expired', 'jp': 'ログインしていないか、ユーザーセッションがタイムアウトしています' },
  { 'zh-cn': '最多更新200个数据', 'en': ' Up to 200 data updates', 'jp': '更新できるデータの数は200までです' },
  { 'zh-cn': '未找到该邮箱地址', 'en': ' The email address was not found', 'jp': '該当メールアドレスが見つかりません' },
  { 'zh-cn': '请先到个人中心页面，完善公司信息后，发起审核', 'en': ' Please go to the personal center page first, complete the company information, and then initiate the review', 'jp': 'マイページより会社情報の入力を完了してください' },
  { 'zh-cn': '用户名必填', 'en': ' User name is mandatory', 'jp': 'ユーザー名は入力必須です' },
  { 'zh-cn': '用户手机号必填', 'en': ' User\'s mobile phone number is mandatory', 'jp': '携帯番号が未入力です' },
  { 'zh-cn': '请上传5M以内的图片', 'en': ' Please upload images within 5M', 'jp': '5MB以内の画像をアップロードしてください)' },
  { 'zh-cn': '上传的文件不是图片', 'en': ' The uploaded file is not an image', 'jp': '画像ファイルをアップロードしてください' },
  { 'zh-cn': '登陆失败,账户被锁定，请3分钟后再试', 'en': ' Login failed, account locked, please try again 3 minutes later.', 'jp': 'ログインに失敗しました。アカウントがロックされていますので、3分後に再度ログインを試みてください' },
  { 'zh-cn': '登陆失败，请检查用户名密码', 'en': ' Login failed, please check username and password', 'jp': 'ログインに失敗しました。ユーザー名とパスワードを確認してください。' },
  { 'zh-cn': '不存在该用户', 'en': ' The user does not exist', 'jp': 'ユーザーが存在しません' },
  { 'zh-cn': '原密码错误', 'en': ' The original password is incorrect', 'jp': '元のパスワードが間違っています' },
  { 'zh-cn': '消息不存在', 'en': ' Message does not exist', 'jp': 'メッセージが存在しません' },
  { 'zh-cn': '非管理员消息，不能设置已读', 'en': ' Non administrator messages cannot be set as read', 'jp': '' },
  { 'zh-cn': '信息不存在', 'en': ' Information does not exist', 'jp': 'メッセージが存在しません' },
  { 'zh-cn': '资源不存在', 'en': ' Resource does not exist', 'jp': '案件が存在しません' },
  { 'zh-cn': '激活失败', 'en': ' Activation failed', 'jp': 'アクティベーションに失敗しました' },
  { 'zh-cn': '登陆失败,账户被锁定，请3分钟后再试', 'en': ' Login failed, account locked, please try again 3 minutes later.', 'jp': 'ログインに失敗しました。アカウントがロックされていますので、3分後に再度ログインを試みてください' },
  { 'zh-cn': '接口调用失败', 'en': ' Interface call failed', 'jp': 'インターフェース呼び出しに失敗しました' },
  { 'zh-cn': '参数检验失败', 'en': ' Parameter verification failed', 'jp': 'パラメータ・チェックに失敗しました' },
  { 'zh-cn': '暂未登录或token已经过期', 'en': ' Not logged in yet or token has expired', 'jp': 'ユーザがログインしていないか、ログイン情報の有効期限が切れている' },
  { 'zh-cn': '没有相关权限', 'en': ' No relevant permissions', 'jp': '該当する権限がありません' },
  { 'zh-cn': '账号尚未被激活，请查看激活邮件，点击激活后重新登录，谢谢！', 'en': ' Your account has not been activated yet. Please check your activation email, click the activation link, and log in again. Thank you!', 'jp': 'アカウントはまだアクティベートされていません。アクティベーションメールを確認し、アクティベーションリンクをクリックして再度ログインしてください。ありがとうございます！' }
]

const { locale } = useFanyi()
export const fanyi_from_java = (x) => {

  console.log(locale.value)
  console.log(x)

  const r = language_for_java.filter(t => t['zh-cn'] == x)
  if (r && r.length > 0) {
    return r[0][locale.value]
  }
  return x
}