import { createApp } from 'vue'
import App from './App.vue'
import './style/index.scss'

import ElementPlus from 'element-plus'
// import 'element-plus/dist/index.css'

import fanyi from './fanyi/index.js'
import router from './router'
// import i18n from './locales/index.js'

import pinia from './plugin/pinia'
import elementIcon from './plugin/elementIcon'
import { createMetaManager } from 'vue-meta'

import { thousands } from './utils/directive'
const app = createApp(App)

app.use(router)
// app.use(i18n)
app.use(fanyi)
app.use(ElementPlus)
app.use(pinia)
app.use(elementIcon)

app.directive('thousands', thousands)
app.use(createMetaManager(false, {
  meta: { tag: 'meta', nameless: true }
}))
app.mount('#app')

