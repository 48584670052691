import PortalLayout from '@/views/layout/portalLayout/index.vue'
import LayoutUserCenter from '@/views/layoutUserCenter/appContent.vue'

// import i18n from '@/locales/index'
// const t = i18n.global.t

import { useFanyi } from '@/plugin/fanyi'
const { f } = useFanyi()

// 定义的所有静态路由
const routes = [
  {
    path: '/portal',
    name: 'portal',
    redirect: '/portal/home',
    component: PortalLayout,
    meta: {
      title: f('p152_t100', '门户')
    },
    children: [
      {
        path: 'home',
        component: () => import('@/views/portal/home/home.vue'),
        meta: {
          title: f('p152_t120', '首页')
        }
      },
      {
        path: 'resource',
        component: () => import('@/views/portal/resource/resource0926.vue'),
        meta: {
          title: f('p152_t140', '浏览资源')
        }
      },
      {
        path: 'searchResult',
        name: 'searchResult',
        component: () => import('@/views/portal/resource/search-result.vue'),
        meta: {
          title: f('p152_t140', '浏览资源')
        }
      },
      // {
      //   path: 'resource',
      //   component: () => import('@/views/portal/resource/resource.vue'),
      //   meta: {
      //     title: f('p152_t140', '浏览资源')
      //   }
      // },
      {
        path: 'resourceInfo',
        component: () => import('@/views/portal/resource/resourceInfo.vue'),
        meta: {
          title: f('p152_t160', '资源详情')
        }
      },
      {
        path: 'perspective',
        component: () => import('@/views/portal/perspective/perspective.vue'),
        meta: {
          title: f('p152_t161', '洞察')
        }
      },
      {
        path: 'perspectiveDetail',
        component: () => import('@/views/portal/perspective/perspectiveDetail.vue'),
        meta: {
          title: f('p152_t161', '洞察详情')
        }
      },
      {
        path: 'about',
        component: () => import('@/views/portal/about/about-old1.vue'),
        meta: {
          title: f('p152_t180', '关于我们')
        }
      },


      {
        path: 'useragreement',
        component: () => import('@/views/portal/footer/useragreement.vue'),
        meta: {
          // title: f('p152_t280', '...')
        }
      },
      {
        path: 'privacy',
        component: () => import('@/views/portal/footer/privacy.vue'),
        meta: {
          // title: f('p152_t280', '...')
        }
      },

      {
        path: 'FAQ',
        component: () => import('@/views/portal/footer/faq.vue'),
        meta: {
          // title: f('p152_t280', '...')
        }
      },


      {
        path: 'demand',
        component: () => import('@/views/portal/demand/demand.vue'),
        meta: {
          title: f('p152_t200', '品牌需求')
        }
      },
      {
        path: 'demandInfo',
        component: () => import('@/views/portal/demand/demandInfo.vue'),
        meta: {
          title: f('p152_t220', '品牌需求详情')
        }
      },
      {
        path: 'topic',
        component: () => import('@/views/portal/topic/topic.vue'),
        meta: {
          title: f('p152_t240', '话题')
        }
      },
      {
        path: 'topicInfo',
        component: () => import('@/views/portal/topic/topicInfo.vue'),
        meta: {
          title: f('p152_t260', '话题详情')
        }
      },
      {
        path: 'activity',
        component: () => import('@/views/portal/activity/activity.vue'),
        meta: {
          title: f('p152_t280', '活动')
        }
      },
      
    ]
  },





  // ==================用户中心==================
  {
    path: '/user',
    redirect: '/user/userInfo',
    name: 'user',
    menuType: 'userCenter',
    userType: 'all',
    component: LayoutUserCenter,
    children: [
      {
        path: '/user/userInfo',
        component: () => import('@/views/user/userInfo.vue'),
        meta: {
          name: 'table',
          // title: f('p152_t300', '个人信息')
        }
      }
    ],
    meta: { title: f('p152_t310', '个人信息'), name: 'form', icon: 'user' }
  },
  {
    path: '/changePassword',
    redirect: '/user/changePassword',
    name: 'changePassword',
    menuType: 'userCenter',
    userType: 'all',
    component: LayoutUserCenter,
    children: [
      {
        path: '/user/changePassword',
        component: () => import('@/views/user/changePassword.vue'),
        meta: {
          name: 'table',
          // title: f('p152_t330', '修改密码')
        }
      }
    ],
    meta: { title: f('p152_t340', '修改密码'), name: 'form', icon: 'user', hidden: true }
  },
  {
    path: '/resource',
    redirect: '/resource/index',
    name: 'resource',
    menuType: 'userCenter',
    userType: 1,
    component: LayoutUserCenter,
    children: [
      {
        path: '/resource/index',
        component: () => import('@/views/resource/index.vue'),
        meta: {
          name: 'table',
          // title: f('p152_t360', '我的资源')
        }
      }
    ],
    meta: { title: f('p152_t370', '我的资源'), name: 'form', icon: 'List' }
  },
  {
    path: '/resourceInfo',
    redirect: '/resource/info',
    name: 'resourceInfo',
    menuType: 'userCenter',
    userType: 1,
    component: LayoutUserCenter,
    children: [
      {
        path: '/resource/info',
        component: () => import('@/views/resource/resourceInfo_v2.vue'),
        meta: {
          name: 'table',
          // title: f('p152_t390', '资源详情')
        }
      },
      {
        path: '/resource/packageList',
        component: () => import('@/views/resource/packageList.vue'),
        meta: {
          name: 'table',
          // title: f('p152_t410', '活动包列表')
        }
      },
      {
        path: '/resource/packageInfo',
        component: () => import('@/views/resource/packageInfo.vue'),
        meta: {
          name: 'table',
          // title: f('p152_t430', '活动包详情')
        }
      }
    ],
    meta: { title: f('p152_t440', '资源详情'), name: 'form', icon: 'goods', hidden: true }
  },
  // {
  //   path: '/interestDemand',
  //   redirect: '/interestDemand/index',
  //   name: 'interestDemand',
  //   menuType: 'userCenter',
  //   userType: 1,
  //   component: LayoutUserCenter,
  //   children: [
  //     {
  //       path: '/interestDemand/index',
  //       component: () => import('@/views/interestList/interestDemand.vue'),
  //       meta: {
  //         name: 'table',
  //         // title: f('p152_t460', '我的意向')
  //       }
  //     }
  //   ],
  //   meta: { title: f('p152_t470', '我的意向'), name: 'interestDemand', icon: 'sold-out' }
  // },
  {
    path: '/demand',
    redirect: '/demand/index',
    name: 'demand',
    menuType: 'userCenter',
    userType: 2,
    component: LayoutUserCenter,
    children: [
      {
        path: '/demand/index',
        component: () => import('@/views/demand/index.vue'),
        meta: {
          name: 'table',
          // title: f('p152_t490', '我的需求')
        }
      }
    ],
    meta: { title: f('p152_t500', '我的需求'), name: 'demand', icon: 'List' }
  },
  {
    path: '/demandInfo',
    redirect: '/demand/info',
    name: 'demandInfo',
    menuType: 'userCenter',
    userType: 2,
    component: LayoutUserCenter,
    children: [
      {
        path: '/demand/info',
        component: () => import('@/views/demand/demandInfo.vue'),
        meta: {
          name: 'table',
          // title: f('p152_t520', '需求详情')
        }
      }
    ],
    meta: { title: f('p152_t530', '需求详情'), name: 'demandInfo', icon: 'goods', hidden: true }
  },
  // {
  //   path: '/interestResource',
  //   redirect: '/interestResource/index',
  //   name: 'interestResource',
  //   menuType: 'userCenter',
  //   userType: 2,
  //   component: LayoutUserCenter,
  //   children: [
  //     {
  //       path: '/interestResource/index',
  //       component: () => import('@/views/interestList/interestResource.vue'),
  //       meta: {
  //         name: 'table',
  //         // title: f('p152_t550', '我的意向')
  //       }
  //     }
  //   ],
  //   meta: {
  //     title: f('p152_t560', '我的意向'),
  //     name: 'interestResource',
  //     icon: 'sold-out'
  //   }
  // },
  {
    path: '/myFavorite',
    redirect: '/myFavorite/index',
    name: 'myFavorite',
    menuType: 'userCenter',
    userType: 'all',
    component: LayoutUserCenter,
    children: [
      {
        path: '/myFavorite/index',
        component: () => import('@/views/myFavorite/index.vue'),
        meta: {
          name: 'table',
          // title: f('p152_t580', '我的收藏')
        }
      }
    ],
    meta: {
      title: f('p152_t590', '我的收藏'),
      name: 'myFavorite',
      icon: 'CollectionTag'
    }
  },
  {
    path: '/myMessage',
    redirect: '/myMessage/index',
    name: 'myMessage',
    menuType: 'userCenter',
    userType: 'all',
    component: LayoutUserCenter,
    children: [
      {
        path: '/myMessage/index',
        component: () => import('@/views/myMessage/index.vue'),
        meta: {
          name: 'table',
          // title: f('p152_t610', '我的消息')
        }
      }
    ],
    meta: {
      title: f('p152_t620', '我的消息'),
      name: 'myMessage',
      icon: 'message'
    }
  },
  {
    path: '/myNotification',
    redirect: '/myNotification/index',
    name: 'myNotification',
    menuType: 'userCenter',
    userType: 'all',
    component: LayoutUserCenter,
    children: [
      {
        path: '/myNotification/index',
        component: () => import('@/views/myNotification/index.vue'),
        meta: {
          name: 'table',
          // title: f('p152_t640', '我的通知')
        }
      }
    ],
    meta: {
      title: f('p152_t650', '我的通知'),
      name: 'myNotification',
      icon: 'bell'
    }
  },
  // {
  //   path: '/mySignAgreement',
  //   redirect: '/mySignAgreement/index',
  //   name: 'mySignAgreement',
  //   menuType: 'userCenter',
  //   userType: 2,
  //   component: LayoutUserCenter,
  //   children: [
  //     {
  //       path: '/mySignAgreement/index',
  //       component: () => import('@/views/mySignAgreement/index.vue'),
  //       meta: {
  //         name: 'table',
  //         title: f('p152_t670', '佣金协议')
  //       }
  //     }
  //   ],
  //   meta: {
  //     title: f('p152_t680', '佣金协议'),
  //     name: 'mySignAgreement',
  //     icon: 'sold-out'
  //   }
  // },
  {
    path: '/topic',
    redirect: '/topic/index',
    name: 'topic',
    menuType: 'userCenter',
    userType: 'all',
    component: LayoutUserCenter,
    children: [
      {
        path: '/topic/index',
        component: () => import('@/views/myTopic/index.vue'),
        meta: {
          name: 'mytopic',
          // title: f('p152_t700', '我的话题')
        }
      }
    ],
    meta: { title: f('p152_t710', '我的话题'), name: 'topic', icon: 'Reading' }
  },
  {
    path: '/topicInfo',
    redirect: '/topic/index',
    name: 'topicInfo',
    menuType: 'userCenter',
    userType: 'all',
    component: LayoutUserCenter,
    children: [
      {
        path: '/topicInfo/opt',
        component: () => import('@/views/myTopic/addTopic.vue'),
        meta: {
          name: 'topicOpt',
          // title: f('p152_t730', '话题新建/编辑')
        }
      }
    ],
    meta: { title: f('p152_t740', '我的话题'), name: 'topicInfo', icon: 'sold-out', hidden: true }
  },


]

export default routes