import { createFanyi } from '@/plugin/fanyi'
// import { message } from './message'
import { language_text } from './language_text'

//纯目录，看看
const pageid = {
  'p100': 'views_user_userInfo',
  'p101': 'views_user_register',
  'p102': 'views_user_login',
  'p103': 'views_user_findPassword',
  'p104': 'views_user_changePassword',
  'p105': 'views_resource_resourceInfo',
  'p106': 'views_resource_packageList',
  'p107': 'views_resource_packageInfo',
  'p108': 'views_resource_index',
  'p109': 'views_portal_topic_comp_topicCard',
  'p110': 'views_portal_topic_comp_comment',
  'p111': 'views_portal_topic_topicInfo',
  'p112': 'views_portal_topic_topic',
  'p113': 'views_portal_resource_comp_resourceBox',
  'p114': 'views_portal_resource_resourceInfo',
  'p115': 'views_portal_resource_resource',
  'p116': 'views_portal_home_comp_topicCard',
  'p117': 'views_portal_home_comp_reportBlock',
  'p118': 'views_portal_home_home',
  'p119': 'views_portal_demand_comp_demandBox',
  'p120': 'views_portal_demand_demandInfo',
  'p121': 'views_portal_demand_demand',
  'p122': 'views_portal_activity_activity',
  'p123': 'views_portal_about_comp_timeline',
  'p124': 'views_portal_about_about',
  'p125': 'views_myTopic_index',
  'p126': 'views_myTopic_addTopic',
  'p127': 'views_mySignAgreement_index',
  'p128': 'views_myNotification_index',
  'p129': 'views_myMessage_components_chat',
  'p130': 'views_myMessage_sentMessage',
  'p131': 'views_myMessage_receivedMessage',
  'p132': 'views_myMessage_index',
  'p133': 'views_myFavorite_index',
  'p134': 'views_myFavorite_favoriteResource',
  'p135': 'views_myFavorite_favoriteDemand',
  'p136': 'views_layoutUserCenter_appSidebar',
  'p137': 'views_layoutUserCenter_appHeader',
  'p138': 'views_layoutUserCenter_appContent',
  'p139': 'views_layout_portalLayout_index',
  'p140': 'views_layout_portalLayout_header',
  'p141': 'views_layout_portalLayout_footer',
  'p142': 'views_interestList_interestResource',
  'p143': 'views_interestList_interestDemand',
  'p144': 'views_demand_index',
  'p145': 'views_demand_demandInfo',
  'p146': 'components_editor_editor',
  'p147': 'components_common_tInputNumber',
  'p148': 'components_common_numberInputBox',
  'p149': 'components_common_menuRenderUserCenter',
  'p150': 'components_common_menuRender',
  'p151': 'components_common_breadcrumb'
}

const fanyi = createFanyi({
  //locale: localStorage.getItem('lang') || 'jp', //改了，后面根据navigator.language 
  locale: localStorage.getItem('lang') ,
  message: language_text
})

export default fanyi