<template>
  <div class="app-sidebar" :class="menuSwitch ? 'sidebar-narrow' : ''">
    <div class="sidebar-logo">
      <span>{{ $f("p136_t100", "我的看板") }}</span>
    </div>
    <menu-render
      :default-active="menuActive"
      :collapse="menuSwitch"
      class="app-sidebar-wrap"
      :menu-data="menuData"
      text-color="#eee"
      active-text-color="#fff"
      popper-class="sidebar-popup"
    />
  </div>
</template>

<script>
import MenuRender from '@/components/common/menuRenderUserCenter.vue'
import menus from '@/router/menus'
import { onMounted, ref, watch } from 'vue'
import { useMenuSwitchInject } from '@/views/layoutUserCenter/hooks/menuSwitch'
// import { getLocalStorage } from '@/utils/index'
import { useRoute } from 'vue-router'
import { useUserStore } from '@/store/user'

export default {
  name: 'AppSidebar',
  components: {
    MenuRender,
  },
  setup () {
    const userStore = useUserStore()

    // 菜单数据操作
    let menuData = ref([]) // 用户
    const menuDataTransfer = (data, oldId) => {
      data.forEach((item, index) => {
        // 递归设置id
        item.id = oldId ? oldId + '-' + (index + 1) : '' + (index + 1)
        if (!oldId) item.root = true // 如果没有oldId证明是最外层
        if (!item.meta.title) item.meta.hidden = false // 如果没有title则不在菜单中显示
        if (item.children && item.children.length) {
          menuDataTransfer(item.children, item.id)
        }
      })
    }
    onMounted(() => {
      menuDataTransfer(menus)
      menuData.value = menus.filter(
        (item) =>
          item.menuType === 'userCenter' &&
          (item.userType === userStore.userType || item.userType === 'all')
      )
    })

    // 获取当前选中的index
    let menuActive = ref('')
    const route = useRoute()
    watch(route, (to) => {
      menuActive.value = to.meta.hidden ? to.meta.activeMenu : to.path
    })
    onMounted(() => {
      menuActive.value = route.meta.hidden ? route.meta.activeMenu : route.path
    })

    // 菜单折叠操作
    const { menuSwitch } = useMenuSwitchInject()

    return {
      menuDataTransfer,
      menuData,
      menuSwitch,
      menuActive,
    }
  },
}
</script>

<style lang="scss" scoped>
// $main-color: #6375aa;
// $main-color: #BF2886;
// $main-color: #10217C;
.app-sidebar {
  padding-right: 10px;
  width: 270px !important;
  // height: 100%;
  // overflow: hidden;
  z-index: 1999;
  box-sizing: border-box;
  transition: all ease-in-out 0.3s;
  background-color: $primaryColor;

  .sidebar-logo {
    position: relative;
    width: 270px;
    height: 60px;
    line-height: 60px;
    padding: 0 20px;
    background-color: $primaryColor;
    font-size: 16px;
    box-sizing: border-box;
    //font-weight: bold;
    color: #fff;
    box-shadow: 1px 2px 8px 3px rgba(0, 0, 0, 0.3);
    z-index: 10;
  }

  .app-sidebar-vertical {
    text-align: left;
  }

  .app-sidebar-wrap {
    background-color: $primaryColor;
    width: 270px;
    height: 100%;
    padding: 10px 0;
    overflow: hidden;
    box-sizing: border-box;

    ::v-deep(.el-menu) {
      border: none;
    }

    .sidebar-title {
      height: 60px;
      line-height: 60px;
      padding-left: 20px;
      font-size: 18px;
      font-weight: normal;
      color: #fff;

      i {
        margin-right: 20px;
      }

      img {
        width: 18px;
      }
    }

    .el-row {
      overflow-x: hidden;
      overflow-y: auto;
      color: #000;
      height: 100%;
    }

    /*滚动条样式*/
    .el-row::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 4px;
    }

    .el-row::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 5px;
      box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.2);
      background: rgba(255, 255, 255, 0.2);
    }

    .el-row::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.1);
      border-radius: 0;
      background: rgba(255, 255, 255, 0.1);
    }
  }

  &.sidebar-narrow {
    width: 64px !important;
    transition: all ease-in-out 0.3s;

    ::v-deep(.menu-title) {
      display: none;
    }

    .app-sidebar-wrap {
      width: 64px !important;
      transition: all ease-in-out 0.3s;
    }

    .sidebar-logo {
      span {
        display: none;
      }
    }
  }

  .iconfont {
    vertical-align: middle;
    margin-right: 20px;
    text-align: center;
    font-size: 18px;
  }

  ::v-deep(.el-menu-item),
  ::v-deep(.el-submenu__title) {
    //width: 270px;
    min-width: auto;
    height: 50px;
    line-height: 50px;
    padding: 0 20px !important;
    color: rgba(255, 255, 255, 0.75);
    font-size: 14px;
    box-sizing: border-box;
    background-color: $primaryColor;

    i {
      margin-top: -2px !important;
      color: rgba(255, 255, 255, 0.75);
      font-size: 20px;

      &.el-submenu__icon-arrow {
        margin-top: -10px !important;
      }
    }
    //
    //&:hover {
    //  background-color: #222;
    //}
  }

  ::v-deep(.el-menu-item.is-active) {
    background-color: #485695 !important;
    color: #fff;
  }

  ::v-deep(.el-submenu__title) {
    color: rgba(255, 255, 255, 0.75);

    i {
      color: rgba(255, 255, 255, 0.75);
    }
  }

  .is-opened {
    ::v-deep(.el-submenu__title) {
      color: #fff;

      i {
        color: #fff;
      }
    }

    .el-menu-item {
      background-color: #222 !important;
    }
  }

  .router-link-active .el-menu-item {
    color: #fff;
    //background-color: #485695 !important;
    i {
      color: #fff;
    }
  }

  ::v-deep(.el-submenu) .el-menu-item {
    padding: 0 40px !important;
    box-sizing: border-box;
  }

  .app-sidebar-top div {
    display: inline-block;
    font-size: 14px;
    color: #fff;
  }

  .app-sidebar-avatar img {
    width: 100%;
  }
}
</style>
<style lang="scss">
.sidebar-popup {
  background-color: #000 !important;
  .el-menu--popup {
    padding: 0;
  }
  .el-menu-item.is-active {
    background-color: #485695 !important;
    color: #fff;
  }
}
</style>
